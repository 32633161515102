import React, { useState, useEffect, useRef } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { Box, Container, Card, CssBaseline, Typography, CircularProgress } from '@mui/material';
import assets from '../../../assets';
import colorConfigs from '../../../configs/colorConfigs';
import api from '../../../redux/features/auth/axiosAuthHelper';

const ActivationCard = () => {
    const [isSubmit, setSubmit] = useState(false);
    const [authFailed, setAuthFailed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [displayMessage, setDisplayMessage] = useState("");
    const { token } = useParams();
    const alertContent = "Error requesting new activation email, please navigate to https://novarumdx.com/company#contact for support.";
    const hasActivated = useRef(false); // Ref to ensure activation is triggered only once

    useEffect(() => {
        if (token && !hasActivated.current) {
            activateUser(token);
            hasActivated.current = true; // Set ref to true after activation attempt
        } else {
            setDisplayMessage("Invalid activation link.");
            setLoading(false);
        }
    }, [token]);

    const activateUser = async (token: any) => {
        try {
            console.log(`auth/activate/${token}`)
            const response = await api.get(`auth/activate/${token}`);
            const payload = response.data;
            console.log(response.data)

            // Extract tokens from payload
            const { access_token, refresh_token, message } = payload;
            console.log("access_token", access_token)
            console.log("refresh_token", refresh_token)

            // Check the user's device, Android, IOS or non phone
            const userAgent = navigator.userAgent || (navigator as any).vendor || (window as any).opera;
            let deeplink = "";
            
            // TODO - These deep links need changed to RARE deep links
            if (/android/i.test(userAgent)) {
                console.log("attempting to redirect to Android APP", `bellylabs://login?access=${access_token}&refresh=${refresh_token}`)
                setDisplayMessage(message);
                deeplink = access_token && refresh_token ? `bellylabs://login?access=${access_token}&refresh=${refresh_token}` : 'bellylabs://' ;
                window.location.href = deeplink;
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
                console.log("attempting to redirect to IOS APP", `bellylabs://login?access=${access_token}&refresh=${refresh_token}`)
                setDisplayMessage(message);
                deeplink = access_token && refresh_token ? `bellylabs://login?access=${access_token}&refresh=${refresh_token}` : 'bellylabs://' ;
                window.location.href = deeplink;
            } else {
                console.log(message)
                setDisplayMessage(message);
            }
        } catch (error) {
            console.error('Activation failed:', error);
            console.log(error);
            setAuthFailed(true);
            setDisplayMessage("Activation failed. Please navigate to https://novarumdx.com/company#contact for support.");
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setSubmit(true);
    };

    return (
        <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 4,
          // justifyContent: 'center',
          px: 2,
        }}
      >
        {/* Logo at the top */}
        <Box data-testid="Box-zg2j"
          component="img"
          sx={{
            width: { xs: '80%', sm: '60%', md: '40%', lg: '35%', xl: '30%' }, // Responsive widths
            height: 'auto',
            mb:  {xs: 3, sm: 1},
          }}
          alt="Rare Logo"
          src={assets.images.rareLogo}
        />
        
        {/* Sign‑in card */}
        <Card
          sx={{
              width: { xs: '80%', sm: '60%', md: '50%', lg: '40%', xl: '30%' }, // Responsive widths
              boxShadow: 10,
            p: {xs: 3, sm: 4, md: 5, lg: 8},
            borderRadius: 5,
          }}
        >
          <CssBaseline />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: {xs: 1, sm: 0, md: 5}
          }}
        >
                    {loading ? (
                        <CircularProgress data-testid="CircularProgress-1pgg" />
                    ) : (
                        <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>  
                                <Typography data-testid="Typography-7yj6" 
                                fontWeight="bold" 
                                sx={{ paddingBottom: 2, textAlign: 'center'}}>
                                {displayMessage}
                                </Typography>
                        </Box>
                    )}
                </Box>
        </Card>
    </Box>
    );
};

export default ActivationCard;
