import { Alert, AlertTitle, Box, Button, Card, Container, CssBaseline, FormControl, Grid, Link, MenuItem, TextField, ThemeProvider, Typography, useTheme } from '@mui/material';
import api from '../../../redux/features/auth/axiosAuthHelper';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from "yup";
import assets from '../../../assets';
import { FormEmailTextField, FormPasswordTextField } from '../fields/AuthFields';
import colorConfigs from "../../../configs/colorConfigs";
import versionConfig from "../../../configs/versionConfig";
import RareButton from '../buttons/RareButton';


export default function ResetPasswordCard() {
    const navigate = useNavigate();
    const theme = useTheme();

    const [errors, setErrors] = useState<string| null>(null);

    const validationSchema = Yup.object().shape({
        password1: Yup.string()
            .required("This field is required")
            .min(8, "Password must be at least 8 characters long")
            .matches(/[a-zA-Z]/, "Password must contain at least one letter")
            .matches(/\d/, "Password must contain at least one number"),
        password2: Yup.string()
            .required("This field is required")
            .oneOf([Yup.ref('password1')], "Passwords must match"),
    });

    var { uidb64, token } = useParams()
    console.log(uidb64, token)

    function getCookie(name: any) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            password1: "",
            password2: "",
        },
        validationSchema,
        onSubmit: (values) => {
            const post_data = {
                new_password1: values.password1,
                new_password2: values.password2,
                uid: uidb64,
                token: token,
            }
            api.post('auth/password/reset-confirm/', post_data, {
                headers: {
                    "X-CSRFToken": getCookie('csrftoken'),
                }
            })            
            .then(function (response) {
                console.log(response);
                navigate('/auth/login');
            })
            .catch(function (error) {
                if (error.response?.data?.new_password2[0]) {
                    setErrors(error.response.data.new_password2[0])
                }
                if (error.response) {
                    console.error("Error data:", error.response.data);
                    console.error("Error status:", error.response.status);
                    console.error("Error headers:", error.response.headers);
                } else if (error.request) {
                    console.error("Error request:", error.request);
                } else {
                    console.error('Error', error.message);
                }
            });
        }
    });

    return (
        <FormikProvider value={formik}>
           <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 4,
                // justifyContent: 'center',
                px: 2,
              }}
            >
              {/* Logo at the top */}
              <Box
                component="img"
                sx={{
                  width: { xs: '80%', sm: '60%', md: '40%', lg: '35%', xl: '30%' }, // Responsive widths
                  height: 'auto',
                  mb:  {xs: 3, sm: 1},
                }}
                alt="Rare Logo"
                src={assets.images.rareLogo}
              />
              
              <Card
                sx={{
                  width: { xs: '80%', sm: '60%', md: '50%', lg: '40%', xl: '30%' }, // Responsive widths
                  boxShadow: 10,
                  p: {xs: 3, sm: 4, md: 5, lg: 8},
                  borderRadius: 5,
                }}
              >
                <CssBaseline />
  
                                    {errors && (
                                        <Alert data-testid="Alert-0jpp" severity="error" style={{ marginTop: '16px' }}>
                                            <AlertTitle data-testid="AlertTitle-xu9c">{"Error"}</AlertTitle>
                                            {errors}
                                        </Alert>
                                    )}
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                px: {xs: 1, sm: 0, md: 5}
                                            }}
                                            >
                                                <Box data-testid="Box-7iot" />
                                                <Box sx={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                                                <Field data-testid="Field-3a59" name="password1" label={"Password"} type="text" required className="form-control" component={FormPasswordTextField} />
                                                <Field data-testid="Field-r220" name="password2" label={"Confirm Password"} type="text" required className="form-control" component={FormPasswordTextField} />                                   
                                                </Box>
                                                    <Box
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            my: 2,
                                                        }}
                                                    >   
                                                        <RareButton data-testid="Button-7b1e"
                                                            disabled={!formik.values.password1 || !formik.values.password2}
                                                            type="submit"
                                                            fullWidth
                                                            variant="contained"
                                                            sx={{width: {xs: '90%', sm: '50%'}}} >
                                                            Confirm
                                                        </RareButton>
                                                    </Box>
                                    <Box sx={{alignSelf: 'flex-start'}} >
                                        <Link data-testid="Link-zyfm" href="/auth/login" variant="body2">
                                            Return to Login
                                        </Link>
                                    </Box>
                                <Typography data-testid="Typography-mwyd" variant="body2" color="text.secondary" align="center">
                                    {versionConfig.version}
                                </Typography>
                       </Box>
                    </Card>
                </Box>
           </form>
        </FormikProvider>
    );
};  