import { Outlet, useNavigate } from "react-router-dom";
import { 
  Box, Drawer, IconButton, List, SpeedDial, Stack, Toolbar,
  Typography, Dialog, AppBar, DialogContent 
} from "@mui/material";
import sizeConfigs from "../../configs/sizeConfigs";
import colorConfigs from "../../configs/colorConfigs";
import React, { useEffect, useState } from "react";
import Sidebar from "../common/sidebar/Sidebar";
import ProfileButton from "../common/buttons/ProfileButton";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import assets from "../../assets";
import {appRoutes, mobileAppRoutes} from "../../routes/appRoutes";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import SignInDialog from "../common/SignInDialog";
import { logout } from "../../redux/features/auth/authSlice";
import EditProfileCard from "../common/cards/EditProfileCard";
import MobileMenuItemCollapse from "../common/mobile_menu/MobileMenuCollapse";
import MobileMenuItem from "../common/mobile_menu/MobileMenuItem";

const MainLayout = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const authData = useSelector((state: RootState) => state.auth);
  const [isHovered, setIsHovered] = useState(false);
  const [popup, setPopup] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleBackButton = () => {
    setPopup("");
  };
  
  const handleLogout = () => {
    handleDrawerToggle();
    setExpanded(false);
    dispatch(logout());
    navigate('/login');
  };

  const handleProfileToggle = () => {
    handleDrawerToggle();
    setExpanded(false);
    setPopup('Edit');
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setExpanded(false)
  }

  const mobiledrawer = (
    <Box
    sx={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 1,
    }}
  >
    <IconButton
      edge="start"
      color="inherit"
      aria-label="close"
      onClick={handleDrawerClose}
      sx={{ position: 'absolute', top: 16, right: 16 }}
    >
      <CloseIcon sx={{ color: 'primary.main' }} />
    </IconButton>
    <List
      // onClick={handleDrawerClose}
      sx={{ 
        width: '90%', 
        height: "100%", 
        p: 0, 
        m: 0, 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between'
      }}
    >    
      <Toolbar disableGutters sx={{ height: "100px", transition: "all 0.3s ease" }}>
        <Stack
          sx={{ width: "100%", height: "80px" }}
          direction="row"
          justifyContent="center"
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              transition: "all 0.3s ease",
              objectFit: "contain",
            }}
            alt="DDTD Logo"
            src={assets.images.rareLogo}
          />
        </Stack>
      </Toolbar>
      {appRoutes.map((route: any, index: any) => {
        if (authData !== undefined && !authData.isAuthenticated)
          return <SignInDialog isAuthenticated={authData.isAuthenticated} key={index} />;
        if (route.sidebarProps && authData !== undefined) {
          if (route.child) {
            return <MobileMenuItemCollapse handleDrawerClose={handleDrawerClose} item={route} key={index} />;
          } else {
            return <MobileMenuItem handleDrawerClose={handleDrawerClose} item={route} key={index} />;
          }
        }
        return null;
      })}
      
    </List>
    <Box sx={{px: '20px', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
    {mobileAppRoutes.map((route: any, index: any) => {
        if (route.state === "logout") {route.onClick = handleLogout}
        if (route.state === "profile") {route.onClick = handleProfileToggle}
        if (route.sidebarProps && authData !== undefined) {
          if (route.onClick) {
            return (
              <IconButton
                key={index}
                onClick={(e) => {
                  e.preventDefault(); // Prevent any default navigation
                  route.onClick && route.onClick();
                }}
                disableRipple
                disableFocusRipple
                disableTouchRipple
                className="sidebar-button"
                sx={{p: 2, color: route.state === "logout" ? colorConfigs.buttons.cancel : colorConfigs.buttons.success}}
              >
                {route.sidebarProps.icon}
                {/* <span>{route.sidebarProps.displayText}</span> */}
              </IconButton>
            );
          }
          if (route.child) {
            return <MobileMenuItemCollapse handleDrawerClose={handleDrawerClose} item={route} key={index} />;
          } else {
            return <MobileMenuItem handleDrawerClose={handleDrawerClose} item={route} key={index} />;
          }
        }
        return null;
      })}
      </Box>
  </Box>
  );
  
  return (
    <Box sx={{ display: "flex", m: 0 }}>
      {/* Mobile layout */}
      <Box display={{ xs: "block", sm: "block", md: 'none' }}>
        <SpeedDial
          ariaLabel="Nav Menu"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{
            position: 'fixed',
            top: -4,
            right: 12, // Adjust this value to keep it exactly in the same place
            zIndex: 11,
            '& .MuiFab-primary': {
              width: '50px',
              height: '50px',
              borderRadius: 6,
            },
            '& .MuiSpeedDial-actions': {
              '& .MuiSpeedDialAction-fab': {}
            }
          }}
          icon={<MenuIcon />}
        />
        <Drawer
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          PaperProps={{
            sx: {
              width: '100vw',
              height: '100vh',
              background: 'rgba(255, 255, 255, 0.92)'
            },
          }}
        >
          {mobiledrawer}
        </Drawer>
      </Box>
      {/* Desktop sidebar */}
      <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
        <ProfileButton />
      </Box>
      <Box 
        component="nav"
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          width: sizeConfigs.sidebar.halfwidth,
          flexShrink: 0,
          overflowY: "auto",
        }}
      >
        <Sidebar />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          paddingX: 0,
          width: `calc(100vw - ${sizeConfigs.sidebar.halfwidth})`,
          height: "100vh",
          backgroundColor: colorConfigs.topbar.bg2,
          overflowY: 'auto',
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          overflowX: "hidden"
        }}
      > 
        <Outlet />
      </Box>
      {/* Edit Profile Dialog */}
      <Dialog
        open={popup === "Edit"}
        fullScreen
        onClose={() => { setPopup("") }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // TransitionComponent={Transition} // Uncomment if using a custom transition
        sx={{
          height: '100vh',
          minHeight: '100vh',
          '& .MuiDialog-paper': {
            alignItems: 'center',
            justifyContent: 'center',
            p: 0,
            height: '100vh',
            minHeight: '100vh'
          }
        }}
      >
        <AppBar
          onClick={() => { setPopup(""); }}
          sx={{
            position: 'fixed',
            top: 0,
            height: sizeConfigs.detailPage.appbar.height,
            minHeight: sizeConfigs.detailPage.appbar.height,
            maxHeight: sizeConfigs.detailPage.appbar.height,
            zIndex: 1201,
          }}
        >                  
          <Toolbar
            sx={{
              height: sizeConfigs.detailPage.appbar.height,
              minHeight: sizeConfigs.detailPage.appbar.height,
              maxHeight: sizeConfigs.detailPage.appbar.height,
              padding: 0,
              backgroundColor: colorConfigs.tables.headBg,
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              color: '#fff',
            }}
          >                
            {popup && (
              <Typography
                onClick={(e) => e.stopPropagation()} 
                sx={{
                  color: '#fff',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: 20,
                  fontWeight: "bold",
                  m: "auto",
                  mt: 0,
                  mb: 0,
                }}
              >
                Edit Profile
              </Typography>
            )}
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => { setPopup("") }}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                padding: 0,
                fontSize: '18px',
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent
          sx={{
            height: sizeConfigs.detailPage.dialogContent.height,
            minHeight: sizeConfigs.detailPage.dialogContent.height,
            overflowY: 'auto',
            '&::-webkit-scrollbar': { display: 'none' },
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            minWidth: '100vw',
            p: 0,
            m: 0,
            backgroundColor: colorConfigs.sidebar.bg,
            mt: sizeConfigs.detailPage.appbar.height,
          }}
        >                  
          <EditProfileCard setPopup={setPopup} handleBackButton={handleBackButton} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MainLayout;
