import React, { ReactElement } from "react";
import { Chip } from "@mui/material";

interface RareChipProps {
  children: React.ReactNode;
  disabled?: boolean;
  variant: "contained" | "outlined" | "success" | "cancel";
  onClick?: () => void;
  onDelete?: () => void;
  deleteIcon?: ReactElement | null
}

const FBLimitChip: React.FC<RareChipProps> = ({
  children,
  disabled,
  variant,
  onClick,
  onDelete,
  deleteIcon,
}) => {
  return (
    <Chip
      label={children}
      variant={variant as any}
      disabled={disabled}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={onDelete && deleteIcon ? deleteIcon : undefined} 
      sx={{
        height: '100%',
        fontSize: "12px",
        fontWeight: "bold",
        cursor: onClick ? "pointer" : "default",
        py:0.2
      }}
    />
  );
};

export default FBLimitChip;
