import React, { ReactElement } from "react";
import { Button, Box, SxProps, Theme } from "@mui/material";

interface RareButtonProps {
  children?: React.ReactNode; // Required: Button text or elements inside the button
  disabled?: boolean; // Optional: Disables the button when set to true
  variant: "contained" | "outlined" | "success" | "cancel"; // Required: Determines the button style
  onClick?: (...args: any[]) => void; // Allows onClick to accept any arguments
  icon?: ReactElement | null; // Optional: Icon to display on the right side of the button
  type?: "button" | "submit" | "reset"; // Optional: Button type (defaults to "button")
  fullWidth?: boolean; // Optional: Makes the button stretch to full width if true
  center?: boolean; // Optional: Centers the button inside a flex container if true
  sx?: SxProps<Theme>; // Optional: Custom styles using MUI's sx prop
}

const RareButton: React.FC<RareButtonProps> = ({
  children,
  disabled,
  variant,
  onClick,
  icon,
  type = "button",
  fullWidth = false,
  center = false,
  sx = {}, // Default to empty object to avoid undefined errors
}) => {
  const button = (
    <Button data-testid="Button-sdr0"
      type={type}
      variant={variant as any}
      fullWidth={fullWidth}
      disabled={disabled}
      onClick={onClick}
      sx={{
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        gap: 1,
        minWidth: 0,
        minHeight: 0,
        ...sx, // Merge user-defined styles with default styles
      }}
    >
      <Box data-testid="Box-v6nd" component="span">{children}</Box>
      {icon && (
        <Box data-testid="Box-swvl"
          component="span"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </Box>
      )}
    </Button>
  );

  // ✅ Wrap button in a centered flex container if center is true
  return center ? (
    <Box data-testid="Box-is32" sx={{ display: "flex", justifyContent: "center", width: fullWidth ? "100%" : "auto" }}>
      {button}
    </Box>
  ) : (
    button
  );
};

export default RareButton;
