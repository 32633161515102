import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { isLoggedIn } from '../../redux/features/auth/authService';
import SignInDialog from '../../components/common/SignInDialog';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { InvoiceSessionStorageKey, InvoiceTableParameterStorageService } from '../../services/sessionStorage';
import { TransitionProps } from '@mui/material/transitions';

import InvoiceTable from '../../components/common/tables/InvoiceTable';
import api from '../../redux/features/auth/axiosAuthHelper';
import InvoiceDynamicFilterBar from '../../components/common/filterbars/InvoicesDynamicFilterBar';

const loginpage = "/login"


type Props = {}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const InvoicesPage = (props: Props) => {

const navigate = useNavigate()
const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [invoice, setInvoice] = useState<any>(null);
const [popup, setPopup] = useState<any>("");
const [totalCount, setTotalCount] = useState<any>(0); 
const [totalCountLoaded, setTotalCountLoaded] = useState<any>(0);
const [noResultsForQuery, setNoResultsForQuery] = useState<any>(false); 

const [sorting, setSorting] = useState<any>(() => {
  return InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.sorting) || [{id: 'created', desc: true}]
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.columnVisibility) || {id: false}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.pagination) || { pageSize: 250, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.showSearch) || false
});

const [rows, setRows] = useState<any>(() => {
  return InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.data) || []
});

const [queryString, setQueryString] = useState<any>(() => {
  return InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.currentQueryString) || ""
});

useEffect(() => {
  console.log(sorting)
},[sorting])

const handleRefreshButton = () => {
  setInvoice("")
  fetchData(queryString)
  setPopup("")
}

const handleBackButton = () => {
  setInvoice("")
  setPopup("")
}


const fetchData = async (query = "") => {
  setIsLoading(true);
  const data: any[] = [];
  let downloadPageSize = 250;  // Set the batch size to 250
  let downloadOffset = 0;
  let total = 0;

  try {
      const url = query ?  `payments/invoices/?${query}` : `payments/invoices/`

      // Fetch the first batch to get the total count
      const initialResponse = await api.get(url, {
          headers: {
              'Authorization': `Bearer ${authData.access}`
          }
      });
      console.log("HELLO", initialResponse.data.results)

      if (initialResponse.data.results.length < 1) {
        console.log("No results for this query")
        setIsLoading(false);
        setNoResultsForQuery(true)
        setRows([]);
        return data
      } else {
        data.push(...initialResponse.data.results);
        total = initialResponse.data.count;
        downloadOffset += downloadPageSize;
        setTotalCount(total);
        setTotalCountLoaded(data.length);
        setIsLoading(false);
        setRows([...data]);
        setQueryString(query)
        InvoiceTableParameterStorageService.set(InvoiceSessionStorageKey.currentQueryString, query)
      }
      // Now fetch the remaining lots in the background
      while (data.length < total) {
          const url = query ?  `payments/invoices/?${query}` : `payments/invoices/`

          const response = await api.get(url, {
              headers: {
                  'Authorization': `Bearer ${authData.access}`
              }
          });

          data.push(...response.data.results);
          downloadOffset += downloadPageSize;
          setTotalCountLoaded(data.length);
          console.log(`Downloaded ${data.length} of ${total} results`);
      }

      // Final update after all lots are fetched
      setRows([...data]);
      console.log('Downloaded all results:', data);

      InvoiceTableParameterStorageService.set(InvoiceSessionStorageKey.data, data)
      InvoiceTableParameterStorageService.set(InvoiceSessionStorageKey.count, total)

      setTimeout(() => { 
        setTotalCount(false)
      }, 6000);

  } catch (error) {
      console.error('Error fetching results:', error);
  } finally {
    setIsLoading(false);
    return data
  }
};

useEffect(() => { 
  const dataInStorage = InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.count);
  if ((!dataInStorage || dataInStorage === 0) && (rows.length < 1 || !rows.length)) {
    // No data in storage and no rows loaded, show loading and fetch data
    fetchData();
  } else if (dataInStorage && rows.length < 1) {
      // Data exists in session storage but rows haven't loaded yet, show loading until rows are ready
      setTotalCount(dataInStorage);
      setTotalCountLoaded(0);
      setIsLoading(true)
      const storedData = InvoiceTableParameterStorageService.get(InvoiceSessionStorageKey.data) || [];

      const fetchStoredDataInBatches = async () => {
        const batchSize = 1000;
        let batchIndex = 0;
        let allData: any[] = [];
        // Retrieve patients from storage in batches of 250 until all are fetched
        while (batchIndex * batchSize < storedData.length) {
          const batch = storedData.slice(batchIndex * batchSize, (batchIndex + 1) * batchSize);
          if (batchIndex >= 1) {setIsLoading(false)}
          allData = [...allData, ...batch];
          setRows([...allData]);  // Update rows incrementally
          batchIndex++;
          setTotalCountLoaded(allData.length)
          await new Promise(resolve => setTimeout(resolve, 10));
        }
        setTimeout(() => { // Hide progress bar
          setTotalCount(false)
        }, 3000);
        setIsLoading(false);  // All batches loaded, stop loading
      };
  
      fetchStoredDataInBatches();
  } else if (rows.length > 0) {
    // Rows are loaded, stop loading
    setIsLoading(false);
  }
}, [rows.length, authData.isAuthenticated]);


const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 250, pageIndex: 0 })
  setSorting([])
}

const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  if (!isLoggedIn(authData.access)) {
    dispatch(refreshAccessToken());
  }
}, [dispatch, authData.access, navigate]);

  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated ? (
      <div data-testid="div-fkb8" style={{ overflowX: "hidden", maxHeight: `100%`, minHeight: `100%`, maxWidth: `100%`, minWidth: `100%`, margin: "auto"}}>

      <div data-testid="div-o69s" style={{ display: "flex", height: "100%", width: "100%", maxWidth: `100%`, minWidth: `100%`,}}>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{m:0, height: "100%", width: "100%", maxWidth: `100%`, minWidth: `100%`,}}
        >
          <Grid item xs={3} sx={{m:0, p:0, maxWidth: `100%`, minWidth: `100%`}}>
            <Box data-testid="Box-pyhm" sx={{ m:0, p:3, transition: 'padding-top 0.2s ease', height: "100%", overflowY: "auto", marginRight: 0, display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `93vw`, minWidth: `100%` }}>
                <Box data-testid="Box-in0d" sx={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', boxShadow: 5, borderTopRightRadius: 3, borderTopLeftRadius: 3 }}>
                <Box data-testid="Box-3240" sx={{
                    borderRadius: 1,
                    height: 'calc(100vh - 50px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <InvoiceDynamicFilterBar 
                        setIsLoading={setIsLoading} 
                        handleRemoveAllFilters={handleRemoveAllFilters} 
                        fetchData={fetchData}
                        totalCount={0} 
                        totalCountLoaded={0}
                        />            
                <InvoiceTable
                      rows={rows}
                      isLoading={isLoading}
                      sorting={sorting}
                      columnVisibility={columnVisibility}
                      columnFilters={columnFilters}
                      pagination={pagination}
                      globalFilter={globalFilter}
                      showGlobalFilter= {showGlobalFilter}
                      setShowGlobalFilter={setShowGlobalFilter}
                      handleRemoveAllFilters={handleRemoveAllFilters}
                      setGlobalFilter={setGlobalFilter}
                      setSorting={setSorting}
                      setColumnVisibility={setColumnVisibility}
                      setColumnFilters={setColumnFilters}
                      setPagination={setPagination}
                      setInvoice={setInvoice}
                      setPopup={setPopup}
                      handleRefreshButton={handleRefreshButton}
                    />
                  </Box>
                  </Box>
                </Box>
          </Grid>
        </Grid>
      </div>
    </div>
    
) : (null);
};

export default InvoicesPage;
