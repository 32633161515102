import { Card, Grid, Typography, Dialog, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle, ListItem, ListItemText, Switch, List, ListSubheader, DialogContent, IconButton, DialogTitle, Divider, Stepper, StepLabel, Step, Snackbar, Tooltip } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs from 'dayjs';
import { SmallTextEditField, HousingResolutionSelectField, HousingResolutionReadOnlySelectField, SmallEditReadOnlyField } from '../fields/fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import { HousingSessionStorageKey, HousingTableParameterStorageService } from '../../../services/sessionStorage';
import CartesianGrid from '../graphs/CartesianGrid';
import FileUploadCard from './FileUploadCard';
import MuiAlert from '@mui/material/Alert';
import FileDownloadCard from './FileDownloadCard';
import sizeConfigs from '../../../configs/sizeConfigs';
import RareButton from '../buttons/RareButton';

interface HousingDetailProps {
  housing_id?: any | null;
  handleRefreshButton: () => void;
  handleBackButton: () => void;
  handleActiveToggle: () => void;
}

const resolution = [
    { label: "VGA: 640 x 480", value: [640, 480], height: "240px", width: "320px" },
    { label: "720p: 1280 x 720", value: [1280, 720], height: "240px", width: "427px" },
    { label: "1080p: 1920 x 1080", value: [1920, 1080], height: "240px", width: "427px" },
];


const HousingDetailCard: React.FC<HousingDetailProps> = ({housing_id, handleRefreshButton, handleBackButton, handleActiveToggle}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const [housing, setHousing] = useState<any>(null);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [image, setImage] = useState("");
    const [graphData, setGraphData] = useState<any>()
    const [aspectRatioWidth, setAspectRatioWidth] = useState<string>("400px");
    const [aspectRatioHeight, setAspectRatioHeight] = useState<string>("400px");
    const create = !housing_id



    // Handle switch toggle
    const handleSwitchToggle = async (event: any) => {
        formik.setFieldValue('is_active', event.target.checked);
        await formik.submitForm();  // Submit form with only 'is_active'
    
        // Show success message in Snackbar
        setSnackbarMessage(event.target.checked ? "Housing activated" : "Housing deactivated");
        setIsSnackbarOpen(true);
    };
    
    const validationSchema = () => {
        return Yup.object().shape({

        });
    }

    // Fetch housing data and point_model JSON data
    useEffect(() => {
        if (housing_id) {
            const fetchHousing = async () => { 
                try {
                    const url = `housing/${housing_id}`;
                    
                    const response = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });
                    
                    const fetchedHousing = response.data;
                    if (fetchedHousing){
                        setHousing(fetchedHousing);
                        setImage(fetchedHousing.image)
                    }

                    // If point_model URL exists, fetch the JSON data
                    if (fetchedHousing.point_model) {
                        try {
                            const modelResponse = await fetch(fetchedHousing.point_model);
                            const modelData = await modelResponse.json();
                            setGraphData(modelData); // Set graphData for the chart
                    } catch (error) {
                        console.error('Error fetching point model data', error);
                        setErrors(`Error fetching point model data ${error}`);
                    }
                }
            } catch (fetchError: any) {
                if (fetchError.response !== "Request cancelled... refreshing token"){
                console.error('Error fetching housing or point model data', fetchError);
                    setErrors(`No Housing found with ID ${housing_id}`)
                    setTimeout(() => {
                        handleBackButton()
                    }, 4000);
                }}
            };
            fetchHousing();    
        }
    }, [housing_id]);

    // // Keeping This For Development Just Now
    // useEffect(() => {
    //     if (housing_id) {
    //         const fetchHousing = async () => { 
    //             try {
    //                 const url = `housing/${housing_id}`;
                    
    //                 const response = await api.get(url, {
    //                     headers: {
    //                         'Authorization': `Bearer ${authData.access}`
    //                     }
    //                 });
                    
    //                 const fetchedHousing = response.data;
    //                 setHousing(fetchedHousing);
    
    //                 // If point_model URL exists, ensure it has the correct port
    //                 if (fetchedHousing.point_model) {
    //                     const pointModelUrl = new URL(fetchedHousing.point_model);
    
    //                     // Check if port is missing, and add it if necessary
    //                     if (!pointModelUrl.port) {
    //                         pointModelUrl.port = '8000'; // Default to 8000 if no port is specified
    //                     }
    
    //                     // Fetch the JSON data from the modified URL
    //                     const modelResponse = await fetch(pointModelUrl.toString());
    //                     const modelData = await modelResponse.json();
    //                     setGraphData(modelData); // Set graphData for the chart
    //                     console.log("graphData", modelData)
    //                 }
    
    //                 // If image URL exists, ensure it has the correct port
    //                 if (fetchedHousing.image) {
    //                     const imageUrl = new URL(fetchedHousing.image);
    
    //                     // Check if port is missing, and add it if necessary
    //                     if (!imageUrl.port) {
    //                         imageUrl.port = '8000'; // Default to 8000 if no port is specified
    //                     }
    
    //                     setImage(imageUrl.toString()); // Set image URL with correct port
    //                 }

    //             } catch (error) {
    //                 console.error('Error fetching housing, point model, or image data', error);
    //             }
    //         };
    //         fetchHousing();    
    //     }
    // }, [housing_id]);
    


    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && housing) ? {
                id: housing.id || '',
                classifier: housing.classifier || null,
                description: housing.description || '',
                image: housing.image || null,
                name: housing.name || '',
                organisation: housing.organisation,
                max_conformance_probability: housing.max_conformance_probability || '',
                min_conformance_probability: housing.min_conformance_probability || '',
                point_model: housing.point_model || null,
                reader_resolution: housing.reader_resolution || [640, 480], // VGA default              
                date_created: housing.date_created ? dayjs(housing.date_created).format('YYYY-MM-DD HH:mm:ss') : null, // Cant edit - Set to null if no date
                date_updated: housing.date_updated ? dayjs(housing.date_updated).format('YYYY-MM-DD HH:mm:ss') : null, // Cant edit - Set to null if no date
                camera_macro_focus_required: housing.camera_macro_focus_required || true,
                is_active: housing.is_active
            }
            : {
                // id: '',
                name: '',
                description: '',
                organisation: authData.organisation,
                max_conformance_probability: 0.85,
                min_conformance_probability: 0.5,
                point_model: null,
                classifier: null,
                image: null,
                reader_resolution: [640, 480],
                camera_macro_focus_required: true,
                is_active: true
            },
        validationSchema,
        onSubmit: async (values) => {

            const formData = new FormData();

            if (create) {
                formData.append('name', values.name);
                formData.append('description', values.description);
                formData.append('organisation', values.organisation);
                formData.append('max_conformance_probability', values.max_conformance_probability);
                formData.append('min_conformance_probability', values.min_conformance_probability);
                formData.append('camera_macro_focus_required', values.camera_macro_focus_required);
                formData.append('is_active', values.is_active);

                // Convert reader_resolution id to array format and append
                formData.append('reader_resolution', JSON.stringify(values.reader_resolution));

                // Append files
                if (values.point_model) formData.append('point_model', values.point_model);
                if (values.classifier) formData.append('classifier', values.classifier);
                if (values.image) formData.append('image', values.image);

            } else {
                if (authData.isAdmin) {
                  formData.append('is_active', values.is_active);
                }
            }

            const url = housing ? `housing/${housing.id}/` : `housing/`
            const method = housing ? 'patch' : 'post'

            try {
                await api ({
                    method,
                    url,
                    data: formData,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setErrors(null);
                console.log("Housing successfully updated")
                if (method === 'post') {
                    handleRefreshButton() // reset states and close popup
                }
                if (method === 'patch') {
                    handleActiveToggle() // refetches new data without closing popup
                }
                const count = HousingTableParameterStorageService.get(HousingSessionStorageKey.count)
                HousingTableParameterStorageService.set(HousingSessionStorageKey.count, count + 1) // Updated count to not trigger refresh data button
            } catch(error: any) {
                console.error("Error updating housing", error);

                if (error.response) {
                    console.log("Response data:", error.response.data);
                    console.log("Response status:", error.response.status);
                    console.log("Response headers:", error.response.headers);
                } else if (error.request) {
                    console.log("Request error, no response:", error.request);
                } else {
                    console.log("Error setting up request:", error.message);
                }


                if (error?.response?.status === 400) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
                } else if (error?.response?.status === 403) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${error.response.data[firstErrorItem]}`);
                } else{
                    const errorText = `Unknown error updating housing`;
                    setErrors(errorText);
                    console.error(errorText, error);
                }
                // window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    });

    const steps = [
        'Describe It',
        'Assign Model',
        'Assign Classifier',
        'Configure Reader',
        'Save',
    ];


    // Function to determine the current active step
    const getActiveStep = () => {
        if (!formik.values.name || !formik.values.description) return 0;
        if (!formik.values.point_model) return 1;
        if (!formik.values.classifier) return 2;
        if (!formik.values.reader_resolution || 
            typeof formik.values.min_conformance_probability !== 'number' || 
            typeof formik.values.max_conformance_probability !== 'number') return 3;
        return 4; // All previous steps are complete, move to Save step
    };

    const activeStep = getActiveStep();
    const isSaveStep = activeStep === steps.length - 1;


    // Handlers for uploaded files
    const handlePointModelChange = async (file: File) => {
        const fileContent = await file.text(); // Read file content as text
        const jsonData = JSON.parse(fileContent); // Parse JSON content
        setGraphData(jsonData); // Set parsed JSON as graphData
        formik.setFieldValue('point_model', file); // Update form field if needed
      };
    const handleClassifierChange = (file: any) => formik.setFieldValue('classifier', file);
    const handleImageChange = (file: File) => {
        formik.setFieldValue('image', file);
        // Create a preview URL for the image
        const imageUrl = URL.createObjectURL(file);
        setImage(imageUrl);
        // Clean up the URL when the component unmounts or a new file is selected
        return () => URL.revokeObjectURL(imageUrl);
    };


    useEffect(() => {
        if (!graphData || !graphData.points.length) return;
    
        const xValues = graphData.points.map((point: any) => point[0][0]);
        const yValues = graphData.points.map((point: any) => point[0][1]);
    
        const minX = Math.min(...xValues);
        const maxX = Math.max(...xValues);
        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);
    
        const width = maxX - minX;
        const height = maxY - minY;
    
        if (width > 0 && height > 0) {
            const aspectRatio = width / height;
    
            const maxHeight = 280; // Maximum height constraint
            const maxWidth = 600;  // Maximum width constraint
    
            let calculatedHeight = maxHeight;
            let calculatedWidth = Math.round(calculatedHeight * aspectRatio);
    
            // If calculated width exceeds maxWidth, recalculate based on maxWidth
            if (calculatedWidth > maxWidth) {
                calculatedWidth = maxWidth;
                calculatedHeight = Math.round(calculatedWidth / aspectRatio);
            }
    
            setAspectRatioHeight(`${calculatedHeight}px`);
            setAspectRatioWidth(`${calculatedWidth}px`);
        }
    }, [graphData]);
    

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>

            {/* Outer Container */}
            <Grid
                container
                direction="column"
                sx={{
                    m: 0,
                    minHeight: sizeConfigs.detailPage.dialogContent,
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around", // Ensures even vertical spacing
                    alignItems: "stretch", // Ensures grids stretch properly
                    overflowY: "auto",
                    px: { xs: 8, xl: 20 },
                }}
                >              
                        {/* item 1 */}
                        { create &&
                         <Grid item xs={12} md={2} sx={{pt: {xs: 3, lg: 4, xl: 6}, height: "auto", width: '100%'}}>
                          <Grid container sx={{ gap: 4, width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <Grid item xs={12} md={3}>
                                    <Typography data-testid="Typography-lul0" sx={{ fontSize: 36, fontWeight: "bold" }}>Create Housing</Typography>
                                </Grid>
                                <Grid item xs={12} md={8} sx={{mr: {xs: -8, xl: -8}}}>
                                <Stepper data-testid="Stepper-rxen" activeStep={activeStep} alternativeLabel>
                                    {steps.map((label, index) => (
                                        <Step data-testid="Step-aysr" key={label} completed={index < activeStep}>
                                        <StepLabel data-testid="StepLabel-635b"
                                            sx={{
                                            '& .MuiStepIcon-root': {
                                                color: index < activeStep ? colorConfigs.buttons.active : undefined, // Green for completed
                                            },
                                            '& .MuiStepIcon-text': {
                                                fill: '#fff', // Optional: Adjust text color for contrast
                                            },
                                            }}
                                        >
                                         {label}
                                        </StepLabel>
                                        </Step>
                                    ))}
                                    </Stepper>
                                </Grid>
                           </Grid>
                           </Grid>
                        }   

                        {/* item 2  */}
                           <Grid item xs={12} md={2} sx={{pt: {xs: 3, lg: 4, xl: 6}, flexGrow: 1, height: "100%", width: '100%' }}>
                           
                           {errors && (
                                 <Alert data-testid="Alert-0523" severity="error" style={{ width: "100%%" }}>
                                    <AlertTitle data-testid="AlertTitle-pvj6">Error</AlertTitle>
                                    {errors}
                                </Alert>
                            )}

                                <Grid container xs={12} spacing={ create ? 5 : 0} direction={{xs: 'column', sm: 'row', md: 'row', lg: 'row'}} sx={{ pr: {xs: 4, md: 0}, gap: 0, display: 'flex', justifyContent: 'space-between' }}>
                                    <Grid item xs={3} sx={{}}>
                                        <Typography data-testid="Typography-u662" sx={{color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>Name</Typography>
                                        <Box data-testid="Box-7d80" sx={{pl: 1.5}}>
                                            <Field data-testid="Field-wfmv" name="name" type="text" className="form-control" placeholder="Name" component={create ? SmallTextEditField : SmallEditReadOnlyField} style={{marginTop: 8}} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={!create ? 6 : 9} sx={{}}>
                                        <Typography data-testid="Typography-cq3f" sx={{color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold'}}>Description</Typography>
                                        <Box data-testid="Box-021y" sx={{pl: 1.5}}>
                                            <Field data-testid="Field-81go" name="description" type="text" className="form-control" placeholder="Description" component={create ? SmallTextEditField : SmallEditReadOnlyField} style={{marginTop: 8}} />
                                        </Box>
                                    </Grid>
                                    { !create  && 
                                    <Grid item xs={1} sx={{}}>
                                        <Typography data-testid="Typography-szlw" sx={{color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold' }}>ID</Typography>
                                        <Box data-testid="Box-dvt2" sx={{pl: 1.5}}>
                                            <Field data-testid="Field-9pzx" name="id" type="text" className="form-control" placeholder="ID" component={SmallEditReadOnlyField}  style={{marginTop: 8}} /> 
                                        </Box>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>

                        {/* item 3  */}
                            <Grid item xs={12}  sx={{pt: {xs: 3, lg: 4, xl: 6}, flexGrow: 1, height: "100%", width: '100%', }}>
                               
                                <Grid container xs={12} spacing={0} justifyContent={'space-between'} direction={{xs: 'column', sm: 'column', md: 'row'}} sx={{ height: "100%", width: '100%'}}>    
                                    <Grid item xs={12} sm={12} md={7.5} direction={'column'}>    
                                       
                                        <Grid container xs={12} sm={12}  md={12}  lg={11}  xl={10} spacing={1} direction={{xs: 'column', sm: 'row'}} sx={{ pr: {xs: 4, md: 0}, display: 'flex', justifyContent: 'space-between', height: "100%", width: '100%', }}>    
                                        <Grid item>
                                            <Typography data-testid="Typography-wb0k" sx={{color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold', mb: 1 }}>Model</Typography>
                                            <Box data-testid="Box-se6l" sx={{ pl: 1.5 }}>
                                                {!create && housing ? (
                                                    <FileDownloadCard url={housing.point_model} authData={authData} fileUrl={`housing/${housing.id}/download-point-model/`}/>
                                                ) : (
                                                    <FileUploadCard title="Model" handleChange={handlePointModelChange} name="point_model" types={['JSON']} />
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Typography data-testid="Typography-ql60" sx={{color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold', mb: 1 }}>Classifier</Typography>
                                            <Box data-testid="Box-n1fj" sx={{ pl: 1.5 }}>
                                                {!create && housing ? (
                                                    <FileDownloadCard url={housing.classifier} authData={authData} fileUrl={`housing/${housing.id}/download-classifier/`} />
                                                ) : (
                                                    <FileUploadCard title="Classifier" handleChange={handleClassifierChange} name="classifier" types={['JSON']} />
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid item>
                                            <Typography data-testid="Typography-tder" sx={{color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold', mb: 1 }}>Full Image</Typography>
                                            <Box data-testid="Box-kqxf" sx={{ pl: 1.5 }}>
                                                {!create && housing ? (
                                                    <FileDownloadCard url={housing.image} authData={authData} fileUrl={`housing/${housing.id}/download-image/`} />
                                                ) : (
                                                    <FileUploadCard title="Full Image" handleChange={handleImageChange} name="image" types={['JPG', 'PNG', 'JPEG']} />
                                                )}
                                            </Box>
                                        </Grid>

                                        </Grid>
                                    </Grid>

                                

                                    <Grid item xs={12} sm={12} md={4.5} sx={{flexGrow: 1}}>
                                            <Grid item xs={12} direction="column" sx={{ flexGrow: 1 }}>
                                                <Typography data-testid="Typography-9arf" sx={{color: colorConfigs.primary, fontSize: 18, fontWeight: 'bold', mb: 0.5 }}>Reader Parameter</Typography>
                                                <Grid container  xs={12} spacing={0} alignItems="center" sx={{ pl: 1}}>
                                                    <Grid item xs={6} sx={{minHeight: '2em'}}>
                                                        <Typography data-testid="Typography-ylf2" sx={{ minHeight: '2em', display: 'flex', alignItems: 'center' }}>Camera Macro Focus Required</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{minHeight: '47px', display: 'flex', alignItems: 'center'}}>
                                                    <Switch data-testid="Switch-jq82"
                                                        edge="end"
                                                        checked={formik.values.camera_macro_focus_required}
                                                        onChange={() => {
                                                            if (create) {
                                                                formik.setFieldValue('camera_macro_focus_required', !formik.values.camera_macro_focus_required);
                                                            }
                                                        }}
                                                        inputProps={{ 'aria-labelledby': 'switch-list-label-camera-macro-focus' }}
                                                        sx={{ my: 0 }}
                                                    />
                                                    </Grid>

                                                    <Grid item xs={6} sx={{minHeight: '2ems'}}>
                                                        <Typography data-testid="Typography-kcpv" sx={{ minHeight: '2ems', display: 'flex', alignItems: 'center' }}>Reader Resolution </Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{minHeight: '47px', display: 'flex', alignItems: 'center'}}>
                                                        <Field data-testid="Field-yk7m"
                                                            name="reader_resolution"
                                                            component={create ? HousingResolutionSelectField : HousingResolutionReadOnlySelectField}
                                                            resolutionOptions={resolution}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Typography data-testid="Typography-130k" sx={{ minHeight: '2ems', display: 'flex', alignItems: 'center' }}>Test PMF Min Conformance Probability</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{minHeight: '47px', display: 'flex', alignItems: 'center'}}>
                                                        <Field data-testid="Field-9p19"
                                                            name="min_conformance_probability"
                                                            type="number"
                                                            component={create ? SmallTextEditField : SmallEditReadOnlyField}
                                                            style={{maxWidth: '80px'}}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={6} sx={{minHeight: '2ems'}}>
                                                        <Typography data-testid="Typography-vc6s" sx={{ minHeight: '2ems', display: 'flex', alignItems: 'center' }}>Test PMF Max Conformance Probability</Typography>
                                                    </Grid>
                                                    <Grid item xs={6} sx={{minHeight: '47px', display: 'flex', alignItems: 'center'}}>
                                                        <Field data-testid="Field-jjbg"
                                                            name="max_conformance_probability"
                                                            type="number"
                                                            component={create ? SmallTextEditField : SmallEditReadOnlyField}
                                                            style={{maxWidth: '80px'}}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>

                        {/* item 4  */}
                            <Grid item xs={12}  sx={{pt: {xs: 3, lg: 4, xl: 6}, height: "auto",  mt: "auto", width: '100%', pb: 5 }}>
                               <Grid container xs={12} justifyContent={'space-between'} sx={{ height: "100%", width: '100%'}}>    
                                    <Grid item xs={12} sm={10} sx={{ height: "100%", width: '100%'}} > 
                                        <Box data-testid="Box-jd70" sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', gap: 5, pl:1.5}}>

                                        <Box data-testid="Box-9xk2" sx={{maxWidth: {aspectRatioWidth}, maxHeight: {aspectRatioHeight}, minWidth: {aspectRatioWidth}, minHeight: {aspectRatioHeight}}}>
                                           { formik.values.point_model && <CartesianGrid graphdata={graphData} create={create} aspectRatioWidth={aspectRatioWidth} aspectRatioHeight={aspectRatioHeight} /> }
                                        </Box>
                                        
                                        <Box data-testid="Box-wobl" sx={{minWidth: aspectRatioWidth}}>  
                                        { image  && 
                                        <Box data-testid="Box-y0qy"
                                            component="img"
                                            src={image}
                                            alt="Full Image"
                                            sx={{
                                                borderRadius: 1,
                                                maxWidth: '440px',
                                                maxHeight: '280px',
                                                height: 'auto',
                                                objectFit: 'contain',
                                            }}
                                        />                                    
                                        }   
                                        </Box>
                                        </Box>
                                    </Grid>


                                    <>
                                    { create &&
                                        <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={1}
                                        direction="row"
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignSelf: "flex-end",
                                            gap: 2 // Add gap between buttons
                                        }}
                                    >           
                                        <RareButton data-testid="RareButton-q49y"
                                            variant="cancel"
                                            onClick={() => formik.resetForm()} // Reset form values to initial
                                            sx={{
                                                mt: { xs: 3, sm: 3, md: 3, lg: 0 },
                                                minWidth: '140px',
                                                maxWidth: '140px',
                                            }}
                                        >
                                            Reset
                                        </RareButton>         
                                        <Tooltip data-testid="Tooltip-06b3" title={!isSaveStep ? "Complete all steps to Save" : ""}>
                                            <span data-testid="span-56o4">
                                                <RareButton data-testid="RareButton-eldw"
                                                    type="submit"
                                                    variant="success"
                                                    disabled={!isSaveStep}
                                                    sx={{
                                                        mt: { xs: 3, sm: 3, md: 3, lg: 0 },
                                                        minWidth: '140px',
                                                        maxWidth: '140px',
                                                    }}
                                                >
                                                    Save Changes
                                                </RareButton>
                                            </span>
                                        </Tooltip>
                                    </Grid>
                               
                                    }
                                        {!create && (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={1}
                                                direction="row"
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    alignSelf: "flex-end",
                                                }}
                                                >
                                                <Box data-testid="Box-5wu7"
                                                    sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    p: 0.5,
                                                    pr: 2,
                                                    minWidth: '150px',
                                                    maxWidth: '150px',
                                                    borderRadius: 1.1,
                                                    border: '1px solid',
                                                    textAlign: 'center',
                                                    mt: {xs: 3, sm:3, md: 3, lg: 0}
                                                    }}
                                                >
                                                    <Switch data-testid="Switch-029n"
                                                    checked={formik.values.is_active}
                                                    onChange={handleSwitchToggle}
                                                    sx={{
                                                        '& .MuiSwitch-switchBase.Mui-checked': {
                                                        color: colorConfigs.buttons.active,
                                                        },
                                                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                                        backgroundColor: colorConfigs.buttons.active,
                                                        },
                                                    }}
                                                    />
                                                    <Typography data-testid="Typography-z16b" sx={{ ml: 1 }}>
                                                    {formik.values.is_active ? "Active" : "Inactive"}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                        )}
                                    </>
                               </Grid>
                            </Grid>
                </Grid>
                <Snackbar open={isSnackbarOpen} autoHideDuration={3000} onClose={() => setIsSnackbarOpen(false)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} sx={{ mb: '-10px' }} >
                    <MuiAlert onClose={() => setIsSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                        {snackbarMessage}
                    </MuiAlert>
                </Snackbar>
            </form>
        </FormikProvider>
    );
};

export default HousingDetailCard;