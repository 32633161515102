import React, { ReactElement } from "react";
import { Chip, SxProps, Theme } from "@mui/material";

interface RareChipProps {
  children: React.ReactNode;
  disabled?: boolean;
  variant: "contained" | "outlined" | "success" | "cancel";
  onClick?: () => void;
  onDelete?: () => void;
  deleteIcon?: ReactElement | null
  sx?: SxProps<Theme>; // Optional: Custom styles using MUI's sx prop
}

const RareChip: React.FC<RareChipProps> = ({
  children,
  disabled,
  variant,
  onClick,
  onDelete,
  deleteIcon,
  sx = {}, // Default to empty object to avoid undefined errors
}) => {
  return (
    <Chip
      label={children}
      variant={variant as any}
      disabled={disabled}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={onDelete && deleteIcon ? deleteIcon : undefined} 
      sx={{
        fontSize: "0.875rem",
        fontWeight: 500,
        cursor: onClick ? "pointer" : "default",
        ...sx, // Merge user-defined styles with default styles
      }}
    />
  );
};

export default RareChip;
