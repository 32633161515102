import React, { useState } from "react";
import { Button } from "@mui/material";
import Popup from "./Popup";
import ImageGrid from "./ImageGrid";
import { ImageItem } from "../CustomHooks";
import RareButton from "../buttons/RareButton";

interface ResultSelectorCardProps {
  onAccept?: (selectedImage: ImageItem | null) => void;
  datasetId?: string;
}

const ResultSelectorCard: React.FC<ResultSelectorCardProps> = ({
  onAccept,
  datasetId,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<ImageItem | undefined>();
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const handleAccept = () => {
    if (selectedImage) {
      if (onAccept) {
        onAccept(selectedImage);
      }
      closeDialog();
    }
  };

  return (
    <>
      <RareButton data-testid="RareButton-wxbh"
        disabled={false}
        variant="contained"
        onClick={openDialog}
        sx={{ margin: "15px", fontWeight: "bold" }}
      >
        Select Result
      </RareButton>
      {isOpen && (
        <Popup
          isOpen={isOpen}
          closeDialog={closeDialog}
          width="md"
          title="Results"
          actions={
            <>
              <RareButton data-testid="RareButton-qc3f"
                onClick={closeDialog}
                variant="outlined"
                sx={{ margin: "10px" }}
              >
                Cancel
              </RareButton>
              <RareButton data-testid="RareButton-ill4"
                onClick={handleAccept}
                variant="success"
                sx={{ margin: "10px" }}
              >
                Accept
              </RareButton>
            </>
          }
        >
          <ImageGrid
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            onDoubleClick={handleAccept}
            datasetId={datasetId}
          />
        </Popup>
      )}
    </>
  );
};

export default ResultSelectorCard;
