import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ControlCorrection,
  ImageItem,
  Strip,
  StripRegion,
} from "../CustomHooks";
import ControlCorrectionPopup from "./ControlCorrectionPopup";
import RegionSelectorCard, { ImageProfile } from "./RegionSelectorCard";
import ProfileChart from "../graphs/ProfileChart";
import { Point } from "../../../utils/math";
import colorConfigs from "../../../configs/colorConfigs";

interface StripCardProps {
  dataset_id?: any;
  image?: ImageItem;
  position: number;
  label: string;
  onStateChange: (state: Strip) => void;
  onError: (key: string, error?: string) => void;
  data?: Strip;
  controlLineCorrection: boolean;
}

const StripCard: React.FC<StripCardProps> = ({
  dataset_id,
  image,
  position,
  label,
  onStateChange,
  onError,
  data,
  controlLineCorrection,
}) => {
  const [imageCorners, setImageCorners] = useState<Point[]>([]);
  const [stripRegions, setStripRegions] = useState<StripRegion[]>([]);
  const [initialRegions, setInitialRegions] = useState<StripRegion[]>([]);
  const [controlCorrection, setControlCorrection] = useState<
    ControlCorrection | undefined
  >();
  const [imageProfile, setImageProfile] = useState<ImageProfile | undefined>();
  const [controlCorrectionSearch, setControlCorrectionSearch] = useState<
    [number | undefined, number | undefined]
  >([undefined, undefined]);

  // If dataset changes in create Datarun page default states
  useEffect(() => {
    if (dataset_id) {
      setImageCorners([]);
      setStripRegions([]);
      setInitialRegions([]);
      setControlCorrection(undefined);
      setImageProfile(undefined);
      setControlCorrectionSearch([undefined, undefined]);
    }
  }, [dataset_id]);

  const validateRegions = (
    regions: StripRegion[],
    handleError: (key: string, error?: string) => void,
    stripPosition: number,
  ) => {
    // Count regions by category
    const testCount = regions.filter(
      (region) => region.category === "TEST",
    ).length;
    const baselineCount = regions.filter(
      (region) => region.category === "BASELINE",
    ).length;
    const controlCount = regions.filter(
      (region) => region.category === "CONTROL",
    ).length;

    // Validation errors
    const testError =
      testCount > 0 ? undefined : "At least one Test region is required.";
    const baselineError =
      baselineCount > 0
        ? undefined
        : "At least one Baseline region is required.";
    const controlError =
      controlCount <= 1 ? undefined : "At most one Control region is allowed.";

    // Report errors
    handleError(`strip-${stripPosition}-regions-test`, testError);
    handleError(`strip-${stripPosition}-regions-baseline`, baselineError);
    handleError(`strip-${stripPosition}-regions-control`, controlError);

    return testError || baselineError || controlError;
  };

  useEffect(() => {
    console.log("StripCard: Change on data:", position, controlCorrection);
    const imageError = imageProfile
      ? undefined
      : `Strip [${label}]: Profile not available`;
    onError(`strip-image-${position}`, imageError);

    const correctionError =
      !controlCorrection && controlLineCorrection
        ? `Strip [${label}]: Control correction not available`
        : undefined;
    onError(`strip-control-correction${position}`, correctionError);

    const regionErrors = validateRegions(stripRegions, onError, position);

    if (imageProfile && !correctionError && !regionErrors) {
      const stripData: Strip = {
        position,
        label,
        sample_rows: imageProfile.sample_rows,
        sample_cols: imageProfile.sample_cols,
        profile: imageProfile.profile,
        image_corners: imageCorners,
        control_correction: controlLineCorrection
          ? controlCorrection
          : undefined,
        strip_regions: stripRegions,
      };
      onStateChange(stripData);
    }
  }, [
    position,
    label,
    imageProfile,
    imageCorners,
    stripRegions,
    controlCorrection,
    onStateChange,
  ]);

  useEffect(() => {
    if (data) {
      setImageCorners(data.image_corners);
      setInitialRegions(data.strip_regions);
      if (data.profile.length) {
        setImageProfile({
          profile: data.profile,
          sample_rows: data.sample_rows,
          sample_cols: data.sample_cols,
        });
      } else {
        setImageProfile(undefined);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data?.control_correction) {
      setControlCorrection(data.control_correction);
    }
  }, [data?.control_correction]);

  useEffect(() => {
    if (!data?.control_correction && !controlCorrection) {
      setControlCorrection({
        extension: 50,
        trim: 50,
        div_profile: 3,
        step_search: 2,
        search_min: undefined,
        search_max: undefined,
        min_fit_percentage: 0.91,
        search_method: "window",
      });
    }
  }, [data?.control_correction, controlCorrection]);

  return (
    <>
      <Box
        data-testid="Box-j1y5"
        sx={{
          p: 2,
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: 2,
          mb: 3, // margin-bottom
          boxShadow: 3,
          // flex: 1,
        }}
      >
        <Typography
          data-testid="Typography-7e44"
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Assay Configuration
        </Typography>
        <div
          data-testid="div-tuxk"
          style={{
            display: "flex",
            flexDirection: "row",
            // flex: 5,
            gap: "1rem",
            width: "100%",
          }}
        >
          <Box
            data-testid="Box-5b12"
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={1}
            sx={{
              flex: 1, // Make the container flexible
              width: "100%", // Ensure it takes full width
            }}
          >
            {image && (
              <RegionSelectorCard
                imageUrl={image.image_url}
                homography={image.homography}
                setImageProfile={setImageProfile}
                setImageCorners={setImageCorners}
                imageCorners={imageCorners}
              />
            )}
            {imageProfile ? (
              <ProfileChart
                profile={imageProfile.profile}
                position={position}
                setStripRegions={setStripRegions}
                initialRegions={initialRegions}
                setControlCorrectionSearch={setControlCorrectionSearch}
              />
            ) : (
              <Box data-testid="Box-mddz">No profile data available</Box>
            )}
            {controlLineCorrection && (
              <div
                data-testid="div-tsvq"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // flex: 5,
                  gap: "1rem",
                  // width: "100%",
                }}
              >
                <div data-testid="div-ffzz" style={{ flex: 1 }}>
                  <ControlCorrectionPopup
                    data={controlCorrection}
                    setData={setControlCorrection}
                    defaultSearchMin={controlCorrectionSearch[0]}
                    defaultSearchMax={controlCorrectionSearch[1]}
                  />
                </div>
              </div>
            )}
          </Box>
        </div>
      </Box>
    </>
  );
};

export default StripCard;
