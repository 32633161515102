import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { blue, red, green, purple, yellow} from '@mui/material/colors'
import colorConfigs from './configs/colorConfigs';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      menu: 1150,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      'Helvetica Neue',
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
    allVariants: {
      color: colorConfigs.text, // Primary typography color
    },
  h1: {
    color: colorConfigs.text,
    fontSize: "2rem",
    fontWeight: 600,
  },
  h2: {
    color: colorConfigs.text,
    fontSize: "1.75rem",
    fontWeight: 500,
  },
  h3: {
    color: colorConfigs.text, // Secondary color for h3
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  h4: {
    color: colorConfigs.text, // Secondary color for h3
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  h5: {
    color: "#7a150d", // Secondary color for h3
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  h6: {
    color: "#7a150d", // Secondary color for h3
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  body1: {
    color: colorConfigs.text,
    fontSize: "1rem",
  },
  body2: {
    color: "#7a150d", // Secondary color for body2 text
    fontSize: "0.875rem",
  },
},
  text: {
    primary: colorConfigs.text,
    secondary: "#7a150d",
  },
  palette: {
    primary: {
      main: colorConfigs.text,
    },
    secondary: {
      main: "#7a150d"
    },
    background: {
      default: '#f9f9f9',
      gradient: 'linear-gradient(45deg, #E4261F 50%, #BB1B16 80%)',
      gradientCompany: 'linear-gradient(90deg, #E4261F 50%, #BB1B16 80%)',
      grey: '#919292', // Battleship Grey for backgrounds and contrasting
      silver: '#C3C4C4', // Silver for backgrounds and contrasting
      white_smoke: '#F5F5F5', // White smoke for backgrounds of components or contrasting
      off_white: '#EBEBEB', // off white for global background, less glaring that full white
      platinum: '#E0E0E1', // Plantinum, lighter silver
      whiteHeader: 'rgba(255, 255, 255, 0.3)', // White with 0.3 opacity
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          transition: "background 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out",
          textTransform: "none", // Prevents uppercase text
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          fontWeight: 'bold',
          "&:hover": {
            boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.3)",
          },
        },
        contained: {
          backgroundColor: "#6FA8DC", // Standard Contained Button (OK)
          color: "#fff",
          "&:hover": {
            backgroundColor: "#5F98CC", // Darker hover color
          },
          "&.Mui-disabled": {
            backgroundColor: "rgba(111,168,220, 0.4)",
          },
        },
        outlined: {
          backgroundColor: "transparent",
          // border: "1px solid #403b43",
          color: colorConfigs.text,
          "&:hover": {
            border: "1px solid #403b43",
            backgroundColor: "rgba(1,1,1, 0.03)",
          },
        },
      },
      // ✅ `variants` should be placed **outside** `styleOverrides`
      variants: [
        {
          props: { variant: "success" }, // Custom "success" variant
          style: {
            // backgroundColor: "#93C47D", //old
            backgroundColor: colorConfigs.buttons.success,
            color: "#fff",
            fontWeight: 'bold',
            "&:hover": {
              backgroundColor: colorConfigs.buttons.successHovered,
            },
            "&.Mui-disabled": {
              backgroundColor: colorConfigs.buttons.successDisabled,
            },
          },
        },
        {
          props: { variant: "cancel" }, // Custom "cancel" variant
          style: {
            // backgroundColor: "#F40003", // old
            backgroundColor: colorConfigs.buttons.cancel,
            color: "#fff",
            fontWeight: 'bold',
            "&:hover": {
              backgroundColor: colorConfigs.buttons.cancelHovered,
            },
            "&.Mui-disabled": {
              backgroundColor: colorConfigs.buttons.cancelDisabled
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
          fontWeight: 'bold',
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          "&:hover": {
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.3)",
          },
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: "#6FA8DC",
            color: "#fff",
            fontWeight: 'bold',
            "&:hover": {
              backgroundColor: "#5F98CC",
            },
            "&.Mui-disabled": {
              color: colorConfigs.text,
              backgroundColor: "rgba(111,168,220, 0.6)",
            },
            "& .MuiChip-deleteIcon": {
              color: "#fff", // Delete icon matches text color
              "&:hover": {
                color: "#fff",
              },
            },
            "&.Mui-disabled .MuiChip-deleteIcon": {
              color: colorConfigs.text, // Delete icon matches disabled text color
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            color: colorConfigs.text,
            px: 1,
            fontWeight: 'bold',
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "rgba(1,1,1, 0.02)",
            },
            "&.Mui-disabled": {
              borderColor: "rgba(64, 59, 67, 0.6)",
              color: colorConfigs.text,
            },
            "& .MuiChip-deleteIcon": {
              color: colorConfigs.text,
              "&:hover": {
                color: "#6FA8DC",
              },
            },
            "&.Mui-disabled .MuiChip-deleteIcon": {
              color: "rgba(64, 59, 67, 0.6)", // Delete icon matches disabled text color
            },
          },
        },
        {
          props: { variant: "success" },
          style: {
            px: 1,
            backgroundColor: colorConfigs.buttons.success,
            color: "#fff",
            fontWeight: 'bold',
            "&:hover": {
              backgroundColor: colorConfigs.buttons.successHovered,
            },
            "&.Mui-disabled": {
              color: colorConfigs.text,
              backgroundColor: colorConfigs.buttons.successDisabled,
            },
            "& .MuiChip-deleteIcon": {
              color: "#fff",
              "&:hover": {
                color: "#fff",
              },
            },
            "&.Mui-disabled .MuiChip-deleteIcon": {
              color: colorConfigs.text,
            },
          },
        },
        {
          props: { variant: "cancel" },
          style: {
            backgroundColor: colorConfigs.buttons.cancel,
            fontWeight: 'bold',
            color: "#fff",
            "&:hover": {
              backgroundColor: colorConfigs.buttons.cancelHovered,
            },
            "&.Mui-disabled": {
              color: colorConfigs.text,
              backgroundColor: colorConfigs.buttons.cancelDisabled,
            },
            "& .MuiChip-deleteIcon": {
              color: "#fff",
              "&:hover": {
                color: "#fff",
              },
            },
            "&.Mui-disabled .MuiChip-deleteIcon": {
              color: colorConfigs.text,
            },
          },
        },
      ],
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          // backgroundColor: "linear-gradient(45deg, #5A77B7, #7B97D4)", // Use the whiteHeader background color
          boxShadow: 'unset',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: colorConfigs.text, // Default border color
              backgroundColor: "rgba(0, 0, 0, 0.05)"
            },
            '&:hover fieldset': {
              borderColor: colorConfigs.text, // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: colorConfigs.text, // Border color when focused
            },
            '&.Mui-error fieldset': {
              borderColor: colorConfigs.text, // Border color when there is an error
            },
          },
          '& .MuiFormLabel-root': {
            fontWeight: 'bold',
            color: colorConfigs.text,
            '&.Mui-focused': {
              color: colorConfigs.text, // Label color when focused
            },
            '&.Mui-error': {
              color: colorConfigs.text, // Label color when there is an error
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: colorConfigs.text, // Default border color
              backgroundColor: "rgba(0, 0, 0, 0.05)"
            },
            '&:hover fieldset': {
              borderColor: colorConfigs.text, // Border color on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: colorConfigs.text, // Border color when focused
            },
            '&.Mui-error fieldset': {
              borderColor: colorConfigs.text, // Border color when there is an error
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: `${colorConfigs.text} !important`, // Ensures label is always #7a150d
          },
          '&.Mui-error': {
            color: `${colorConfigs.text} !important`, // Ensures label is always #7a150d
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root': {
            '&.Mui-focused': {
              color: colorConfigs.text, // Label color when focused
            },
            '&.Mui-error': {
              color: colorConfigs.text, // Label color when there is an error
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // color: colorConfigs.text, // For outlined text fields
          '& fieldset': {
            borderColor: colorConfigs.text, // Default border color
            backgroundColor: "rgba(0, 0, 0, 0.05)"
          },
          '&:hover fieldset': {
            borderColor: colorConfigs.text, // Border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: colorConfigs.text, // Border color when focused
          },
          '&.Mui-error fieldset': {
            borderColor: colorConfigs.text, // Border color when there is an error
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: colorConfigs.text, // Ensures label remains #7a150d for standard inputs
          },
          "&:before": {
            borderBottomColor: colorConfigs.text, // Underline color for standard inputs
          },
          "&:hover:before": {
            borderBottomColor: colorConfigs.text, // Keeps underline consistent on hover
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: "#C96969", // Error message color
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          boxShadow: 0,
          border: 0, 
          background: '#fff',   
          minHeight: 'calc(100vh - 150px)',
          maxHeight: 'calc(100vh - 150px)',
          flex: 1,
          overflowY: 'auto',
          paddingBottom: '300px',
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none', // Internet Explorer 10+
          scrollbarWidth: 'none', // Firefox
        },
      },
    },
    MuiCustomToolbar: {
      styleOverrides: {
        root: {
          position: 'sticky',
          background: '#f9f9f9',           
          boxShadow: 0,
          border: 0,
          top: 0,
          zIndex: 1,
          overflowX: 'auto',
          overflowY: 'hidden',
          p: 0.7,
          height: "fit-contents",
          maxHeight: "fit-contents",
          minHeight: "fit-contents",
          m: 0,
          color: colorConfigs.text,
          '& .MuiToolbar-root': {
             width: "50%",
             padding: 0,
             margin: 0,
             paddingBottom: 0,
             paddingTop: 0,
          },
          '& .MuiBox-root': {
            p:0
          },
          '& .MuiIconButton-root': {
            // color: colorConfigs.tables.headBg,
          },
          '& .MuiTextField-root': {
            p:0,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: colorConfigs.text,
              },
              '&:hover fieldset': {
                borderColor: colorConfigs.text,
              },
              '&.Mui-focused fieldset': {
                borderColor: colorConfigs.text,
              },
            },
            '& .MuiInputBase-input': {
              color: colorConfigs.text,
            },
            '& .MuiInputAdornment-root .MuiSvgIcon-root': {
              // color: colorConfigs.tables.headBg,
            },
          },
          '& .MuiTablePagination-root': {
            margin: 0,
            paddingTop: 0.5,
            paddingBottom: 0.5,
            color: colorConfigs.text,
          },
          '& .MuiTablePagination-selectLabel': {
            color: colorConfigs.text,
          },
          '& .MuiTablePagination-displayedRows': {
            color: colorConfigs.text,
          },
          '& .MuiTablePagination-actions': {
            color: colorConfigs.text,
          },
          '& .MuiInputAdornment-root .MuiSvgIcon-root': {
            color: colorConfigs.text,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          position: 'sticky',
          top: 0,
          zIndex: 1,
          overflowY: 'hidden',
          color: '#fff',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          border: `1px solid rgba(255, 255, 255, 0.1)`,
          backgroundColor: colorConfigs.tables.headBg,
          color: '#fff',
          paddingBottom: 0.2,
          paddingTop: 0.2,
          '& .MuiIconButton-root': {
            color: '#fff',
          },
          '& .MuiSvgIcon-root': {
            color: '#fff',
            padding: 0,
            margin: 0,
          },
          '& .MuiTableSortLabel-root': {
            color: '#fff',
            margin: 0,
            marginLeft: 1,
            marginRight: -0.5,
            '&.Mui-active': {
              color: '#fff',
            },
            '& .MuiTableSortLabel-icon': {
              color: '#fff !important',
              marginLeft: 'auto',
            },
          },
          '& .MuiTableSortLabel-iconDirectionAsc': {
            color: '#fff !important',
            p:0,
            m:0,
            ml:3
          },
          '& .MuiTableSortLabel-iconDirectionDesc': {
            color: '#fff !important',
            p:0,
            m:0,
            ml:3
          },
        },
        body: {
          borderBottom: `1px solid rgba(0, 0, 0, 0.05)`,
          // borderLeft: `1px solid rgba(0, 0, 0, 0.05)`,
        }
      },
    },
    // Add Filter Text Field Styles
    MuiFilterTextField: {
      styleOverrides: {
        root: {
          padding: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          color: '#fff',
          '& .MuiIconButton-root': {
            color: 'rgba(255, 255, 255, 0.5)',
          },
          '& .MuiInputBase-input': {
            color: '#fff',
            p: 0.5,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'none',
          },
          '& .MuiInput-underline:before': {
            borderColor: 'none',
          },
          '&:hover .MuiInput-underline:before': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
          },
          '& .MuiInput-underline:after': {
            borderColor: 'rgba(255, 255, 255, 0.5)',
          },
        },
      },
    },
    // Add Table Body Row Styles
    MuiTableBodyRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          paddingBottom: 0,
          paddingTop: 0,
          backgroundColor: (row) =>
            row.index % 2 === 0
              ? colorConfigs.tables.firstRow
              : colorConfigs.tables.secondRow,
          '&:nth-of-type(odd) .MuiTableCell-root': {
            borderLeft: `1px solid rgba(0, 0, 0, 0.03)`, // Border for odd rows
          },
          '&:nth-of-type(even) .MuiTableCell-root': {
            borderLeft: `1px solid rgba(255, 255, 255, 0.8)`, // Border for even rows
          },
              
        },

      },
    },
    // Add Table Body Styles
    MuiTableBody: {
      styleOverrides: {
        root: {
          maxHeight: '100%',
          overflowY: 'auto',
        },
      },
    },
    // Add Pagination Toolbar Styles
    MuiPaginationToolbar: {
      styleOverrides: {
        root: {
          padding: 0,  // Remove padding
          margin: 0,   // Remove margin
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        placement:"top",
      },
      styleOverrides: {
        tooltip: {
          // backgroundColor: colorConfigs.tables.headBg,
          backgroundColor: 'rgba(122,21,14, 0.9)',
          // border: `2px solid rgba(255, 255, 255, 0.5)`,
          border: `4px solid rgba(122,21,14, 0.2)`,
          // color: colorConfigs.text,
          color: '#fff',
          padding: '8px 12px',
          fontSize: '13px',
          fontWeight: 'bold'
        },
        arrow: {
          color: colorConfigs.tables.headBg, // Match arrow color with background
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
