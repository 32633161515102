import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Typography,
  TextField,
  MenuItem,
  LinearProgress,
  Switch,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { Field, FormikProvider, useFormik } from "formik";
import { FormSwitch } from "../../../components/common/fields/ProfileFeilds";
import * as Yup from "yup";
import { TextEditField, TextEditReadOnlyField } from "../fields/fields";
import {
  ImageItem,
  Method,
  statusLabels,
  Strip,
  useHousing,
} from "../CustomHooks";
import SignInDialog from "../SignInDialog";
import ResultSelectorCard from "./ResultSelectorCard";
import TabbedStripsCard from "./TabbedStripsCard";
import NotificationSnackbarCard, {
  parseErrors,
} from "./NotificationSnackbarCard";
import StepperCard from "./StepperCard";
import ReaderConfiguration from "./ReaderConfiguration";
import MethodDescriptionCard from "./MethodDescriptionCard";
import colorConfigs from "../../../configs/colorConfigs";
import RareButton from "../buttons/RareButton";

interface MethodCardProps {
  methodId?: any | null;
  handleRefreshButton?: () => void;
  handleBackButton: () => void;
}

const MethodCard: React.FC<MethodCardProps> = ({
  methodId,
  handleRefreshButton,
  handleBackButton,
}) => {
  const nonCollectionModeSteps = [
    "Name Method",
    "Describe Method",
    "Select Housing",
    "Load Test Image",
    "Configure Strips",
    "Save",
  ];

  const collectionModeSteps = [
    "Name Method",
    "Describe Method",
    "Select Housing",
    "Save",
  ];
  const navigate = useNavigate();

  const [errorRecord, setErrorRecord] = useState<Record<string, string>>({});
  const [errorQueue, setErrorQueue] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const authData = useSelector((state: RootState) => state.auth);
  const { housing, housingError } = useHousing();
  const [selectedImage, setSelectedImage] = useState<ImageItem | null>(null);
  const [strips, setStrips] = useState<Strip[]>([]);
  const [stripsAsRecord, setStripsAsRecord] = useState<Record<number, Strip>>(
    {},
  );
  const [createNewVersion, setCreateNewVersion] = useState(false);
  const [steps, setSteps] = useState<string[]>(nonCollectionModeSteps);
  const create = !methodId;

  const updateStripState = useCallback(
    (strip: Strip) => {
      console.log("Saving strip state: ", strip.position, strip);
      setStripsAsRecord((prev) => ({ ...prev, [strip.position]: strip }));
    },
    [setStripsAsRecord],
  );

  const handleStripError = useCallback(
    (key: string, error?: string) => {
      console.log("Setting error: ", key, error);
      if (error) {
        setErrorRecord((prev) => ({ ...prev, [key]: error }));
      } else {
        setErrorRecord((prevState) => {
          const { [key]: _, ...newState } = prevState; // Exclude the attribute
          return newState;
        });
      }
    },
    [setErrorRecord],
  );

  const getStripsAsArray = (strips: Record<number, Strip>): Strip[] => {
    const orderedStrips = Object.values(strips).sort(
      (a, b) => a.position - b.position,
    );
    return orderedStrips;
  };

  const getStripsAsRecord = (strips: Strip[]): Record<number, Strip> => {
    return strips.reduce(
      (acc, strip) => {
        acc[strip.position] = strip;
        return acc;
      },
      {} as Record<number, Strip>,
    );
  };

  useEffect(() => {
    const fetchMethodData = async () => {
      if (!authData.isAuthenticated || housing.length === 0 || !methodId) {
        return;
      }

      try {
        const response = await api.get(`methods/${methodId}`, {
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });

        const data: Method = response.data;
        formik.setFieldValue("id", methodId);
        formik.setFieldValue("housing", data.housing);
        formik.setFieldValue("test_name", data.test_name);
        formik.setFieldValue("description", data.description || "");
        formik.setFieldValue("version", data.version);
        formik.setFieldValue("status", statusLabels[data.status || ""]);
        formik.setFieldValue("flash_required", data.flash_required);
        formik.setFieldValue("collection_mode", data.collection_mode);
        formik.setFieldValue("initial_h_factor", data.initial_h_factor);
        formik.setFieldValue(
          "control_line_correction",
          data.control_line_correction,
        );
        formik.setFieldValue("profile_alignment", data.profile_alignment);
        formik.setFieldValue("control_exposure", data.control_exposure);
        formik.setFieldValue(
          "profile_control_levels",
          data.profile_control_levels
            ? JSON.stringify(data.profile_control_levels)
            : "",
        );
        formik.setFieldValue(
          "polynomial_baseline_order",
          data.polynomial_baseline_order,
        );
        formik.setFieldValue("frames_to_capture", data.frames_to_capture);
        formik.setFieldValue("control_baseline", data.control_baseline);
        formik.setFieldValue(
          "control_baseline_threshold",
          data.control_baseline_threshold,
        );
        setSelectedImage(data.strip_image);
        setStrips(data.strips);
        setStripsAsRecord(getStripsAsRecord(data.strips));
      } catch (fetchError: any) {
        console.error("Error fetching method", fetchError);
        if (fetchError.response !== "Request cancelled... refreshing token") {
          setErrorQueue([`No Method found with ID ${methodId}`]);
          setTimeout(() => {
            handleBackButton();
          }, 4000);
        }
      }
    };

    fetchMethodData();
  }, [housing, authData.isAuthenticated, methodId]);

  const validationSchema = () => {
    return Yup.object().shape({
      test_name: Yup.string().required("This field is required"),
      description: Yup.string().required("This field is required"),
      housing: Yup.string().required("This field is required"),
      profile_control_levels: Yup.string()
        .test(
          "is-valid-integer-array",
          "Profile Control Levels must be a list of integers, e.g., [2, 3]",
          (value) => {
            if (!value) return true; // Optional field
            try {
              const parsed = JSON.parse(value);
              return (
                Array.isArray(parsed) &&
                parsed.length === 2 &&
                parsed.every((num) => Number.isInteger(num))
              );
            } catch {
              return false;
            }
          },
        )
        .optional(),
    });
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      id: "",
      housing: "",
      test_name: "",
      description: "",
      version: 1,
      status: statusLabels["IN_PROGRESS"],
      flash_required: true,
      collection_mode: false,
      initial_h_factor: 1.5,
      control_line_correction: false,
      profile_alignment: true,
      control_exposure: true,
      minimumProfileControlLevel: 100,
      maximumProfileControlLevel: 250,
      polynomial_baseline_order: 3,
      frames_to_capture: 10,
      control_baseline: true,
      control_baseline_threshold: 1.5, // optional
    },
    validationSchema,
    onSubmit: async (values) => {
      if (Object.keys(errorRecord).length > 0) {
        console.log("Error count:", Object.keys(errorRecord).length);
        const errors = Object.values(errorRecord);
        setErrorQueue(errors);
        return;
      }

      const stripsData = getStripsAsArray(stripsAsRecord);
      let data: Method = {
        housing: values.housing,
        test_name: values.test_name,
        description: values.description,
        flash_required: values.flash_required,
        collection_mode: values.collection_mode,
        frames_to_capture: values.frames_to_capture,
        strip_image: null,
        strips: [],
      };
      if (!values.collection_mode) {
        const collectionModeData = {
          initial_h_factor: values.initial_h_factor,
          control_line_correction: values.control_line_correction,
          profile_alignment: values.profile_alignment,
          control_exposure: values.control_exposure,
          profile_control_levels: values.control_exposure
            ? [
                values.minimumProfileControlLevel,
                values.maximumProfileControlLevel,
              ]
            : undefined,
          polynomial_baseline_order: values.polynomial_baseline_order,
          frames_to_capture: values.frames_to_capture,
          control_baseline: values.control_baseline,
          control_baseline_threshold: values.control_baseline
            ? values.control_baseline_threshold
            : undefined,
          strips: stripsData,
          strip_image: selectedImage,
        };
        data = { ...data, ...collectionModeData };
      }
      const update = methodId && values.status === statusLabels["IN_PROGRESS"];
      const url = update ? `methods/${methodId}/` : "methods/";
      const method = update ? "patch" : "post";

      console.log("SEND DATA", data);

      setErrorQueue([]);
      setSuccessMessage("");

      try {
        const result = await api({
          method,
          url,
          data,
          headers: {
            Authorization: `Bearer ${authData.access}`,
          },
        });
        if (handleRefreshButton) {
          handleRefreshButton();
        }
        if (handleBackButton) {
          handleBackButton();
        }
        setSuccessMessage(
          `Method successfully ${update ? "updated" : "created"}.`,
        );
        console.log(`Method successfully ${update ? "updated" : "created"}.`);
        navigate("/lf_configuration/methods");
      } catch (error: any) {
        if (error?.response?.status === 400) {
          const errors = parseErrors(error.response.data);
          setErrorQueue(errors);
        } else {
          const errorText = `Unknown error ${
            update ? "updating" : "creating"
          } method`;
          setErrorQueue([errorText]);
          console.error(errorText, error.response);
        }
      }
    },
  });

  useEffect(() => {
    if (!methodId) {
      formik.setFieldValue(
        "frames_to_capture",
        formik.values.collection_mode ? 50 : 10,
      );
    }
    if (formik.values.collection_mode) {
      setErrorRecord({});
    }
  }, [formik.values.collection_mode, methodId]);

  useEffect(() => {
    console.log("selectedImage changed:", selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    console.log("strips changed:", stripsAsRecord);
  }, [stripsAsRecord]);

  useEffect(() => {
    if (formik.values.collection_mode) {
      setSteps(collectionModeSteps);
    } else {
      setSteps(nonCollectionModeSteps);
    }
  }, [formik.values.collection_mode]);

  // Determin if we are createing a new version
  useEffect(() => {
    if (!create && formik.values.status !== statusLabels["IN_PROGRESS"]) {
      setCreateNewVersion(true);
    }
  }, [formik.values.status, create]);

  // Function to determine the current active step
  const getActiveStep = () => {
    if (!formik.values.test_name) return 0;
    if (!formik.values.description) return 1;
    if (!formik.values.housing) return 2;
    if (!selectedImage) return 3;
    if (
      Object.keys(stripsAsRecord).length === 0 ||
      Object.values(errorRecord).length
    )
      return 4;
    return 5; // All previous steps are complete, move to Save step
  };

  const activeStep = getActiveStep();
  const isSaveStep = activeStep >= steps.length - 1;

  const handleImageSelected = (selectedImage: ImageItem | null) => {
    setSelectedImage(selectedImage);
    setStrips((prevStrips) =>
      prevStrips.map((strip) => ({
        ...strip,
        image_corners: [],
        strip_regions: [],
        profile: [],
      })),
    );
  };

  return authData.isAuthenticated == undefined ? (
    // isAuthenticated is undefined or null
    <Box data-testid="Box-er7z" sx={{ width: "100%" }}>
      <StepperCard steps={steps} activeStep={activeStep} title="" />
      <LinearProgress data-testid="LinearProgress-u4qy" sx={{ zIndex: 1001 }} />
    </Box>
  ) : authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={false} />
  ) : housing.length === 0 ? (
    // clinics not loaded yet
    <Box data-testid="Box-893t" sx={{ width: "100%" }}>
      <StepperCard steps={steps} activeStep={activeStep} title="" />
      <LinearProgress data-testid="LinearProgress-4c19" sx={{ zIndex: 1001 }} />
    </Box>
  ) : authData.isAuthenticated ? (
    <Box
      data-testid="Box-h73w"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <StepperCard steps={steps} activeStep={activeStep} title="" />
      <FormikProvider value={formik}>
        <form
          onSubmit={formik.handleSubmit}
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <MethodDescriptionCard
            formik={formik}
            housing={housing}
            housingError={housingError}
            create={create || !createNewVersion}
          />
          <div data-testid="div-0sdg" style={{ flexGrow: 0, width: "100%" }}>
            {/* <div data-testid="div-lcxn" style={{ flex: 1 }}> */}
            <div
              data-testid="div-98n1"
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                width: "100%",
              }}
            >
              <ReaderConfiguration formik={formik} />
              {!formik.values.collection_mode ? (
                <div
                  data-testid="div-g7k0"
                  style={{
                    flex: 5,
                  }}
                >
                  <Box
                    data-testid="Box-ef2c"
                    sx={{
                      p: 2,
                      border: "1px solid",
                      borderColor: "grey.300",
                      borderRadius: 2,
                      mr: 1, // margin-right
                      mb: 3, // margin-bottom
                      boxShadow: 3,
                      display: "flex", // Enable flexbox
                      flexDirection: "column", // Arrange items in a row
                      gap: "1rem", // Space between items
                    }}
                  >
                    <Box
                      data-testid="Box-8zyv"
                      sx={{
                        // p: 2,
                        // boxShadow: 3,
                        display: "flex", // Enable flexbox
                        flexDirection: "row", // Arrange items in a row
                        // gap: "1rem", // Space between items
                      }}
                    >
                      {/* Assay Configuration */}
                      <Box data-testid="Box-nafy" sx={{ p: 2, flex: 1 }}>
                        <Typography
                          data-testid="Typography-vf2c"
                          variant="h6"
                          gutterBottom
                          sx={{
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                        >
                          Assay Configuration
                        </Typography>
                      </Box>
                      {/* ResultSelectorCard */}
                      <Box data-testid="Box-3aqf" sx={{ flex: 2 }}>
                        <ResultSelectorCard onAccept={handleImageSelected} />
                      </Box>
                    </Box>
                    <TabbedStripsCard
                      image={selectedImage || undefined}
                      onStateChange={updateStripState}
                      onError={handleStripError}
                      strips={strips}
                      controlLineCorrection={
                        formik.values.control_line_correction
                      }
                    />
                  </Box>
                </div>
              ) : (
                <FormButtons
                  methodId={methodId}
                  methodStatus={formik.values.status}
                  status={formik.values.status}
                  create={create}
                  version={formik.values.version}
                  // changeVersion={changeVersion}
                  createNewVersion={createNewVersion}
                  isSaveStep={isSaveStep}
                />
              )}
            </div>
          </div>
          {/* Keep the button at the bottom */}
          {!formik.values.collection_mode && (
            <FormButtons
              methodId={methodId}
              methodStatus={formik.values.status}
              status={formik.values.status}
              create={create}
              version={formik.values.version}
              // changeVersion={changeVersion}
              createNewVersion={createNewVersion}
              isSaveStep={isSaveStep}
            />
          )}{" "}
          {/* Adjusted width */}
        </form>
      </FormikProvider>
      {/* </Card> */}
      <NotificationSnackbarCard
        errorQueue={errorQueue}
        successMessage={successMessage}
        setErrorQueue={setErrorQueue}
        setSuccessMessage={setSuccessMessage}
      />
    </Box>
  ) : null;
};

interface FormButtonsProps {
  methodId?: any | null;
  // handleChangeStatus: () => void;
  methodStatus: string;
  status: string;
  create: boolean;
  version: number;
  createNewVersion: boolean;
  // changeVersion: () => void;
  isSaveStep: boolean;
}

const FormButtons: React.FC<FormButtonsProps> = ({
  methodId,
  // handleChangeStatus,
  status,
  create,
  version,
  createNewVersion,
  // changeVersion,
  isSaveStep,
}) => {
  return (
    <Box
      data-testid="Box-i9e5"
      sx={{
        display: "flex", // Default to horizontal alignment
        flexDirection: "row", // Ensure buttons are in a row
        justifyContent: "flex-end", // Align buttons to the right
        alignItems: "flex-start", // Align buttons to the top
        width: "100%",
        paddingRight: "20px",
        paddingBottom: "20px",
      }}
    >
      <Tooltip
        data-testid="Tooltip-00zc"
        title={!isSaveStep ? "Complete all steps to Save" : ""}
      >
        <RareButton data-testid="RareButton-pv6n"
          type="submit"
          variant="success"
          disabled={!isSaveStep}
          sx={{
            m: 1, // Spacing between buttons
          }}
        >
          {createNewVersion ? `Save as New Version` : "Save Changes"}
        </RareButton>
      </Tooltip>
    </Box>
  );
};

export default MethodCard;
