import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle  } from 'react'
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import SignInDialog from '../../../components/common/SignInDialog';
import api from '../../../redux/features/auth/axiosAuthHelper';
import { logout, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { ResultSessionStorageKey, ResultTableParameterStorageService } from '../../../services/sessionStorage';
import ResultDetailCard from '../../../components/common/cards/ResultDetailCard';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from '../../../configs/colorConfigs';
import DownloadProgressBar from '../../../components/common/cards/DownloadProgressBar';
import sizeConfigs from '../../../configs/sizeConfigs';
import fontConfigs from '../../../configs/fontConfigs';
import ResultDetailDCMCard from '../../../components/common/cards/ResultDetailDCMCard';
import ResultsTableForDatasets from '../tables/ResultsTableForDatasets';
import ResultDynamicFilterBar from '../filterbars/ResultDynamicFilterBar';

interface DataSetResultsCardProps {
    handleSelectedResultsChange: (result_ids: any[]) => void;
    datasetResults: any[];
    create: boolean;
}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const DataSetResultsCard = forwardRef<
  { clearSelectedResults: () => void }, // Type for the exposed methods
  DataSetResultsCardProps // Props type
>(({ handleSelectedResultsChange, datasetResults, create }, ref) => {

const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [result, setResult] = useState<any>(null);
const [popup, setPopup] = useState<any>("");
const [totalCount, setTotalCount] = useState<any>(0); 
const [totalCountLoaded, setTotalCountLoaded] = useState<any>(0);
const [uniqueHousingError, setUniqueHousingError] = useState<any>("");
const [noResultsForQuery, setNoResultsForQuery] = useState<any>(false); 

const [sorting, setSorting] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.columnVisibility) || authData.isAdmin ? { selected: false} : {organisation: false, selected: false}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.pagination) || { pageSize: 250, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.showSearch) || false
});

const [rows, setRows] = useState<any>([]);

const [selectedResults, setSelectedResults] =  useState<any>([]);


const [queryString, setQueryString] = useState<any>(() => {
  return ResultTableParameterStorageService.get(ResultSessionStorageKey.currentQueryString) || ""
});

const handleRefreshButton = () => {
  setResult("")
  fetchData(queryString)
  setPopup("")
}

const handleBackButton = () => {
  setResult("")
  setPopup("")
}

const handleShowAllSelectedResults = () => {
  setRows(selectedResults)
}

useEffect(() => {
  if (selectedResults.length) {
        setColumnVisibility((prevVisibility: any) => ({
          ...prevVisibility,
          selected: true,
        }));
  } else {
        setColumnVisibility((prevVisibility: any) => ({
          ...prevVisibility,
          selected: false,
        }));
  }
}, [selectedResults])

const handleAddSelectedResults = (rowsToAdd: any[]) => {
    // Validate that all rows have the same housing_id
    setUniqueHousingError("");
    const allHousingIds = [
        ...rowsToAdd.map((row) => row.housing_id),
        ...selectedResults.map((row: any) => row.housing_id),
    ];

    if (new Set(allHousingIds).size > 1) {
        // Set an error if housing IDs are not the same
        setUniqueHousingError("All Results in a dataset must share a common housing");
        setTimeout(() => setUniqueHousingError(""), 3000);
        return; // Exit early without updating results
    }

    const allCollectionModes = [
      ...rowsToAdd.map((row) => row.test_configuration.collection_mode),
      ...selectedResults.map((row: any) => row.test_configuration.collection_mode),
  ];

  if (new Set(allCollectionModes).size > 1) {
      // Set an error if housing IDs are not the same
      setUniqueHousingError("All Results in a dataset must share a common source, Normal App result or Data Collection Mode");
      setTimeout(() => setUniqueHousingError(""), 3000);
      return; // Exit early without updating results
  }

    setSelectedResults((prevSelectedResults: any[]) => {
      // Add rows to selectedResults, avoiding duplicates
      const updatedResults = [
        ...prevSelectedResults,
        ...rowsToAdd.filter(
          (newRow) => !prevSelectedResults.some((result) => result.id === newRow.id)
        ).map((row) => ({ ...row, selected: true })), // Set selected = true for added rows
      ];
  
      // Call handleSelectedResultsChange with the updated list of IDs
      handleSelectedResultsChange(updatedResults.map((result) => result.id));
      return updatedResults;
    });
  
    // Update rows to set selected = true
    setRows((prevRows: any[]) =>
      prevRows.map((row) =>
        rowsToAdd.some((toAdd) => toAdd.id === row.id)
          ? { ...row, selected: true }
          : row
      )
    );
  };
  
  
  const handleRemoveSelectedResults = (rowsToRemove: any[]) => {
    setSelectedResults((prevSelectedResults: any[]) => {
      // Remove rows from selectedResults based on their IDs
      const updatedResults = prevSelectedResults.filter(
        (result) => !rowsToRemove.some((toRemove) => toRemove.id === result.id)
      );
  
      // Call handleSelectedResultsChange with the updated list of IDs
      handleSelectedResultsChange(updatedResults.map((result) => result.id));
      return updatedResults;
    });
  
    // Update rows to set selected = false
    setRows((prevRows: any[]) =>
      prevRows.map((row) =>
        rowsToRemove.some((toRemove) => toRemove.id === row.id)
          ? { ...row, selected: false }
          : row
      )
    );
  };

  const handleClearAllSelectedResults = () => {
    setRows((prevRows: any[]) =>
        prevRows.map((row) =>
          selectedResults.some((selectedResult: any) => selectedResult.id === row.id)
            ? { ...row, selected: false }
            : row
        )
      );
    setSelectedResults([])
  }
  
  
    // Expose the method to the parent using useImperativeHandle
    useImperativeHandle(ref, () => ({
        clearSelectedResults: handleClearAllSelectedResults,
    }));


const fetchData = async (query = "") => {

  setIsLoading(true);
  const data: any[] = [];
  let downloadPageSize = 250;  // Set the batch size to 250
  let downloadOffset = 0;
  let total = 0;

  try {
      // Extract the limit from the query if it exists
      const urlParams = new URLSearchParams(query);
      const limitParam = urlParams.get('limit');
      
      // If the limit parameter exists and is a valid number, set the downloadPageSize
      if (limitParam && !isNaN(Number(limitParam))) {
          downloadPageSize = Number(limitParam);  
      }

      let url = `results/?${query}&skip=${downloadOffset}&datarun_id=null`;
      if (!urlParams.has('limit')) {
          url += `&limit=${downloadPageSize}`;
      }
      console.log(url)
      // Fetch the first batch to get the total count
      const initialResponse = await api.get(url, {
          headers: {
              'Authorization': `Bearer ${authData.access}`
          }
      });
      if (initialResponse.data.results.length < 1) {
        console.log("No results for this query")
        setIsLoading(false);
        setNoResultsForQuery(true)
        setRows([]);
        return data
      } else {
        // Add fetched results to the data array
        data.push(
            ...initialResponse.data.results.map((result: any) => ({
            ...result,
            selected: selectedResults.some((selected: any) => selected.id === result.id), // Add selected flag
            }))
        );

        if (limitParam && !isNaN(Number(limitParam))) {
          total = initialResponse.data.results.length
        } else {
          total = initialResponse.data.count;
        }
        downloadOffset += downloadPageSize;
        setTotalCount(total);
        setTotalCountLoaded(data.length);
        setIsLoading(false);
        setRows([...data]);
        setQueryString(query)
      }
      // Now fetch the remaining lots in the background
      while (data.length < total && !limitParam) {
          const url = query ?  `results/?${query}&limit=${downloadPageSize}&skip=${downloadOffset}&datarun_id=null` : `results/?limit=${downloadPageSize}&skip=${downloadOffset}&datarun_id=null`

          const response = await api.get(url, {
              headers: {
                  'Authorization': `Bearer ${authData.access}`
              }
          });
          data.push(
            ...response.data.results.map((result: any) => ({
            ...result,
            selected: selectedResults.some((selected: any) => selected.id === result.id), // Add selected flag
            }))
        );
          downloadOffset += downloadPageSize;
          setTotalCountLoaded(data.length);
          console.log(`Downloaded ${data.length} of ${total} results`);
      }

      // Final update after all lots are fetched
      setRows([...data]);
      console.log('Downloaded all results:', data);

      setTimeout(() => { 
        setTotalCount(false)
      }, 6000);

  } catch (error) {
      console.error('Error fetching results:', error);
  } finally {
    setIsLoading(false);
    return data
  };
}

useEffect(() => { 
  if (!create) {
    setRows(datasetResults);
    setIsLoading(false);
  } else if ((rows.length < 1 || !rows.length)) {
    // No data in storage and no rows loaded, show loading and fetch data
    fetchData("limit=10");
  } else if (rows.length > 0) {
    // Rows are loaded, stop loading
    setIsLoading(false);
  }
}, [rows.length, authData.isAuthenticated, datasetResults]);


const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 250, pageIndex: 0 })
  setSorting([])
}

  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated && authData.isFreeUser ? (
    <Navigate to="/account/subscription"/>
    ) : authData.isAuthenticated === true && !authData.isFreeUser ? (
      <div data-testid="div-87zy">

      <div data-testid="div-vl0s">

        <Grid
          container
          spacing={0}
          direction="column"
        //   alignItems="center"
        //   justifyContent="center"
          sx={{m:0}}
        >
          <Grid item xs={3} sx={{m:0, p:0}}>
            { create ? (
            <Box data-testid="Box-9nnk" sx={{display: 'flex', alignItems: 'center', maxWidth: `88vw`, minWidth: `88vw`}}>
               <Typography data-testid="Typography-4kd6" sx={{ fontSize: 18, fontWeight: 'bold', minWidth: '10em' }}>Select Results Data</Typography>
               <Typography data-testid="Typography-exho" sx={{ fontSize: 14, minWidth: '25em' }}>(All Results in a dataset must share a common housing)</Typography>
               <DownloadProgressBar totalCount={totalCount} totalCountLoaded={totalCountLoaded}/>
            </Box>
            ) : (
            <Box data-testid="Box-rtwy" sx={{display: 'flex', alignItems: 'center', maxWidth: `88vw`, minWidth: `88vw`}}>
                <Typography data-testid="Typography-9si3" sx={{ fontSize: 18, fontWeight: 'bold', minWidth: '6em' }}>Data</Typography>
             </Box>
            )}
            <Box data-testid="Box-uohn" sx={{ m:0, p:1, pl: 1.5, height: "100%", overflowY: "auto", display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `89vw`, minWidth: `89vw` }}>
                <Box data-testid="Box-0w76" sx={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', boxShadow: 5, borderTopRightRadius: 3, borderTopLeftRadius: 3 }}>
                <Box data-testid="Box-e9wi" sx={{
                    borderRadius: 1,
                    height: 'calc(100vh - 120px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                {create 
                && 
                <ResultDynamicFilterBar 
                        setIsLoading={setIsLoading} 
                        handleRemoveAllFilters={handleRemoveAllFilters} 
                        fetchData={fetchData}
                        totalCount={totalCount} 
                        totalCountLoaded={totalCountLoaded}
                        />                
                }
                <Box data-testid="Box-sl1q" sx={{
                    flex: 1,
                    // maxHeight: 'calc(100vh - 295px)',
                    minHeight: 'calc(100vh - 295px)',
                    display: 'flex',
                    flexDirection: 'column',
                    borderTopRightRadius: 50,
                    borderTopLeftRadius: 0,
                    border: 'none',
                    boxShadow: 0,
                    
                }}>

                 <ResultsTableForDatasets
                    rows={rows}
                    isLoading={isLoading}
                    sorting={sorting}
                    columnVisibility={columnVisibility}
                    columnFilters={columnFilters}
                    pagination={pagination}
                    globalFilter={globalFilter}
                    showGlobalFilter= {showGlobalFilter}
                    setShowGlobalFilter={setShowGlobalFilter}
                    handleRemoveAllFilters={handleRemoveAllFilters}
                    setGlobalFilter={setGlobalFilter}
                    setSorting={setSorting}
                    setColumnVisibility={setColumnVisibility}
                    setColumnFilters={setColumnFilters}
                    setPagination={setPagination}
                    setPopup={setPopup}
                    setResult={setResult}
                    handleRefreshButton={handleRefreshButton}
                    authData={authData}
                    handleAddSelectedResults={handleAddSelectedResults}
                    handleRemoveSelectedResults={handleRemoveSelectedResults}
                    handleShowAllSelectedResults={handleShowAllSelectedResults}
                    selectedResults={selectedResults}
                    setRows={setRows}
                    setUniqueHousingError={setUniqueHousingError}
                    handleClearAllSelectedResults={handleClearAllSelectedResults}
                    create={create}
                  />
                  </Box>
                  </Box>
                </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog data-testid="Dialog-fmcq"
            open={(result && popup === "Edit") || popup === "Create"}
            fullScreen
            onClose={() => {setResult(""); setPopup("")}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
                height: '100vh',
                minHeight: '100vh',
              '& .MuiDialog-paper': {
                alignItems: 'center',
                justifyContent: 'center',
                p:0,
                height: '100vh',
                minHeight: '100vh'
              },
            }}
          >
            <AppBar
              onClick={() => { setResult(""); setPopup(""); }}
              sx={{
                position: 'fixed',
                top: 0,
                height: sizeConfigs.detailPage.appbar.height,
                minHeight: sizeConfigs.detailPage.appbar.height,
                maxHeight: sizeConfigs.detailPage.appbar.height,
                zIndex: 1201,
              }}
            >             
             <Toolbar
                sx={{
                  color: '#fff', 
                  height: sizeConfigs.detailPage.appbar.height,
                  minHeight: sizeConfigs.detailPage.appbar.height,
                  maxHeight: sizeConfigs.detailPage.appbar.height,
                  padding: 0,
                  backgroundColor: colorConfigs.tables.headBg,
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                }}
              >
                {(popup  && result) && (
                  <Box data-testid="Box-30hw" onClick={(e) => e.stopPropagation()} sx={{ m: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
                  <Typography data-testid="Typography-zpso" sx={{ fontWeight: 'bold', ml: 2, fontSize: fontConfigs.detailPage.appbar.label, padding: "0 8px" }}>
                    Sample ID
                  </Typography>
                  <Typography data-testid="Typography-vam8" sx={{ fontSize: fontConfigs.detailPage.appbar.title, fontWeight: "bold", padding: "0 8px" }}>
                    {result.sample_id}
                  </Typography>
                  </Box>
                )}
                 <IconButton data-testid="IconButton-uwex"
                  edge="start"
                  color="inherit"
                  onClick={() => { setResult(""); setPopup(""); }}
                  aria-label="close"
                  sx={{
                    position: 'absolute',
                    right: 8,
                    padding: 0,
                    fontSize: '18px',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>


            <DialogContent data-testid="DialogContent-g9du"
                  sx={{
                    height: sizeConfigs.detailPage.dialogContent.height,
                    minHeight: sizeConfigs.detailPage.dialogContent.height,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none', // Internet Explorer 10+
                    scrollbarWidth: 'none', // Firefox
                    p: 0,
                    m: 0,
                    minWidth: '100vw',
                    backgroundColor: colorConfigs.sidebar.bg,
                    mt: sizeConfigs.detailPage.appbar.height,
                  }}
                >                
                {(popup && result && result?.test_configuration.collection_mode) ? 
                <ResultDetailDCMCard result_id={result.id} popup={popup} handleBackButton={handleBackButton} />
                : (popup && result && !result?.test_configuration.collection_mode) ?
                <ResultDetailCard result_id={result.id} popup={popup} handleBackButton={handleBackButton} />
                : null
                }
            </DialogContent>
        </Dialog>
    </div>
) : (null);
});

export default DataSetResultsCard;

