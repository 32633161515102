import { Chip, Box, Button, FormControlLabel, Collapse, Tabs, Tab, MenuItem, TextField, Checkbox, Alert, AlertTitle, IconButton, Typography, Card, InputLabel, Divider, InputAdornment, Dialog, DialogActions, DialogContent, DialogTitle, Slide, AppBar, Toolbar, Tooltip, LinearProgress} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import * as Yup from "yup";
import { Field, FormikProvider, useFormik } from 'formik';
import api from '../../../redux/features/auth/axiosAuthHelper';
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { ResultSessionStorageKey, ResultTableParameterStorageService } from '../../../services/sessionStorage';
import colorConfigs from '../../../configs/colorConfigs';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { FBDatePicker, FBIDEditField, FBTextEditField, FormFBLimitSelectField, FormFBSavedSearchSelectField, FormFBSelectField } from '../fields/filterBarFeilds';
import RareChip from '../buttons/RareChip';
import FBLimitChip from '../buttons/FBLimitChip';
import SearchIcon from "@mui/icons-material/Search";
import { useRareHousings, useRareMethods, useRareOrganisations, useRareUsers } from '../CustomHooks';
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import sizeConfigs from '../../../configs/sizeConfigs';
import fontConfigs from '../../../configs/fontConfigs';
import RareButton from '../buttons/RareButton';
import { TextEditField } from '../fields/fields';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';

dayjs.extend(utc);
dayjs.extend(timezone);
const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
dayjs.tz.setDefault(browserTimezone);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SectionConfig {
  key: string;
  label: string;
  // The field definitions will be spread into the Field component.
  fields: Array<{
    name: string;
    label: string;
    component: any;
    // Optional additional props
    selectTitle?: string;
    options?: any;
  }>;
  // List of Formik field keys to check – if any is set, the section remains open.
  formikFields: string[];
}

// SETUP
// 1 Change name of Component
// 2 Change single and plural Names and set userCanSaveFilter and userFiltersName
const singleName = "Feedback"  
const pluralName = "Feedback"
const userCanSaveFilter = false // If you cannot save a custom filter for these objects on the user set to false
const userFiltersName = "result_filters" // If there is not filter field on the User for these objects leave empty string
// 3 Change all instances of session storage keys
// 4 Updated Advance sections 
// {
//   key: 'sample', --unique identifier
//   label: 'Sample', --name of section / group name
//   fields: [
//     TEXT
//     {                   -- each input in the group 1 or many
//       name: 'sample_id',  -- property_name on the object that will be the same as the formik value
//       label: 'Sample ID',    -- The english label for this property
//       component: FBTextEditField -- The coponent to use for each input 
//                                     TEXT: FBTextEditField, 
//                                     SELECT: FormFBSelectField (you will need to add options) (Also used for boolean fields options=BooleanOptions)
//                                     DATE: FBDatePicker
//                                     
//     }, 
//     SELECT Custom (orgs, users)
//     { name: 'organisation_id', label: 'Select Organisation', component: FormFBSelectField, selectTitle: 'Select Organisation', options: organisationOptions },
//     DATE PICKERS
//     { name: 'timestamp_from_date', label: 'From', component: FBDatePicker }, -- For Dates use the property name followed but to_date / from_date for each date feild
//     { name: 'timestamp_to_date', label: 'To', component: FBDatePicker },
//     BOOLEAN SELECT  (is_active or status)
//     { name: 'is_active', label: 'Is Active?', component: FormFBSelectField, selectTitle: 'Select Yes/No', options: booleanOptions, },
//     { name: 'status', label: 'Status', component: FormFBSelectField, selectTitle: 'Select Status', options: statusOptions, }, 
//   ],
//   formikFields: ['sample_id', 'organisation_id', 'timestamp_from_date', 'timestamp_to_date', 'is_active', 'status'],  -- All formik fields in this group must be listed here
// },

interface FeedbackDynamicFilterBarProps {
  setIsLoading: (data: boolean) => void;
  handleRemoveAllFilters: () => void;
  fetchData: (query?: any) => any;
  totalCountLoaded: number;
  totalCount: number;
  }


const FeedbackDynamicFilterBar: React.FC<FeedbackDynamicFilterBarProps> = ({setIsLoading, handleRemoveAllFilters, fetchData, totalCountLoaded, totalCount}) => {

    const authData = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch<AppDispatch>();
    const errorRef = useRef<HTMLDivElement>(null);
    const searchRef = useRef<HTMLDivElement>(null);
    const [expanded, setExpanded] = useState<string | false>(false); 
    const [filterCriteriaOptions, setFilterCriteriaOptions] = useState<any[]>([]);
    const [searchMode, setSearchMode] = useState<"quick" | "advanced" | "update_filter">("advanced");

    // State to track errors 
    const [errors, setErrors] = useState<string | null>(null);
    useEffect(() => {
      if (errors && errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  }, [errors]);

    // State and Hooks for Any filter Drop Downs

    const booleanOptions = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];

    const statusOptions = [
      { label: "Active", value: "ACTIVE" },
      { label: "Inactive", value: "INACTIVE" },
      { label: "In Progress", value: "IN_PROGRESS" },
    ];

    const organisations = useRareOrganisations()
    const [organisationOptions, setOrganisationOptions] = useState<any[]>([]);
    const users = useRareUsers();
    const [userOptions, setUserOptions] = useState<any[]>([]);
    const housings = useRareHousings();
    const [housingOptions, setHousingOptions] = useState<any[]>([]);
    const methods = useRareMethods();
    const [methodOptions, setMethodOptions] = useState<any[]>([]);
    useEffect(() => {
      if (organisations.length) {
        setOrganisationOptions(
          organisations.map((org) => ({
            label: org.name,
            value: org.id.toString(),
          }))
        );
      } else {
        setOrganisationOptions([]);
      }
    }, [organisations]);

    useEffect(() => {
      if (users.length) {
        setUserOptions(
          users.map((user: any) => ({
            label: user.name,
            value: user.id.toString(),
          }))
        );
      } else {
        setUserOptions([]);
      }
    }, [users]);

    useEffect(() => {
      if (housings.length) {
        setHousingOptions(
          housings.map((housing) => ({
            label: housing.name,
            value: housing.id.toString(),
          }))
        );
      } else {
        setHousingOptions([]);
      }
    }, [housings]);

    useEffect(() => {
      if (methods.length) {
        setMethodOptions(
          methods.map((method) => ({
            label: method.name,
            value: method.id.toString(),
          }))
        );
      } else {
        setMethodOptions([]);
      }
    }, [methods]);

    // State to track download progress
    const progress = totalCount > 0 ? (totalCountLoaded / totalCount) * 100 : 0;
    const [showDownloadProgress, setShowDownloadProgress] = useState(false);
    
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowDownloadProgress(true);
      }, 1000);
      return () => clearTimeout(timer);
    }, [totalCountLoaded, totalCount]);


    // State and Handling saving and retrieving filters to User filters 
    const [saveCustomFilter, setSaveCustomFilter] = useState<boolean>(false);
    const [savedFilters, setSavedFilters] = useState<any[]>([]);
    const [savedFilterOptions, setSavedFilterOptions] = useState<any[]>([]);

    useEffect(() => {
      if (userFiltersName && userCanSaveFilter) {
        const filtersFromProfile = (authData?.user?.profile?.[userFiltersName] as unknown as any[]) || [];
        if (filtersFromProfile.length) {
          setSavedFilters(filtersFromProfile);
          setSavedFilterOptions(
            filtersFromProfile.map((filter: any) => ({
              value: filter.name,
              label: filter.name,
            }))
          );
        } else {
          setSavedFilterOptions([]);
        }
      }
    }, [authData]);


     // ADVANCED FILTER SECTIONS
     const advancedSearchSections: SectionConfig[] = [
      {
        key: 'feedback',
        label: 'Feedback',
        fields: [
          { name: 'reader_feedback', label: 'Sample ID', component: FBTextEditField },
          { name: 'test_feedback', label: 'Sample ID', component: FBTextEditField },
        ],
        formikFields: ['reader_feedback', 'test_feedback'],
      },
      {
        key: 'phone',
        label: 'Phone',
        fields: [
          { name: 'device_model', label: 'Device Model', component: FBTextEditField },
          { name: 'app_version', label: 'App Version', component: FBTextEditField },
        ],
        formikFields: ['device_model', 'app_version'],
      },
      {
        key: 'createdBy',
        label: 'Created By',
        fields: [
          { name: 'user_id', label: 'Select User', component: FormFBSelectField, selectTitle: 'Select User', options: userOptions },
          { name: 'user_id', label: 'User ID', component: FBTextEditField },
        ],
        formikFields: ['user_id'],
      },
      {
        key: 'organisation',
        label: 'Organisation',
        fields: [
          { name: 'organisation_id', label: 'Select Organisation', component: FormFBSelectField, selectTitle: 'Select Organisation', options: organisationOptions },
          { name: 'organisation_id', label: 'Organisation ID', component: FBTextEditField },
        ],
        formikFields: ['organisation_id'],
      },
      {
        key: 'timestamp',
        label: 'Timestamp',
        fields: [
          { name: 'timestamp_from_date', label: 'From', component: FBDatePicker },
          { name: 'timestamp_to_date', label: 'To', component: FBDatePicker },
        ],
        formikFields: ['timestamp_from_date', 'timestamp_to_date'],
      },
    ]

    const validationSchema = () => {
      return Yup.object().shape({
        timestamp_from_date: Yup.date()
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr)),
        timestamp_to_date: Yup.date()
          .nullable()
          .transform((curr, orig) => (orig === "" ? null : curr))
          .min(
            Yup.ref('timestamp_from_date'),
            'The "To" date must be later than the "From" date'
          ),        
        });
    }

    const loadInitialValues = (): { [key: string]: any } => {
      // Formik Initial Values
      const initialValues: { [key: string]: any } = {
        id: "",
        selectedFilter: "",
        new_filter: "",
        filter_to_delete: "",
        skip: "",
        limit: "",
      };
      
      if (userFiltersName) {
        initialValues[userFiltersName] = [];
      }

    // Add keys from each advanced search section if not already set, e.g. formikFields: ['timestamp_from_date', 'timestamp_to_date'],
    advancedSearchSections.forEach((section) => {
      section.formikFields.forEach((fieldName) => {
        if (!(fieldName in initialValues)) {
          initialValues[fieldName] = "";
        }
      });
    });
  
      // // Load stored options from session storage
      // const storedOptions = ResultTableParameterStorageService.get(ResultSessionStorageKey.filterCriteria) || [];
    
      // // Merge stored options into initial values if their values are not empty
      // storedOptions.forEach((option: any) => {
      //   if (option.value !== '' && option.name in initialValues) {
      //     initialValues[option.name as keyof typeof initialValues] = option.value;
      //   }
      // });
    
      return initialValues;
    };


    // Helper function to build filters dynamically for use when saving for fetching the applied filters.
    const buildFilters = (values: any) => {
      // ID Limit and Skip are constants
      const filters: any = {
        id: values.id,
        limit: values.limit,
        skip: values.skip,
      };

      // Loop over each advanced section and add each dynamic field
      advancedSearchSections.forEach((section) => {
        section.formikFields.forEach((key) => {
          // Only add a field if it has a value (you can adjust this logic as needed)
          if (values[key] !== undefined && values[key] !== "") {
            // If the key ends with _from_date or _to_date, format it using dayjs
            if (key.endsWith('_from_date') || key.endsWith('_to_date')) {
              filters[key] = dayjs(values[key]).format('YYYY-MM-DD');
            } else {
              filters[key] = values[key];
            }
          }
        });
      });

      return filters;
    };

    // Helper function to build the data object dynamically:
    const buildData = (values: any) => {
      // Start with constant keys that are always part of the query.
      const data: any = {
        id: values.id,
        limit: values.limit,
        skip: values.skip,
      };

      // Loop over each advanced section and add each field if a value exists.
      advancedSearchSections.forEach((section) => {
        section.formikFields.forEach((fieldName) => {
          if (values[fieldName] !== undefined && values[fieldName] !== "") {
            // Format date fields
            if (fieldName.endsWith("_from_date") || fieldName.endsWith("_to_date")) {
              data[fieldName] = dayjs(values[fieldName]).format("YYYY-MM-DD");
            } else {
              data[fieldName] = values[fieldName];
            }
          }
        });
      });

      return data;
    };

    const formik = useFormik({
      validateOnChange: false,
      validateOnBlur: false,
      initialValues: loadInitialValues(),
      validationSchema,
      onSubmit: async (values) => {
        setErrors(null);
        setIsLoading(true)
        
        // Check if the limit is greater than 1000
        if (Number(values.limit) > 1000) {
          setErrors("Limit can be a maximum of 1000");
          setIsLoading(false);
          return;
        }
         
        //  If we're in update_filter mode, use PATCH logic to update users profile filters.
        if(userCanSaveFilter){
        if (searchMode === "update_filter") {
          let updatedFilters: any[] = savedFilters || [];
    
          // If a new filter is being saved, prepend it.
            // If a new filter is being saved, build it dynamically
            if (values.new_filter) {
              const newFilter = {
                name: values.new_filter,
                filters: buildFilters(values),
              };
              updatedFilters = [newFilter, ...updatedFilters];

            // Optional: if new_filter equals a special value to delete all filters:
            if (values.new_filter === "delete_all_custom_filters") {
              updatedFilters = [];
            }
          }

          // If a filter is to be deleted, remove it from the array.
          if (values.filter_to_delete && savedFilters.length) {
            updatedFilters = savedFilters.filter(
              (filter: any) => filter.name !== values.filter_to_delete
            );
          }
          
          const data = { profile: { [userFiltersName]: updatedFilters } }; // userFilterName is set at the top e.g. "result_filters"
    
          try {
            await api.patch(`profile/`, data, {
              headers: {
                Authorization: `Bearer ${authData.access}`,
              },
            });
            handleRemoveAllFilters();
            setErrors(null);
            setIsLoading(false);
            if (searchRef.current) {
              searchRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
            }
            ResultTableParameterStorageService.set(ResultSessionStorageKey.filters, updatedFilters);
            dispatch(fetchUserProfile());
            formik.setFieldValue("new_filter", "");
            formik.setFieldValue("filter_to_delete", "");
          } catch (error: any) {
            setIsLoading(false);
            if (error?.response?.status === 400) {
              const firstErrorItem = Object.keys(error.response.data)[0];
              setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
            } else {
              const errorText = `Unknown error updated your filters`;
              setErrors(errorText);
              console.error(errorText, error);
            }
          }
          return; // End the onSubmit early for update_filter
        }
      }
    
        // Otherwise, in not in 'updated_filter' mode proceed with GET request logic for quick/advanced searches.
        if (searchMode === "advanced") {
          // For advanced search, clear quick search fields so they are not added to the query
          values.id = "";
          values.limit = "";
          formik.setFieldValue('id', "");
          formik.setFieldValue('limit', "");
        } else if (searchMode === "quick") {
          // For quick search, clear all fields except id (if set) or limit (if set)
          if (values.id) {
            Object.keys(values).forEach((key) => {
              if (key !== "id") {
                if (key === userFiltersName) {  // e.g. 'result_filters' 
                  (values as any)[key] = []; // Cast to any so TS accepts an array assignment
                } else {
                  (values as any)[key] = "";
                }
              }
            });
          } else if (values.limit) {
            Object.keys(values).forEach((key) => {
              if (key !== "limit") {
                if (key === userFiltersName) { // e.g. 'result_filters' 
                  (values as any)[key] = [];
                } else {
                  (values as any)[key] = "";
                }
              }
            });
          }
        }

        // Build the data object dynamically from Formik values.
        const data: { [key: string]: any } = buildData(values);
              
        
      
          let queryParams: URLSearchParams;

          if (data.id) {
            // If an id is provided, only use the id in the query string
            queryParams = new URLSearchParams({ id: data.id });
          } else if (data.limit) {
            // If a limit is provided (and no id), only use the limit in the query string
            queryParams = new URLSearchParams({ limit: data.limit });
          } else {
          queryParams = Object.entries(data).reduce((acc, [key, value]) => {
            // Include the field if it's not an empty string, undefined or null.
            if (value !== "" && value !== undefined && value !== null) {
              if (key !== "id" && key !== "limit") {
                acc.set(key, String(value));
              }
            }
            return acc;
          }, new URLSearchParams());
          }
                
        // Generate the query string by concatinating any fields that have been entered
        const queryString = queryParams.toString();
        
        console.log("queryString", queryString)
        try {
          const results = await fetchData(queryString);
          if (results.length < 1) {
            setErrors(`There are no ${pluralName} matching your search.`);
          } 
          // else if (searchMode !== "quick") {  // Closes the filterbar automatically
          //   setTimeout(() => {
          //     setExpanded(false);
          //   }, 1000);
          // }
        } catch (err) {
          // Handle error if needed.
        }
      },
    });
  
  // Sets a list of search criteria options that is used for the display Chips
  useEffect(() => {
    const options: any[] = generateFilterCriteriaOptions(formik.values);
    setFilterCriteriaOptions(options);
    ResultTableParameterStorageService.set(ResultSessionStorageKey.filterCriteria, options)
  }, [formik.values]);

  // Generates a list of search criteria options based
  const generateFilterCriteriaOptions = (formikValues: typeof formik.values): any[] => {
    const omitFromFilterChips = ['id', 'limit', 'skip', 'new_filter', 'from_date', 'filter_to_delete', 'to_date', 'criteria', 'selected_filter', 'display'];
    const filterCriteriaOptions: any[] = [];
    
    for (const key in formikValues) {
      // Skip keys that are in omitFromFilter or that end with '_display'
      if (omitFromFilterChips.includes(key) || key.endsWith('_display') || key.endsWith('_filters')) {
          continue;
      }

        const value = formikValues[key as keyof typeof formikValues];
        let label = key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());

        // Special case for 'organisation' field
        // if (key === 'timestamp') {
        //   label = 'Date';
        // }

        filterCriteriaOptions.push({
            name: key as keyof typeof formik.values,
            label,
            value,
        });
    }
    return filterCriteriaOptions;
};

  // HANDLERS

  // Handles setting new created custom filters
  const handleCustomFilter = (filter: any) => {
    console.log("filter", filter);
    formik.resetForm();
    // Always reset new_filter to an empty string
    formik.setFieldValue("new_filter", "");
  
    // Loop through all keys in the Formik initialValues (except "new_filter")
    Object.keys(formik.initialValues).forEach((key) => {
      if (key !== "new_filter") {
        formik.setFieldValue(key, filter.filters[key] || "");
      }
    });
  
    setSearchMode("advanced");
    formik.submitForm();
  };

    // Handles deleting a specific custom search/filter
    const handleDeleteCustomFilter = (filter: any) => {
      setSearchMode("update_filter");
      formik.setFieldValue("filter_to_delete", filter.value);
      formik.submitForm();
    };
  
    // Handles saving a custom search/filter
    const handleCustomFilterSave = () => {
      setSaveCustomFilter(false);
      setSearchMode("update_filter");
      // When saving a new custom filter, formik.values.new_filter is not empty.
      formik.submitForm();
    };

  // Handlers for Quick Filter Functions
  const handleChipDelete = (filterName:any) => {
    formik.setFieldValue(filterName, '');
    formik.setFieldValue(`${filterName}_display`, ''); // Clear the display value as well
  };
  
  // Handles the Clear All button in Advanced Secion
  const handleClearAll = () => {
    filterCriteriaOptions.forEach(option => {
        formik.setFieldValue(option.name, '');
        formik.setFieldValue(`${option.name}_display`, ''); // Clear all display values
    });
    setErrors("")
    formik.resetForm()
  };

  // Handles ID Quick Search – only preserve the id field
  const handleIDSearch = (id: any) => {
    setSearchMode("quick");
    formik.resetForm();
    formik.setFieldValue("id", id);
    formik.submitForm();
  };

  // Handles Limit Quick Search – only preserve the limit field
  const handleLimitButtons = (limit: any) => {
    if (formik.values.limit === limit) {
      formik.setFieldValue("limit", "");
    } else {
      formik.resetForm();
      setSearchMode("quick");
      formik.setFieldValue("limit", limit);
      formik.submitForm();
    }
  };

  //  Handles the Search Button in Advanced Search section
  const handleAdvancedSearch = () => {
    setSearchMode("advanced");
    formik.setFieldValue("limit", "");
    formik.setFieldValue("id", "");
    formik.submitForm();
  };

  // ADVANCED FILTER SECTIONS
  // Initialise each section as closed.
  const [sectionToggles, setSectionToggles] = useState<Record<string, boolean>>(
    advancedSearchSections.reduce((acc, section) => {
      acc[section.key] = false;
      return acc;
    }, {} as Record<string, boolean>)
  );

  // Generic toggle handler for each section.
  const handleToggle = (key: string, formikFieldKeys: string[]) => {
    const shouldAlwaysShow = formikFieldKeys.some((field) => !!(formik.values as Record<string, any>)[field]);
    setSectionToggles((prev) => ({
      ...prev,
      [key]: shouldAlwaysShow ? true : !prev[key],
    }));
  };

  // When Formik values change, ensure that sections with data remain open.
  useEffect(() => {
    setSectionToggles((prev) => {
      const newToggles = { ...prev };
      advancedSearchSections.forEach((section) => {
        if (section.formikFields.some((field) => !!(formik.values as Record<string, any>)[field])) {
          newToggles[section.key] = true;
        }
      });
      return newToggles;
    });
  }, [formik.values]);

// Determine if all sections are currently open.
const allOpen = advancedSearchSections.every(
  (section) => sectionToggles[section.key] || section.formikFields.some((field) => !!(formik.values as Record<string, any>)[field])
);

// Handler to toggle all sections Show All and Close All buttons
const handleToggleAll = () => {
  setSectionToggles((prev) => {
    const newToggles: Record<string, boolean> = {};
    advancedSearchSections.forEach((section) => {
      // If a section has a forced open value from Formik then it remains open.
      const forcedOpen = section.formikFields.some(
        (field) => !!(formik.values as Record<string, any>)[field]
      );
      // Otherwise, if all are open then close it; if not, open it.
      newToggles[section.key] = forcedOpen ? true : !allOpen;
    });
    return newToggles;
  });
};

return (
  <Box data-testid="Box-yj2p" ref={searchRef}>
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div data-testid="div-84m1">
          <div data-testid="div-4jpj" style={{ overflowX: 'hidden'}}>
            <Accordion data-testid="Accordion-s139"
              expanded={Boolean(expanded)}
              onChange={(event, isExpanded) => setExpanded(isExpanded ? 'panel1' : false)}
              sx={{
                borderTopRightRadius: 2,
                borderTopLeftRadius: 2,
                border: 0,
                background: '#f9f9f9',
                boxShadow: 0,
                paddingBottom:0,
                marginBottom: 0,
                '&.Mui-expanded': {
                  paddingBottom: 0,
                  marginBottom: 0,
                },
              }}
              slotProps={{ transition: { timeout: 800 } }}
            >
              {/* Header with Search 'object name' and progress bar */}
              <AccordionSummary data-testid="AccordionSummary-v90u"
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{
                  height: '40px',
                  minHeight: '40px',
                  width: '100%',
                  background: '#f9f9f9',
                  display: 'flex',
                  flexDirection: 'row',
                  padding: 0,
                  margin: 0,
                  alignItems: 'center',
                  borderTopRightRadius: 2,
                  borderTopLeftRadius: 2,
                  justifyContent: 'center',
                  position: 'relative', // to position the progress bar absolutely
                  '&.Mui-expanded': {
                    minHeight: '40px',
                    margin: 0,
                  },
                }}
              >
                <Box data-testid="Box-n2eu" sx={{
                    width: '100%',
                    height: '100%',
                    background: colorConfigs.tables.headBg,
                  }}
                >
                  {/* Progress bar at the bottom */}
                  <Collapse in={totalCount > 0} timeout={200}>
                    <LinearProgress data-testid="LinearProgress-6jrc"
                        variant="determinate"
                        value={progress}
                        sx={{
                          display: showDownloadProgress ? 'block' : 'none',
                          backgroundColor: showDownloadProgress ? '#fff' : 'tranparent', // Background color (track)
                          '& .MuiLinearProgress-bar': {
                            backgroundColor: showDownloadProgress ? colorConfigs.text : 'tranparent', // Progress bar color
                          },
                        }}
                      />
                  </Collapse>
                  <Box data-testid="Box-0m9m"
                    sx={{
                      width: '100%',
                      height: '100%',
                      minHeight: '40px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      background: colorConfigs.tables.headBg,
                    }}
                  >
                  {/* Data Load Counter */}
                  <Box data-testid="Box-8bog"
                      sx={{
                        color: showDownloadProgress ? 'transparent' : '#fff',
                        maxWidth: '400px',
                        minWidth: '400px',
                        display: 'flex',
                        px: 2,
                      }}
                    >
                    {totalCountLoaded !== totalCount ? (
                    <Typography data-testid="Typography-yq85"
                      variant="body2"
                      sx={{
                        display: 'flex',
                        gap: 1,
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color:  showDownloadProgress ? 'transparent' : '#fff',
                      }}
                    >
                      {totalCountLoaded} / {totalCount} <FileDownloadOutlinedIcon sx={{ color:  showDownloadProgress ? 'transparent' : '#fff', }} />
                    </Typography>
                    ) : (
                    <Typography data-testid="Typography-6iva"
                      variant="body2"
                      sx={{
                        display: 'flex',
                        gap: 1,
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color:  showDownloadProgress ? '#fff' : 'transparent',

                      }}
                    >
                      {totalCountLoaded} / {totalCount} <CloudDoneOutlinedIcon sx={{ color:  showDownloadProgress ? '#fff' : 'transparent', }}  />
                    </Typography>
                    )}
                  </Box>
          
                  {/* Title */}
                  <Box data-testid="Box-bnxx" sx={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
                  <Typography data-testid="Typography-k2vw"
                    sx={{
                      color: '#fff',
                      fontSize: '18px',
                      fontWeight: 'bold',
                    }}
                  >
                    Search&nbsp;{pluralName}
                  </Typography>
                  {expanded ? (
                    <ExpandLessIcon sx={{ ml: 2, color: '#fff' }} />
                  ) : (
                    <ExpandMoreIcon sx={{ ml: 2, color: '#fff' }} />
                  )}
                </Box>
                  {/* Balance Box on right side */}
                <Box data-testid="Box-zbv6"
                  sx={{
                    ml: 2,
                    color: showDownloadProgress ? 'transparent' : '#fff',
                    p: 0.5,
                    maxWidth: '400px',
                    minWidth: '400px',
                  }}
                >
                </Box>
              </Box>
              {/* Progress bar at the bottom, The one thats actually on display */}
              <Collapse in={totalCount > 0} timeout={200}>
                <LinearProgress data-testid="LinearProgress-exhg"
                    variant="determinate"
                    value={progress}
                    sx={{
                      display: showDownloadProgress ? 'block' : 'none',
                      backgroundColor: showDownloadProgress ? '#fff' : 'tranparent', // Background color (track)
                      '& .MuiLinearProgress-bar': {
                        borderTop: '0.1px solid #fff',
                        backgroundColor: showDownloadProgress ? colorConfigs.buttons.success : 'tranparent', // Progress bar color
                      },
                    }}
                  />
              </Collapse>
            </Box>
          </AccordionSummary>

        <AccordionDetails
          sx={{
            maxHeight: 'calc(100vh - 200px)',
            background: '#f9f9f9',
            p:0,
            overflowY: 'auto',
            width: '100%',
            pt:0.25
          }}
        >
          <Collapse in={!!errors} timeout="auto">
            <Alert data-testid="Alert-eoq5" ref={errorRef} severity="error">
                <AlertTitle data-testid="AlertTitle-hlwf" sx={{display: 'flex', justifyContent: 'space-between'}}>Error <CloseIcon onClick={() => {setErrors("")}}/> </AlertTitle>
                {errors} 
            </Alert>
          </Collapse>

          <Box data-testid="Box-8bek" sx={{ width: "100%", display: "flex", flexDirection: {xs: 'column', sm: 'column', md: 'column', menu: 'column', lg: 'row'}, gap: {xs: 2, sm: 2, md: 0} }}>
            <Box data-testid="Box-jcid" sx={{ p:0.5, px:1, pt: 0.8, height: '100%', width: "100%", display: "flex", flexDirection: {xs: "column", sm: "column"}}}>
              <Card data-testid="Card-uea8" sx={{ boxShadow: 4, pt: 0, pb:0.5, height: '100%', width: '100%', display: "flex", flexDirection: 'column', gap: 0.5, mb: 1.2 }}>
                <Box data-testid="Box-s8q6" sx={{height: '100%', display: 'flex',}}>
                  <Box data-testid="Box-bw4r" sx={{height: '100%', background: colorConfigs.tables.headBg, display: 'flex', alignItems: 'center', borderBottomRightRadius: 3}}>
                    <Typography data-testid="Typography-1h5s" sx={{px: 2, fontSize: 16, color: '#fff', fontWeight: 'bold' }}>Quick Search</Typography>
                  </Box>
                </Box>   
                <Box data-testid="Box-z52o" sx={{ height: "100%", px: 1.5, py: 0.5, width: '100%', display: "flex", flexWrap: 'wrap', flexDirection: {xs: 'column', sm: 'row', md: 'row'}, alignItems: {xs: 'center', md: 'flex-start'}, gap: {xs: 0.5, sm: 0.5, md: 3}}}>
             
                  {/* Fetch Latest */}
                  <Box data-testid="Box-tyfy" sx={{ display: 'flex', flexDirection: 'column'}}>
                    <Typography data-testid="Typography-jvfk" sx={{pb: 0.5, color: colorConfigs.primary, fontSize: 14, fontWeight: 'bold' }}>Latest {pluralName}</Typography>
                    <Box data-testid="Box-cccm" sx={{p: 0.5, pl: 1, pt: 0.75,}}>
                      <Box data-testid="Box-s1hd" sx={{width: '100%', display: 'flex', gap: 1}}>
                        <FBLimitChip data-testid="FBLimitChip-fg8i" onClick={() => handleLimitButtons("10")} variant={formik.values.limit === "10" ? "success" : "outlined"}>10</FBLimitChip>
                        <FBLimitChip data-testid="FBLimitChip-kdwt" onClick={() => handleLimitButtons("100")} variant={formik.values.limit === "100" ? "success" : "outlined"}>100</FBLimitChip>
                        <FBLimitChip data-testid="FBLimitChip-y3st" onClick={() => handleLimitButtons("500")} variant={formik.values.limit === "500" ? "success" : "outlined"}>500</FBLimitChip>
                        <FBLimitChip data-testid="FBLimitChip-mk56" onClick={() => handleLimitButtons("1000")} variant={formik.values.limit === "1000" ? "success" : "outlined"}>1000</FBLimitChip>
                      </Box>
                    </Box>
                  </Box>

                  {/* Find By ID */}
                  <Box data-testid="Box-ilov" sx={{display: 'flex', flexDirection: 'column'}}>
                    <Typography data-testid="Typography-0h13" sx={{pb: 0.5, color: colorConfigs.primary, fontSize: 14, fontWeight: 'bold' }}>Search By ID</Typography>
                    <Box data-testid="Box-woi1" sx={{p: 0.5, pl: 1,display: 'flex', flexDirection: 'column', gap: 2}}>
                      <Box data-testid="Box-bqim" sx={{display: 'flex', gap: 2, maxWidth: '230px', minWidth: '230px'}}>
                      <Field data-testid="Field-3er5"
                          name="id"
                          label={`${singleName} ID`}
                          placeholder="e.g. 67a0df461608a78cc6269ef1"
                          type="text"
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === "Enter") {
                              setSearchMode("quick");
                            }
                          }}
                          component={FBIDEditField}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment data-testid="InputAdornment-hi0z" position="end">
                                <IconButton data-testid="IconButton-x947" sx={{p:0.5, px:0, "&:hover .MuiSvgIcon-root": { color: colorConfigs.buttons.successHovered}}} 
                                            onClick={() => handleIDSearch(formik.values.id)}>
                                  <SearchIcon fontSize="small" sx={{p:0}} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Apply Saved Filter */}
                  {userCanSaveFilter &&
                  <Box data-testid="Box-td13" sx={{ display: 'flex', flexDirection: 'column'}}>
                    <Typography data-testid="Typography-cd5t" sx={{pb: 0.5, color: colorConfigs.primary, fontSize: 14, fontWeight: 'bold' }}>Load Saved Search</Typography>
                    <Box data-testid="Box-chh4" sx={{p: 0.5, pl: 1, display: 'flex', flexDirection: 'column', gap: 2}}>
                      <Box data-testid="Box-ydz3" sx={{width: '100%', display: 'flex', gap: 2, maxWidth: '230px', minWidth: '230px'}}>
                      <Field data-testid="Field-8rpz"
                          name="selected_filter"
                          selectTitle={"Load Saved Search"}
                          options={savedFilterOptions}
                          label="Load Saved Search"
                          type="text"
                          className="form-control"
                          component={FormFBSavedSearchSelectField}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const filterName = e.target.value;
                            formik.setFieldValue("selected_filter", filterName);
                            const selectedFilter = savedFilters.find((f: any) => f.name === filterName);
                            if (selectedFilter) {
                              console.log("filter", selectedFilter);
                              handleCustomFilter(selectedFilter);
                            }
                          }}
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === "Enter") {
                              setSearchMode("advanced");
                            }
                          }}
                          onDeleteOption={(option: any) => {
                            // Call your deletion handler for a single custom filter
                            handleDeleteCustomFilter(option);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  }

                </Box>
              </Card>

              
              <Card data-testid="Card-n46b" sx={{ boxShadow: 4, pt: 0, height: '100%', width: '100%', display: "flex", flexDirection: 'column', gap: 0.5, mb: 1}}>
                <Box data-testid="Box-kt3o" sx={{display: 'flex', flexDirection: 'column'}}>
                  
                  {/* Header */}
                  <Box data-testid="Box-4r89" sx={{height: '100%', display: 'flex'}}>
                    <Box data-testid="Box-4qx2" sx={{height: '100%', background: colorConfigs.tables.headBg, display: 'flex', alignItems: 'center', borderBottomRightRadius: 3}}>
                      <Typography data-testid="Typography-p3uq" sx={{px: 2, fontSize: 16, color: '#fff', fontWeight: 'bold' }}>Advanced Search</Typography>
                    </Box>
                  </Box>

                  {/* Chip toggles for each section */}
                  <Box data-testid="Box-sc7r" sx={{ width: '100%', p:1.5, pb:0.5}}>
                    <Box data-testid="Box-xd6o" sx={{ display: 'flex',  flexWrap: 'wrap', width: '100%', justifyContent: {xs: 'center', sm: 'flex-start', md: 'flex-start'}, gap: {xs: 1, sm: 1, md: 2} }}>
                      {advancedSearchSections.map((section) => (
                        <FBLimitChip data-testid="FBLimitChip-g4pn"
                          key={section.key}
                          onClick={() => handleToggle(section.key, section.formikFields)}
                          variant={sectionToggles[section.key] ? 'contained' : 'outlined'}
                        >
                          {section.label}
                        </FBLimitChip>
                      ))}
                        <FBLimitChip data-testid="FBLimitChip-sgh2"
                          key="toggle-all"
                          onClick={handleToggleAll}
                          variant={allOpen ? 'outlined' : 'contained'}
                        >
                          {allOpen ? 'Close All' : 'Show All'}
                        </FBLimitChip>
                    </Box>
                  </Box>
                </Box>

                {/* Advanced Search Sections */}
                <Box data-testid="Box-1yhw" sx={{width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: {xs: 'center', sm: 'flex-start'}, px: 1, pb: 0.5}}>
                {/* Render each section inside a Collapse */}
                {advancedSearchSections.map((section) => (
                  <Collapse key={section.key} in={sectionToggles[section.key]} timeout="auto" sx={{ }}>
                    <Box data-testid="Box-3sug" sx={{ display: sectionToggles[section.key] ? 'flex' : 'none', pl: 0, flexDirection: 'column' }}>
                      <Typography data-testid="Typography-dxgr" sx={{ pb: 0.5, color: colorConfigs.primary, fontSize: 14, fontWeight: 'bold' }}>
                        {section.label}
                      </Typography>
                      <Box data-testid="Box-somk" sx={{ p: 0.5, pl: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {section.fields.map((field, idx) => (
                          <Box data-testid="Box-fqyt" key={idx} sx={{ display: 'flex', gap: 0, maxWidth: '155px', minWidth: '155px' }}>
                            <Field data-testid="Field-a5ap"
                              name={field.name}
                              label={field.label}
                              type="text"
                              className="form-control"
                              component={field.component}
                              // Pass along any extra props such as selectTitle and options if present.
                              {...(field.selectTitle ? { selectTitle: field.selectTitle } : {})}
                              {...(field.options ? { options: field.options } : {})}
                              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                if (e.key === 'Enter') {
                                  setSearchMode('advanced');
                                }
                              }}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </Collapse>
                ))}
                </Box>

                {/* Display Search, Save, Clear Buttons and Filtering Chips with tool tips */}
                <Collapse
                    in={filterCriteriaOptions.some(option => {
                      const value = formik.values[option.name as keyof typeof formik.values];
                      return typeof value === 'boolean' || value === 0 ? true : Boolean(value);
                    })}
                  >                  
                  <Box data-testid="Box-bu2j" sx={{width: '100%', display: 'flex', justifyContent: {xs: 'center', sm: 'flex-end', md:'space-between'}, gap: 1, px: 1, pb: 1}}>
                    
                    {/* Chips */}
                    <Box data-testid="Box-bexb" sx={{display: {xs: 'none', sm: 'none', md:'flex'}, flexWrap: 'wrap', gap: 1, px: 1, pt: 0.5}}>
                      {filterCriteriaOptions.map((option: any) => {
                        const rawValue = formik.values[option.name as keyof typeof formik.values];
                        // If the option is of type 'date' and follows the expected format, parse and format it
                        const displayValue =
                        typeof rawValue === 'string' && /^\d{4}-\d{2}-\d{2}T/.test(rawValue)
                        ? (() => {
                            // Extract the date part ("2025-02-04") and reformat as "04/02/2025"
                            const datePart = rawValue.substring(0, 10);
                            const [year, month, day] = datePart.split('-');
                            return `${month}/${day}/${year}`;
                          })()
                        : rawValue === true  ? 'Yes' // Handles booleans
                        : rawValue === false ? "No" 
                        : rawValue === 0 ? "0"
                        : rawValue;

                        // For date fields, adjust the display title.
                        const displayTitle = (() => {
                          // Check if the option name follows a date pattern.
                          // Examples: timestamp_from_date, timestamp_to_date, date_created_from_date, date_created_to_date
                          const dateFieldMatch = option.name.match(/^(timestamp|date_created|date_joined|expiry_date)_(from|to)_date$/);
                          if (dateFieldMatch) {
                            // Capitalise the prefix. If it contains an underscore, split it and capitalise each word.
                            const prefixParts = dateFieldMatch[1].split('_').map((part: any) => part.charAt(0).toUpperCase() + part.slice(1));
                            const prefix = prefixParts.join(' ');
                            // Capitalise the suffix.
                            const suffix = dateFieldMatch[2].charAt(0).toUpperCase() + dateFieldMatch[2].slice(1);
                            return `${prefix} ${suffix}`;
                          }
                          // Fallback to the original option label if not a date field.
                          return option.label;
                        })();

                      
                        return displayValue ? (
                          <Tooltip data-testid="Tooltip-ghq4" title={`${displayTitle}: ${displayValue}`}>
                          <Chip
                            key={option.name}
                            variant="outlined"
                            label={
                              <span>
                                <span data-testid="span-rg7m" style={{ fontSize: '11px', fontWeight: 'bold', color: colorConfigs.text}}>{displayTitle}</span>
                                {`: `}
                                <span data-testid="span-4s80" style={{ fontWeight: 'normal', color: colorConfigs.text }}>{displayValue}</span>
                              </span>
                            }
                            onDelete={() => handleChipDelete(option.name)}
                            deleteIcon={<CloseIcon fontSize='small'/>}
                            sx={{
                              height: "100%",
                              maxHeight: '1.5em',
                              fontSize: "12px",
                              // width: '100%',
                              display: 'flex', 
                              alignItems: 'center', 
                              justifyContent: 'flex-start',
                              fontWeight: "bold",
                              py: 0.2,
                              textAlign: 'start',
                              boxShadow: "0.1px 0.1px 3px rgba(0,0,0,0.1)",
                              transition: "none",
                              "&:hover": {
                                backgroundColor: "inherit",
                                boxShadow: "0.1px 0.1px 3px rgba(0,0,0,0.1)",
                              },
                              "& .MuiChip-deleteIcon": {
                                fontSize: "12px",
                                "&:hover": {
                                  color: "red",
                                },
                              },
                            }}
                          />
                          </Tooltip>
                        ) : null;
                      })}
                    </Box>

                    <Box data-testid="Box-272a" sx={{display: 'flex', gap: 1.5, alignItems: 'flex-end'}}>

                      <RareChip data-testid="RareChip-3o4y"
                        key="toggle-all1"
                        onClick={handleClearAll}   
                        // disabled={!filterCriteriaOptions.some(option => formik.values[option.name as keyof typeof formik.values])}     
                        variant="cancel"
                        sx={{p:0, height: "2em"}}
                      >
                        Clear All
                      </RareChip>
                      <RareChip data-testid="RareChip-p1cx"
                        key="toggle-all2"
                        // disabled={!userCanSaveFilter || !filterCriteriaOptions.some(option => formik.values[option.name as keyof typeof formik.values])}
                        onClick={() => {setSaveCustomFilter(true); formik.setFieldValue('new_filter', "")}}
                        variant="outlined"
                        sx={{height: "2em", display: userCanSaveFilter ? 'flex' : 'none'}}
                      >
                        Save
                      </RareChip>
                      <RareChip data-testid="RareChip-m22o"
                        key="toggle-all3"
                        onClick={handleAdvancedSearch}
                        // disabled={!filterCriteriaOptions.some(option => formik.values[option.name as keyof typeof formik.values])}
                        variant="success"
                        sx={{px: 4, height: "2em"}}
                      >
                        Search
                      </RareChip>
                        
                        {/* Hidden submit button to capture the Enter key */}
                        <button type="submit" style={{ display: "none" }} />

                    </Box>
                  </Box>
                </Collapse>
              </Card>

            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  </div>
      

  {/* Dialog for saving custom filter */}
  <Dialog data-testid="Dialog-l5bt"
    open={saveCustomFilter}
    onClose={() => setSaveCustomFilter(false)}
    fullWidth
    maxWidth="sm"
    TransitionComponent={Transition}
    sx={{
      '& .MuiDialog-paper': {
        alignItems: 'center',
        justifyContent: 'center',
        m:0,
      }
    }}
  >
    <AppBar 
      sx={{ 
        position: 'relative',
        height: sizeConfigs.detailPage.appbar.height,
        minHeight: sizeConfigs.detailPage.appbar.height,
        maxHeight: sizeConfigs.detailPage.appbar.height,
      }}
    > 
      <Toolbar 
        sx={{  
          alignItems: 'center',
          width: '100%', 
          color: '#fff', 
          display: 'flex', 
          justifyContent: 'space-between', 
          p:0, 
          backgroundColor: colorConfigs.tables.headBg,
          }}>
        <Box data-testid="Box-d6nw" onClick={(e) => e.stopPropagation()} sx={{ m: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
          <Typography data-testid="Typography-d1vy" sx={{ color: '#fff', fontWeight: 'bold', fontSize: fontConfigs.detailPage.headings}}>
              Create New Quick Search
          </Typography>
        </Box>
        <IconButton data-testid="IconButton-hdq6"
          edge="start"
          color="inherit"
          onClick={() => setSaveCustomFilter(false)}
          aria-label="close"
          sx={{ position: 'absolute', right: 15 }}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>
    </AppBar>

    <DialogContent data-testid="DialogContent-8opl" sx={{p: 4, display: 'flex', gap: 1, justifyContent: 'space-around', alignItems: 'center'}}>
      {/* Show current search chips */}
      <Box data-testid="Box-flox" sx={{ width: '100%', display: "flex", flexWrap: "wrap", gap: 1, p: 2 }}>
        {filterCriteriaOptions.map((option: any) => {
          const rawValue =
            formik.values[option.name as keyof typeof formik.values];
          const displayValue =
            option.name.toLowerCase().includes("date") &&
            (option.name.toLowerCase().includes("from") || option.name.toLowerCase().includes("to")) &&
            typeof rawValue === "string" &&
            rawValue.includes("T")
              ? rawValue.split("T")[0]
              : rawValue;
          return displayValue ? (
            <Chip
              key={option.name}
              variant="outlined"
              label={
                <span>
                  <span data-testid="span-00ol"
                    style={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      color: colorConfigs.text,
                    }}
                  >
                    {option.label}
                  </span>
                  {`: `}
                  <span data-testid="span-xov7" style={{ fontWeight: "normal", color: colorConfigs.text }}>
                    {displayValue}
                  </span>
                </span>
              }
              onDelete={() => handleChipDelete(option.name)}
              deleteIcon={<CloseIcon fontSize="small" />}
              sx={{
                height: "100%",
                fontSize: "12px",
                fontWeight: "bold",
                py: 0.2,
                boxShadow: "0.1px 0.1px 3px rgba(0,0,0,0.3)",
                transition: "none",
                "&:hover": {
                  backgroundColor: "inherit",
                  boxShadow: "0.1px 0.1px 3px rgba(0,0,0,0.3)",
                },
                "& .MuiChip-deleteIcon": {
                  fontSize: "12px",
                  "&:hover": {
                    color: "red",
                  },
                },
              }}
            />
          ) : null;
        })}
      </Box>
      {/* Input for naming the custom filter */}
      <Box data-testid="Box-prjh" sx={{minWidth: '300px', maxWidth: '300px'}}>
      <Field data-testid="Field-bybo" name="new_filter" type="text" className="form-control" label="Name Quick Search" component={TextEditField} />
      </Box>
    </DialogContent>
    <DialogActions data-testid="DialogActions-p152" sx={{width: '100%', justifyContent: 'space-between', p: 1}}>
      <RareButton data-testid="RareButton-3lzs" variant="outlined" onClick={() => setSaveCustomFilter(false)}>Cancel</RareButton>
      <RareButton data-testid="RareButton-4f1a" disabled={!formik.values.new_filter} variant="success" onClick={handleCustomFilterSave}>
        Save Filter
      </RareButton>
    </DialogActions>
  </Dialog>

                  
  </form>
</FormikProvider>
</Box>
);
};

export default FeedbackDynamicFilterBar;
