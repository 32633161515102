import React, { useState } from "react";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import api from "../../../redux/features/auth/axiosAuthHelper";
import { Button, Typography } from "@mui/material";
import { fetchUserProfile, refreshAccessToken } from '../../../redux/features/auth/authSlice';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import RareButton from "../buttons/RareButton";

interface UpgradeSubscriptionFormProps {
  upgradeTier: any,
}

const UpgradeSubscriptionForm: React.FC<UpgradeSubscriptionFormProps>  = ({upgradeTier}) => {
  const authData = useSelector((state: RootState) => state.auth);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const dispatch = useDispatch<AppDispatch>();

  const validationSchema = () => {
    return Yup.object().shape({
      tier: Yup.number()
        .required("Tier is required")
        .oneOf([1, 2, 3], "Invalid tier selected"),
    });
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      tier: upgradeTier || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      const payload = {
        tier: values.tier,
      };

      try {
        const response = await api.post(
          "payments/update-subscription/",
          payload,
          {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          }
        );
        if (response.status === 200) {
          setSuccess("Subscription upgraded successfully!");
          setError(""); // Clear any previous errors
          dispatch(fetchUserProfile());
        }
      } catch (error: any) {
        const errorTxt = JSON.stringify(error?.response?.data);
        setError(`An unexpected error occurred: ${errorTxt || error}`);
        setSuccess("");
        console.error("Error during checkout:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
        {!success &&
        <RareButton data-testid="RareButton-1x64" 
        type='submit'
        variant="success" 
        sx={{
          textAlign: 'center', 
          marginX: 'auto', 
          textTransform: 'none', 
          fontWeight: 'bold', 
          mt: 1, 
          mb:1, 
          width: '100%'
        }}>
          {loading ? (
        "Processing..."
    ) : (
        `Confirm Upgrade to Tier ${upgradeTier}`
    )}
      </RareButton>
        }
        </form>
      </FormikProvider>
      {error && <div data-testid="div-3g0f" style={{ color: "red" }}>{error}</div>}
      {success && <div data-testid="div-t3dc" style={{ color: "green" }}>{success}</div>}
    </>
  );
};

export default UpgradeSubscriptionForm;
