import React, { useEffect, useState } from 'react';
import { AuthState } from '../../redux/features/auth/authSlice';
import api from '../../redux/features/auth/axiosAuthHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';


export const statusLabels: Record<string, string> = {
  IN_PROGRESS: "In Progress",
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  DATARUN: "For Datarun"
};

export interface OrganisationData {
  id: string;
  name: string;
}

export interface UserData {
  id: number;
  name: string;
}

export interface HousingData {
    id: number;
    name: string;
}

export interface MethodData {
  id: number;
  name: string;
}

type SearchMethods = "window";

export interface ControlCorrection {
  extension: number;
  trim: number;
  div_profile: number;
  step_search: number;
  search_min?: number;
  search_max?: number;
  min_fit_percentage: number;
  search_method: SearchMethods;
}

export interface StripRegion {
  category: string;
  lower_bound: number;
  upper_bound: number;
}

export interface Strip {
  position: number;
  label: string;
  sample_rows: number;
  sample_cols: number;
  image_corners: [number, number][];
  profile: number[];
  strip_regions: StripRegion[];
  control_correction?: ControlCorrection;
}

export interface ImageItem {
  result_id: string;
  image_url: string;
  homography: number[];
  timestamp: string;
}
  
export interface Method {
  id?: number | string;
  housing: number | string;
  test_name: string;
  description: string;
  version?: number;
  status?: string;
  flash_required: boolean;
  collection_mode: boolean;
  initial_h_factor?: number;
  control_line_correction?: boolean;
  profile_alignment?: boolean;
  control_exposure?: boolean;
  profile_control_levels?: number[]; // optional
  polynomial_baseline_order?: number;
  frames_to_capture: number;
  control_baseline?: boolean;
  control_baseline_threshold?: number; // optional
  strip_image: ImageItem | null;
  strips: Strip[];
}

/**
 * Custom hook to fetch and manage clinic data based on the user type.
 * @param authData - The authData object containing authentication-related information.
 * @returns An array containing clinics.
 */
const useOrganisations = () => {
    // State to hold the fetched clinic data.
    const [organisations, setOrganisations] = useState<OrganisationData[]>([]);
    const authData = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        // Skip fetching if the user is not authenticated.
        if (!authData.isAuthenticated) {
            return;
        }

        // Asynchronous function for fetching clinic data.
        const fetchData = async () => {
            try {
                let organisationData: OrganisationData[] = [];
                if (authData.isSuperUser || authData.isAdmin) {
                    // Fetch Organisations using the 'api/organisations/' endpoint for administrators and support users.
                    const response = await api.get('organisations/', {
                        headers: {
                            Authorization: `Bearer ${authData.access}`,
                        },
                    });

                    organisationData = (response.data.results as OrganisationData[]).map(
                        ({ id, name }) => ({
                            id,
                            name,
                        }),
                    );
                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                } else if (authData.isOrganisationAdminAnyTier || authData.isStandardUserAnyTier) {
                    organisationData = [
                        {
                            id: String(authData.user?.organisation),
                            name: authData.user?.organisation_name || '',
                        },
                    ];

                    // Update the component state with the fetched organisation data.
                    setOrganisations(organisationData);
                }
                console.log('-----Organisations------');
                console.log(organisationData);
            } catch (error) {
                // Log an error message if there is an issue fetching organisation data.
                console.error('Error fetching organisation data:', error);
            }
        };

        // Call the fetchData function to initiate the effect.
        fetchData();
    }, [authData.isAuthenticated, authData.isSuperUser, authData.isAdmin, authData.isStandardUserAnyTier, authData.isOrganisationAdminAnyTier]);

    return [organisations] as const;
};

const useRareOrganisations = (): OrganisationData[] => {
  // State to hold the fetched clinic data.
  const [organisations, setOrganisations] = useState<OrganisationData[]>([]);
  const authData = useSelector((state: RootState) => state.auth);

  useEffect(() => {
      // Skip fetching if the user is not authenticated.
      if (!authData.isAuthenticated) {
          return;
      }

      // Asynchronous function for fetching clinic data.
      const fetchData = async () => {
          try {
              let organisationData: OrganisationData[] = [];
              let downloadPageSize = 250;  // Set the batch size to 250
              let downloadOffset = 0;
              let total = 0;
              if (authData.isSuperUser || authData.isAdmin) {
                  // Fetch Organisations using the 'api/organisations/' endpoint for administrators and support users.
                  const initialResponse = await api.get(`organisations/?limit=${downloadPageSize}&offset=${downloadOffset}`, {
                      headers: {
                          Authorization: `Bearer ${authData.access}`,
                      },
                  });

                  organisationData = (initialResponse.data.results as OrganisationData[]).map(
                      ({ id, name }) => ({
                          id,
                          name,
                      }),
                  );
                  // Update the component state with the fetched organisation data.
                  total = initialResponse.data.count;
                  setOrganisations([...organisationData].sort((a, b) => Number(b.id) - Number(a.id)));


                  // Now fetch the remaining in the background
                  while (organisationData.length < total) {
                    downloadOffset += downloadPageSize;
                    const url = `organisations/?limit=${downloadPageSize}&offset=${downloadOffset}`

                    const response = await api.get(url, {
                        headers: {
                            'Authorization': `Bearer ${authData.access}`
                        }
                    });
                    const nextPageorganisationData = (response.data.results as OrganisationData[]).map(
                      ({ id, name }) => ({
                          id,
                          name,
                      }),
                  );

                    organisationData.push(...nextPageorganisationData);
                    console.log(`Downloaded ${organisationData.length} of ${total} organisations`);
                    setOrganisations([...organisationData].sort((a, b) => Number(b.id) - Number(a.id)));
                  }

              } else if (authData.isOrganisationAdminAnyTier || authData.isStandardUserAnyTier) {
                  organisationData = [
                      {
                          id: String(authData.user?.organisation),
                          name: authData.user?.organisation_name || '',
                      },
                  ];

                  // Update the component state with the fetched organisation data.
                  setOrganisations([...organisationData]);
              }
              console.log('-----Organisations------');
              console.log(organisationData);
          } catch (error) {
              // Log an error message if there is an issue fetching organisation data.
              console.error('Error fetching organisation data:', error);
          }
      };

      // Call the fetchData function to initiate the effect.
      fetchData();
  }, [authData]);

  return organisations;
};


const useRareUsers = (): UserData[] => {
  const [users, setUsers] = useState<UserData[]>([]);
  const authData = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!authData.isAuthenticated) {
      return;
    }

    const fetchData = async () => {
      try {
        let userData: UserData[] = [];
        const downloadPageSize = 250;
        let downloadOffset = 0;
        let total = 0;

        if (authData.isAuthenticated) {
          const initialResponse = await api.get(
            `users/?limit=${downloadPageSize}&offset=${downloadOffset}`,
            { headers: { Authorization: `Bearer ${authData.access}` } }
          );

          userData = (initialResponse.data.results as any[]).map((user) => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`.trim(),
          }));
          total = initialResponse.data.count;
          setUsers([...userData]);

          while (userData.length < total) {
            downloadOffset += downloadPageSize;
            const response = await api.get(
              `users/?limit=${downloadPageSize}&offset=${downloadOffset}`,
              { headers: { Authorization: `Bearer ${authData.access}` } }
            );
            const nextPageUserData = (response.data.results as any[]).map((user) => ({
              id: user.id,
              name: `${user.first_name} ${user.last_name}`.trim(),
            }));
            userData.push(...nextPageUserData);
            setUsers([...userData]);
          }
        } 
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [authData]);

  return users;
};

const useRareHousings = (): HousingData[] => {
  const [housings, setHousings] = useState<HousingData[]>([]);
  const authData = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!authData.isAuthenticated) {
      return;
    }

    const fetchData = async () => {
      try {
        let housingData: HousingData[] = [];
        const downloadPageSize = 250;
        let downloadOffset = 0;
        let total = 0;

        if (authData.isAuthenticated) {
          const initialResponse = await api.get(
            `housing/?limit=${downloadPageSize}&offset=${downloadOffset}`,
            { headers: { Authorization: `Bearer ${authData.access}` } }
          );

          housingData = (initialResponse.data.results as any[]).map((housing) => ({
            id: housing.id,
            name: housing.name,
          }));
          total = initialResponse.data.count;
          setHousings([...housingData]);

          while (housingData.length < total) {
            downloadOffset += downloadPageSize;
            const response = await api.get(
              `housing/?limit=${downloadPageSize}&offset=${downloadOffset}`,
              { headers: { Authorization: `Bearer ${authData.access}` } }
            );
            const nextPageHousingData = (response.data.results as any[]).map((housing) => ({
              id: housing.id,
              name: housing.name,
            }));
            housingData.push(...nextPageHousingData);
            setHousings([...housingData]);
          }
        } 
      } catch (error) {
        console.error("Error fetching housing data:", error);
      }
    };

    fetchData();
  }, [authData]);

  return housings;
};


const useRareMethods = (): MethodData[] => {
  const [methods, setMethods] = useState<MethodData[]>([]);
  const authData = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!authData.isAuthenticated) {
      return;
    }

    const fetchData = async () => {
      try {
        let methodData: MethodData[] = [];
        const downloadPageSize = 250;
        let downloadOffset = 0;
        let total = 0;

        if (authData.isAuthenticated) {
          const initialResponse = await api.get(
            `methods/?limit=${downloadPageSize}&offset=${downloadOffset}`,
            { headers: { Authorization: `Bearer ${authData.access}` } }
          );

          methodData = (initialResponse.data.results as any[]).map((method) => ({
            id: method.id,
            name: method.test_name,
          }));
          total = initialResponse.data.count;
          setMethods([...methodData]);

          while (methodData.length < total) {
            downloadOffset += downloadPageSize;
            const response = await api.get(
              `methods/?limit=${downloadPageSize}&offset=${downloadOffset}`,
              { headers: { Authorization: `Bearer ${authData.access}` } }
            );
            const nextPageMethodData = (response.data.results as any[]).map((method) => ({
              id: method.id,
              name: method.test_name,
            }));
            methodData.push(...nextPageMethodData);
            setMethods([...methodData]);
          }
        } 
      } catch (error) {
        console.error("Error fetching method data:", error);
      }
    };

    fetchData();
  }, [authData]);

  return methods;
};

export {
    useOrganisations,
    useRareOrganisations,
    useRareUsers,
    useRareHousings,
    useRareMethods
};

export const useHousing = () => {
  const [housing, setHousing] = useState<HousingData[]>([]);
  const [housingError, setHousingError] = useState<string | null>(null);
  const authData = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const fetchHousings = async () => {
      try {
        let housingData: HousingData[] = [];
        let downloadPageSize = 250;  // Set the batch size to 250
        let downloadOffset = 0;
        let total = 0;
        const initialResponse = await api.get(`housing/?limit=${downloadPageSize}&offset=${downloadOffset}`, {
          headers: {
              Authorization: `Bearer ${authData.access}`,
          },
       });

        housingData = (initialResponse.data.results as HousingData[]).map(
          ({ id, name }) => ({
            id,
            name,
          })
        );
        total = initialResponse.data.count;
        setHousing([...housingData]);
        // Now fetch the remaining in the background
        while (housingData.length < total) {
          downloadOffset += downloadPageSize;
          const url = `housing/?limit=${downloadPageSize}&offset=${downloadOffset}`

          const response = await api.get(url, {
              headers: {
                  'Authorization': `Bearer ${authData.access}`
              }
          });
          const nextPageHousingData = (response.data.results as HousingData[]).map(
            ({ id, name }) => ({
                id,
                name,
            }),
        );

          housingData.push(...nextPageHousingData);
          console.log(`Downloaded ${housingData.length} of ${total} housing`);
          setHousing([...housingData]);
        }

        setHousingError("");
      } catch (err) {
        console.error(err);
        setHousingError("Failed to fetch housings");
      }
    };
    fetchHousings();
  }, [authData.isAuthenticated]);

  return { housing, housingError };
};

export const useDatasets = () => {
    const [datasets, setDatasets] = useState<any[]>([]);
    const [datasetError, setDatasetError] = useState<string | null>(null);
    const authData = useSelector((state: RootState) => state.auth);
    
    const fetchDatasets = async () => {
        try {
          const response = await api.get("datasets/", {
            headers: {
              Authorization: `Bearer ${authData.access}`,
            },
          });
  
          const housingData = (response.data.results as any[]).map(
            ({ id, name }) => ({
              id,
              name,
            })
          );
          setDatasets(housingData);
          setDatasetError("");
        } catch (err) {
          console.error(err);
          setDatasetError("Failed to fetch housings");
        }
      };
    useEffect(() => {

      fetchDatasets();
    }, [authData.isAuthenticated]);
  
    return { datasets, datasetError, fetchDatasets};
  };
  
