import { AppBar, Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemText, Paper, Slide, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import SignInDialog from '../../components/common/SignInDialog';
import api from '../../redux/features/auth/axiosAuthHelper';
import { logout, refreshAccessToken } from '../../redux/features/auth/authSlice';
import { DataSetSessionStorageKey, DataSetTableParameterStorageService } from '../../services/sessionStorage';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import colorConfigs from '../../configs/colorConfigs';
import sizeConfigs from '../../configs/sizeConfigs';
import fontConfigs from '../../configs/fontConfigs';
import DataSetTable from '../../components/common/tables/DataSetTable';
import DataSetDetailCard from '../../components/common/cards/DataSetDetailCard';
import DatasetDynamicFilterBar from '../../components/common/filterbars/DatasetDynamicFilterBar';

type Props = {}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const DatasetManagmentPage = (props: Props) => {

const authData = useSelector((state: RootState) => state.auth);
const [isLoading, setIsLoading] = useState<boolean>(false);
const [dataSet, setDataSet] = useState<any>(null);
const [popup, setPopup] = useState<any>("");
const [totalCount, setTotalCount] = useState<any>(0); 
const [totalCountLoaded, setTotalCountLoaded] = useState<any>(0); 
const [noResultsForQuery, setNoResultsForQuery] = useState<any>(false); 

const [sorting, setSorting] = useState<any>(() => {
  return DataSetTableParameterStorageService.get(DataSetSessionStorageKey.sorting) || []
});

const [columnVisibility, setColumnVisibility] = useState<any>(() => {
  return DataSetTableParameterStorageService.get(DataSetSessionStorageKey.columnVisibility) || authData.isAdmin ? {} : { organisation_id: false, user_id: false}
});

const [columnFilters, setColumnFilters] = useState<any>(() => {
  return DataSetTableParameterStorageService.get(DataSetSessionStorageKey.columnFilters) || []
});

const [globalFilter, setGlobalFilter] = useState<string>(() => {
  return DataSetTableParameterStorageService.get(DataSetSessionStorageKey.search) || "";
});

const [pagination, setPagination] = useState<any>(() => {
  return DataSetTableParameterStorageService.get(DataSetSessionStorageKey.pagination) || { pageSize: 250, pageIndex: 0 }
});

const [showGlobalFilter, setShowGlobalFilter] = useState<any>(() => {
  return DataSetTableParameterStorageService.get(DataSetSessionStorageKey.showSearch) || false
});

const [rows, setRows] = useState<any>(() => {
  return DataSetTableParameterStorageService.get(DataSetSessionStorageKey.data) || []
});

const [queryString, setQueryString] = useState<any>(() => {
  return DataSetTableParameterStorageService.get(DataSetSessionStorageKey.currentQueryString) || ""
});

// Handle Page Navigation Parameters
const { paramID } = useParams<{ paramID: string }>();
const navigate = useNavigate();
const isValidObjectId = (id: any) => /^[a-fA-F0-9]{24}$/.test(id); // Regular expression to match MongoDB ObjectId

useEffect(() => {
  const lowercaseID = paramID?.toLowerCase(); // Normalize to lowercase

  if (lowercaseID === "create") {
    setPopup("Create");
    setDataSet(null);
  } else if (lowercaseID && isValidObjectId(lowercaseID)) {
    // When the ID is a valid MongoDB ObjectId
    fetchData(`id=${lowercaseID}`).then((data) => {
      if (data.length > 0) {
        setDataSet(data[0]); // Access the first result directly
      } else {
        setDataSet({ id: lowercaseID, name: 'Not Found' });
        console.log("No data found for the given ID");
      }
      setPopup("Edit");
    }).catch((error) => {
      console.error("Error fetching data:", error);
    });
  } else if (lowercaseID && lowercaseID !== "create") {
    // If the ID is neither "create" nor a valid ObjectId, navigate to another page
    navigate("/data_analysis/data_run_management", { replace: true });
  }
}, [paramID, navigate]);


const handleRefreshButton = () => {
  setDataSet("")
  fetchData(queryString)
  setPopup("")
}

const handleActiveToggle = () => {
  fetchData(queryString)
}

const handleBackButton = () => {
  setDataSet("")
  setPopup("")
}

const fetchData = async (query = "") => {
  setIsLoading(true);
  const data: any[] = [];
  let downloadPageSize = 250;  // Set the batch size to 250
  let downloadOffset = 0;
  let total = 0;

  try {
      // Extract the limit from the query if it exists
      const urlParams = new URLSearchParams(query);
      const limitParam = urlParams.get('limit');
      
      // If the limit parameter exists and is a valid number, set the downloadPageSize
      if (limitParam && !isNaN(Number(limitParam))) {
          downloadPageSize = Number(limitParam);  
      }

      let url = `datasets/?${query}&skip=${downloadOffset}`;
      if (!urlParams.has('limit')) {
          url += `&limit=${downloadPageSize}`;
      }

      // Fetch the first batch to get the total count
      const initialResponse = await api.get(url, {
          headers: {
              'Authorization': `Bearer ${authData.access}`
          }
      });
      if (initialResponse.data.results.length < 1) {
        console.log("No results for this query")
        setIsLoading(false);
        setNoResultsForQuery(true)
        setRows([]);
        DataSetTableParameterStorageService.set(DataSetSessionStorageKey.data, [])
        DataSetTableParameterStorageService.set(DataSetSessionStorageKey.count, 0)
        return data
      } else {

        data.push(...initialResponse.data.results);
        if (limitParam && !isNaN(Number(limitParam))) {
          total = initialResponse.data.results.length
        } else {
          total = initialResponse.data.count;
        }
        downloadOffset += downloadPageSize;
        setTotalCount(total);
        setTotalCountLoaded(data.length);
        setIsLoading(false);
        setRows([...data]);
        setQueryString(query)
        DataSetTableParameterStorageService.set(DataSetSessionStorageKey.currentQueryString, query)
      }
      // Now fetch the remaining lots in the background

      while (data.length < total && !limitParam) {

          const url = query ?  `datasets/?${query}&limit=${downloadPageSize}&skip=${downloadOffset}` : `datasets/?limit=${downloadPageSize}&skip=${downloadOffset}`

          console.log("url_in_while", url);
          
          const response = await api.get(url, {
              headers: {
                  'Authorization': `Bearer ${authData.access}`
              }
          });

          data.push(...response.data.results);
          downloadOffset += downloadPageSize;
          setTotalCountLoaded(data.length);
          console.log(`Downloaded ${data.length} of ${total} datasets`);
      }

      // Final update after all lots are fetched
      setRows([...data]);
      console.log('Downloaded all datasets:', data);

      DataSetTableParameterStorageService.set(DataSetSessionStorageKey.data, data)
      DataSetTableParameterStorageService.set(DataSetSessionStorageKey.count, total)

      setTimeout(() => { 
        setTotalCount(false)
      }, 6000);

  } catch (error) {
      console.error('Error fetching datasets:', error);
  } finally {
    setIsLoading(false);
    return data
  }
};

useEffect(() => { 
  const dataInStorage = DataSetTableParameterStorageService.get(DataSetSessionStorageKey.count);
  if ((!noResultsForQuery && (!dataInStorage || dataInStorage === 0) && (rows.length < 1 || !rows.length))) {
    // No data in storage and no rows loaded, show loading and fetch data
    fetchData("limit=10");
  } else if (dataInStorage && rows.length < 1) {
      // Data exists in session storage but rows haven't loaded yet, show loading until rows are ready
      setTotalCount(dataInStorage);
      setTotalCountLoaded(0);
      setIsLoading(true)
      const storedData = DataSetTableParameterStorageService.get(DataSetSessionStorageKey.data) || [];

      const fetchStoredDataInBatches = async () => {
        const batchSize = 1000;
        let batchIndex = 0;
        let allData: any[] = [];
        // Retrieve patients from storage in batches of 250 until all are fetched
        while (batchIndex * batchSize < storedData.length) {
          const batch = storedData.slice(batchIndex * batchSize, (batchIndex + 1) * batchSize);
          if (batchIndex >= 1) {setIsLoading(false)}
          allData = [...allData, ...batch];
          setRows([...allData]);  // Update rows incrementally
          batchIndex++;
          setTotalCountLoaded(allData.length)
          await new Promise(resolve => setTimeout(resolve, 10));
        }
        setTimeout(() => { // Hide progress bar
          setTotalCount(false)
        }, 3000);
        setIsLoading(false);  // All batches loaded, stop loading
      };
  
      fetchStoredDataInBatches();
  } else if (rows.length > 0) {
    // Rows are loaded, stop loading
    setIsLoading(false);
  }

}, [rows.length, authData.isAuthenticated]);


const handleRemoveAllFilters = () => {
  setGlobalFilter("")
  setShowGlobalFilter(false)
  setColumnFilters([])
  setColumnVisibility({})
  setPagination({ pageSize: 250, pageIndex: 0 })
  setSorting([])
}

// AUTHENTICATION CHECK
const dispatch = useDispatch<AppDispatch>();
useEffect(() => {
  const checkAuthentication = async () => {
    if (!authData.isAuthenticated) {
      return;
    }

    try {
      await dispatch(refreshAccessToken()).unwrap();
    } catch (error) {
      console.log("Authentication check failed:", error);
    }
  };

  checkAuthentication();
}, [dispatch, authData.isAuthenticated, authData.access]);


  return authData.isAuthenticated === false ? (
    <SignInDialog isAuthenticated={authData.isAuthenticated} />
    ) : authData.isAuthenticated && authData.isFreeUser ? (
    <Navigate to="/account/subscription"/>
    ) : authData.isAuthenticated === true && !authData.isFreeUser ? (
      <div data-testid="div-c3wq" style={{ overflowX: "hidden", maxHeight: `100%`, minHeight: `100%`, maxWidth: `100%`, minWidth: `100%`, margin: "auto"}}>

      <div data-testid="div-8wup" style={{ display: "flex", height: "100%", width: "100%", maxWidth: `100%`, minWidth: `100%`,}}>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{m:0, height: "100%", width: "100%", maxWidth: `100%`, minWidth: `100%`,}}
        >
          <Grid item xs={3} sx={{m:0, p:0, maxWidth: `100%`, minWidth: `100%`}}>
            <Box data-testid="Box-cstj" sx={{ m:0, p:3, transition: 'padding-top 0.2s ease', height: "100%", overflowY: "auto", marginRight: 0, display: 'flex', flexDirection: 'column', overflowX: "hidden", maxWidth: `93vw`, minWidth: `100%` }}>
                <Box data-testid="Box-z8bo" sx={{ display: 'flex', flexDirection: 'column', overflowY: 'hidden', boxShadow: 5, borderTopRightRadius: 3, borderTopLeftRadius: 3 }}>
                <Box data-testid="Box-0n8j" sx={{
                    borderRadius: 1,
                    height: 'calc(100vh - 50px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <DatasetDynamicFilterBar
                        setIsLoading={setIsLoading} 
                        handleRemoveAllFilters={handleRemoveAllFilters} 
                        fetchData={fetchData}
                        totalCount={totalCount} 
                        totalCountLoaded={totalCountLoaded}
                        />
                <Box data-testid="Box-ek89" sx={{
                    flex: 1,
                    // maxHeight: 'calc(100vh - 295px)',
                    minHeight: 'calc(100vh - 295px)',
                    display: 'flex',
                    flexDirection: 'column',
                    borderTopRightRadius: 50,
                    borderTopLeftRadius: 0,
                    border: 'none',
                    boxShadow: 0,
                    
                }}>

                 <DataSetTable
                    rows={rows}
                    isLoading={isLoading}
                    sorting={sorting}
                    columnVisibility={columnVisibility}
                    columnFilters={columnFilters}
                    pagination={pagination}
                    globalFilter={globalFilter}
                    showGlobalFilter= {showGlobalFilter}
                    setShowGlobalFilter={setShowGlobalFilter}
                    handleRemoveAllFilters={handleRemoveAllFilters}
                    setGlobalFilter={setGlobalFilter}
                    setSorting={setSorting}
                    setColumnVisibility={setColumnVisibility}
                    setColumnFilters={setColumnFilters}
                    setPagination={setPagination}
                    setPopup={setPopup}
                    setDataSet={setDataSet}
                    handleRefreshButton={handleRefreshButton}
                    authData={authData}
                  />
                  </Box>
                  </Box>
                </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog data-testid="Dialog-isjs"
            open={(dataSet && popup === "Edit") || popup === "Create"}
            fullScreen
            onClose={() => {setDataSet(""); setPopup("")}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            TransitionComponent={Transition}
            sx={{
                height: '100vh',
                minHeight: '100vh',
              '& .MuiDialog-paper': {
                alignItems: 'center',
                justifyContent: 'center',
                p:0,
                height: '100vh',
                minHeight: '100vh'
              },
            }}
          >
            <AppBar
              onClick={() => { setDataSet(""); setPopup(""); }}
              sx={{
                position: 'fixed',
                top: 0,
                height: sizeConfigs.detailPage.appbar.height,
                minHeight: sizeConfigs.detailPage.appbar.height,
                maxHeight: sizeConfigs.detailPage.appbar.height,
                zIndex: 1201,
              }}
            >             
             <Toolbar
                sx={{
                  height: sizeConfigs.detailPage.appbar.height,
                  minHeight: sizeConfigs.detailPage.appbar.height,
                  maxHeight: sizeConfigs.detailPage.appbar.height,
                  padding: 0,
                  backgroundColor: colorConfigs.tables.headBg,
                  display: 'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center',
                  color: '#fff', 
                }}
              >
                {popup && (
                  <Box data-testid="Box-hnr5" onClick={(e) => e.stopPropagation()} sx={{ m: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'baseline'}}>
                  {popup === "Create" ? 
                  <>
                  <Typography data-testid="Typography-fnzf" sx={{color: '#fff',  fontSize: fontConfigs.detailPage.appbar.title, fontWeight: "bold", padding: "0 8px" }}>
                    Create Dataset
                  </Typography>
                  </>
                  :
                  <>
                  <Typography data-testid="Typography-j9dn" sx={{fontWeight: 'bold', color: '#fff',  ml: 2, fontSize: fontConfigs.detailPage.appbar.label, padding: "0 8px" }}>
                    Dataset Name
                  </Typography>
                  <Typography data-testid="Typography-ehsa" sx={{color: '#fff',  fontSize: fontConfigs.detailPage.appbar.title, fontWeight: "bold", padding: "0 8px" }}>
                    {dataSet.name}
                  </Typography>
                  </>
                  }
                  </Box>
                )}
                 <IconButton data-testid="IconButton-a49o"
                  edge="start"
                  color="inherit"
                  onClick={() => { setDataSet(""); setPopup(""); }}
                  aria-label="close"
                  sx={{
                    position: 'absolute',
                    right: 8,
                    padding: 0,
                    fontSize: '18px',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>


            <DialogContent data-testid="DialogContent-kwqp"
                  sx={{
                    height: sizeConfigs.detailPage.dialogContent.height,
                    minHeight: sizeConfigs.detailPage.dialogContent.height,
                    overflowY: 'auto',
                    '&::-webkit-scrollbar': { display: 'none' },
                    msOverflowStyle: 'none', // Internet Explorer 10+
                    scrollbarWidth: 'none', // Firefox
                    p: 0,
                    m: 0,
                    minWidth: '100vw',
                    backgroundColor: colorConfigs.sidebar.bg,
                    mt: sizeConfigs.detailPage.appbar.height,
                  }}
                >         
                {(popup === "Create") ?
                <DataSetDetailCard handleRefreshButton={handleRefreshButton} handleBackButton={handleBackButton} handleActiveToggle={handleActiveToggle} />
                :(popup === "Edit" && dataSet) ?
                <DataSetDetailCard dataset_id={dataSet.id} handleRefreshButton={handleRefreshButton} handleBackButton={handleBackButton} handleActiveToggle={handleActiveToggle}/>
                : null
              }       
            </DialogContent>
        </Dialog>
    </div>
) : (null);
};

export default DatasetManagmentPage;

