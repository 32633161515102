import { Box, Divider, Drawer, List, Stack, Toolbar } from "@mui/material";
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { useState } from "react";
import sizeConfigs from "../../../configs/sizeConfigs";
import colorConfigs from "../../../configs/colorConfigs";
import assets from "../../../assets";
import {appRoutes} from "../../../routes/appRoutes";
import { RootState } from "../../../redux/store";
import SidebarItemCollapse from "./SidebarItemCollapse";
import dayjs from 'dayjs';
import versionConfig from "../../../configs/versionConfig";
import SignInDialog from "../SignInDialog";

const Sidebar = () => {
  const [isHovered, setIsHovered] = useState(false);
  const authData = useSelector((state: RootState) => state.auth);
  const currentYear = dayjs().year();

  return (
    <Drawer
      variant="permanent"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        width: isHovered ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.halfwidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          transition: "width 0.3s",
          boxShadow: '0px 7px 10px 5px rgba(50, 50, 50, 0.6)',
          width: isHovered ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.halfwidth,
          boxSizing: "border-box",
          borderRight: "0px",
          fontWeight: "bold",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.buttons.sidebar,
          '&::-webkit-scrollbar': { display: 'none' },
          msOverflowStyle: 'none', // Internet Explorer 10+
          scrollbarWidth: 'none', // Firefox
          opacity: 0.94,
          zIndex: 10
        }
      }}
    >
      <List disablePadding>
        <Toolbar sx={{height: "100px" }}>
          <Stack data-testid="Stack-4m44"
            sx={{ width: "100%", height: "80px" }}
            direction="row"
            justifyContent="center"
          >
            <Box data-testid="Box-8uhv"
              component="img"
              sx={{
                height: "100%",
                width: "auto",
                maxWidth: isHovered ? sizeConfigs.sidebar.width : sizeConfigs.sidebar.halfwidth,
                maxHeight: '60px',
                transition: "all 0.3s ease",
                backgroundColor: colorConfigs.sidebar.bg,
                paddingTop: 0,
                paddingBottom: 0,
                paddingX: 0.5,
                borderRadius: 1,
                marginTop: { xs: 1, md: 2 },
              }}
              alt="NovarumDX Logo"
              src={ isHovered ? assets.images.rareLogo : assets.images.brainLogo}
            />
          </Stack>
        </Toolbar>
        {appRoutes.map((route:any, index:any) => {
          if (authData !== undefined && !authData.isAuthenticated) return <SignInDialog isAuthenticated={authData.isAuthenticated} />
          if (route.sidebarProps && authData !== undefined) {
            if (route.child) {
              return <SidebarItemCollapse item={route} key={index} isHovered={isHovered} />;
            } else {
              return <SidebarItem item={route} key={index} isHovered={isHovered} />;
            }
          }
          return null;
        })}
      </List>
      <Stack data-testid="Stack-7rxq"
        sx={{
          color: '#555',
          marginTop: "auto", // Pushes the footer to the bottom
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingBottom: "20px",
          gap: 1,
        }}
      >
        {isHovered && <Box data-testid="Box-w4ys">©&nbsp;Novarum&nbsp;DX&nbsp;Ltd&nbsp;{currentYear}</Box> }
        <Box data-testid="Box-qmhn">{versionConfig.version}</Box>
      </Stack>
    </Drawer>
  );
};

export default Sidebar;
