import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import assets from "../../assets";
import { Alert, Card, InputAdornment, IconButton, LinearProgress } from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { loginUser } from '../../redux/features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import { unwrapResult } from '@reduxjs/toolkit';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import colorConfigs from '../../configs/colorConfigs';
import versionConfig from '../../configs/versionConfig';
import RareButton from './buttons/RareButton';

function Copyright(props: any) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      DDTD is registered trademarks of DDTD in many countries of the world.
    </Typography>
  );
};

const SignIn = () => {
  const [isSubmit, setSubmit] = useState(false);
  const [authfailed, setAuthFailed] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false); // New loading state
  const alertContent = "Login failed, please check email and password";
  const dispatch = useDispatch<AppDispatch>();
  const authData = useSelector((state: RootState) => state.auth); // Accessing the auth state

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true); // Start progress indicator
    const data = new FormData(event.currentTarget);
    const username = data.get('email') as string;
    const password = data.get('password') as string;

    try {
      setAuthFailed(false);
      const resultAction = await dispatch(loginUser({ username, password }));
      unwrapResult(resultAction); // If the promise is rejected, this will throw
      setSubmit(true); // Or navigate to another page

    } catch (err) {
      console.error('Failed to login:', err);
      setAuthFailed(true);
    } finally {
      setLoading(false); // Stop progress indicator whether successful or not
    }
  };

  //  Log Auth Data
  React.useEffect(() => {
    console.log(authData);
  }, [authData]);

  return isSubmit === false ? (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 4,
        // justifyContent: 'center',
        px: 2,
      }}
    >
      {/* Logo at the top */}
      <Box data-testid="Box-zg2j"
        component="img"
        sx={{
          width: { xs: '80%', sm: '60%', md: '40%', lg: '35%', xl: '30%' }, // Responsive widths
          height: 'auto',
          mb:  {xs: 3, sm: 1},
        }}
        alt="Rare Logo"
        src={assets.images.rareLogo}
      />
      
      {/* Sign‑in card */}
      <Card
        sx={{
            width: { xs: '80%', sm: '60%', md: '50%', lg: '40%', xl: '30%' }, // Responsive widths
            boxShadow: 10,
          p: {xs: 3, sm: 4, md: 5, lg: 8},
          borderRadius: 5,
        }}
      >
        <CssBaseline />
        {loading && <LinearProgress sx={{ mb: 2,   
               backgroundColor: '#fff', // Background color (track)
              '& .MuiLinearProgress-bar': {
                backgroundColor: colorConfigs.primary, // Progress bar color
              }, }} />}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: {xs: 1, sm: 0, md: 5}
          }}
        >
          <Box component="form" onSubmit={handleSubmit} sx={{width: '100%'}}>
            {authfailed ? <Alert severity="error">{alertContent}</Alert> : null}
            <Box sx={{width: '100%', display: 'flex', flexDirection: 'column'}}>  
            <TextField data-testid="TextField-mv4b"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField data-testid="TextField-xgil"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                my: 2,
              }}
            >
              <RareButton data-testid="RareButton-h17o" type="submit" fullWidth variant="contained" sx={{width: {xs: '90%', sm: '50%'}}}>
                Sign In
              </RareButton>
            </Box>
            <Box >
              <Link data-testid="Link-cgqj" href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Box>
          </Box>
          <Typography data-testid="Typography-lk65" variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
            {versionConfig.version}
          </Typography>
        </Box>
      </Card>
    </Box>
  ) : (
    <Navigate to="/" />
  );
};

export default SignIn;