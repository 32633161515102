import { Card, Grid, Typography, Button, FormControlLabel, Checkbox, TextField, Box, Alert, AlertTitle } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Field, FormikProvider, useFormik } from 'formik';

import * as Yup from "yup";
import api from '../../../redux/features/auth/axiosAuthHelper';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';
import {  TextEditField, TextEditReadOnlyField, OrganisationSelectField, UserTypeSelectField, SubscriptionSelectField, OranisationStatusSelectField, OrganisationUsersReadOnlyField } from '../fields/fields';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import colorConfigs from '../../../configs/colorConfigs';
import { OrganisationSessionStorageKey, OrganisationTableParameterStorageService } from '../../../services/sessionStorage';
import RareButton from '../buttons/RareButton';

interface OrganisationDetailProps {
  organisation_id?: any | null;
  handleRefreshButton: () => void;
  handleBackButton: () => void;
}

const phoneRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const OrganisationDetailCard: React.FC<OrganisationDetailProps> = ({organisation_id, handleRefreshButton, handleBackButton}) => {
    const authData = useSelector((state: RootState) => state.auth);
    const [errors, setErrors] = useState<string | null>(null);
    const [initialValues, setInitialValues] = useState<any>({})
    const [updatedValues, setUpdatedValues] = useState<any>({})
    const [hasMadeChanges, setHasMadeChanges] = useState<boolean>(false)
    const [isActiveChecked, setIsActiveChecked] = useState(false);
    const [organisation, setOrganisation] = useState<any>(null);

    const create = !organisation_id

        // Fetch housing data and point_model JSON data
        useEffect(() => {
            if (organisation_id) {
                const fetchHousing = async () => { 
                    try {
                        const url = `organisations/${organisation_id}`;
                        
                        const response = await api.get(url, {
                            headers: {
                                'Authorization': `Bearer ${authData.access}`
                            }
                        });
                        
                        const fetchedHousing = response.data;
                        setOrganisation(fetchedHousing);
                    } catch (fetchError: any) {
                        if (fetchError.response !== "Request cancelled... refreshing token"){
                        console.error('Error fetching organisation', fetchError);
                        setErrors(`No Organisation found with ID ${organisation_id}`)
                        setTimeout(() => {
                            handleBackButton()
                        }, 4000);
                    }}
                };
                fetchHousing();    
            }
        }, [organisation_id]);


    const validationSchema = () => {
        return Yup.object().shape({
        });
    }

    const formik = useFormik({
        enableReinitialize: !create,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues:
            (!create && organisation) ? {
                id: organisation.id || '',
                name: organisation.name || '',
                email: organisation.email || '',
                tier: organisation.subscription_tier || 0,
                date_created: organisation.date_created ? dayjs(organisation.date_created).format('YYYY-MM-DD HH:mm:ss') : null,
                status: organisation.status || 'ACTIVE',
                active_standard_users_count: organisation.active_standard_users_count || 0,
                permitted_number_of_users: organisation.permitted_number_of_users || 0,
                can_create_new_users: organisation.can_create_new_users || false,
            }
            : {
                id: '',
                name: '',
                email: '',
                subscription: 0,
                tier: '',
                date_created: dayjs(),
                status: 'ACTIVE'
            },
        validationSchema,
        onSubmit: async (values) => {

            const data = authData.isAdmin ? {
                name: values.name,
                // reader_config: values.reader_config,
                tier: values.tier ? values.tier - 1 : '',
                email: values.email,
                status: values.status,
            } : {
                name: values.name,
                email: values.email,
            }

            const url = organisation ? `organisations/${organisation.id}/` : `organisations/`
            const method = organisation ? 'patch' : 'post'

            try {
                console.log("data", data)
                await api ({
                    method,
                    url,
                    data,
                    headers: {
                        'Authorization': `Bearer ${authData.access}`,
                    },
                });
                setErrors(null);
                console.log("Organisation successfully updated")
                handleRefreshButton() // reset states and close popup
                const count = OrganisationTableParameterStorageService.get(OrganisationSessionStorageKey.count)
                OrganisationTableParameterStorageService.set(OrganisationSessionStorageKey.count, count + 1) // Updated count to not trigger refresh data button
            } catch(error: any) {
                if (error?.response?.status === 400) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${firstErrorItem}: ${error.response.data[firstErrorItem]}`);
                } else if (error?.response?.status === 403) {
                    const firstErrorItem = Object.keys(error.response.data)[0];
                    setErrors(`${error.response.data[firstErrorItem]}`);
                } else{
                    const errorText = `Unknown error updating lot`;
                    setErrors(errorText);
                    console.error(errorText, error);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
    });

  
    // Effect to set initial form values from organisation to check for form changes
    useEffect(() => {
        setTimeout(() => {
            setInitialValues(formik.initialValues);
            setUpdatedValues(formik.initialValues);
        }, 500);
        setHasMadeChanges(false);
    }, [organisation]);

    // Update the updatedValues state when formik values change
    useEffect(() => {
        setUpdatedValues(formik.values);
    }, [formik.values]);

    // Function to check if form has changed so the save button becomes active on edit
    const hasFormChanged = (flatModel: Record<string, any>, formValues: Record<string, any>): boolean => {
        for (const key in formValues) {
            if (key === "expiry_date") {
                const formDate = dayjs(formValues[key]).format('YYYY-MM-DD');
                const initialDate = create ? dayjs(flatModel[key]).format('YYYY-MM-DD') : dayjs(formValues[key]).format('YYYY-MM-DD');
                if (formDate !== initialDate) {
                    console.log("Changed Date", formDate, initialDate)
                    return true;
                }
            } else {
                if (formValues[key]!= flatModel[key]) {
                    console.log("Changed Data", formValues[key], flatModel[key])
                    return true;
                }
            }
        }
        return false;
    };
            
    // Use Effect to check if any changes ahve been made to the form on Edit
    useEffect(() => {
        if (!create && organisation) {
            if (Object.keys(initialValues).length > 0 && Object.keys(updatedValues).length > 0) {
                const formChanged = hasFormChanged(initialValues, updatedValues);
                setHasMadeChanges(formChanged);
            }
        }
    }, [updatedValues, initialValues, organisation]);
    
    // Use Effect set save button active on load if Create and if changes are made on Edit
    useEffect(() => {
        if (create) {
            setHasMadeChanges(true);
        } else {
            setHasMadeChanges(false);
        }
    }, [create]);

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} style={{overflow: 'auto'}}>
            <Card data-testid="Card-v74t" sx={{ height: "100%", boxShadow: 10, p:2, marginX: 5, marginY: 3, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {errors && (
                <Alert data-testid="Alert-q9l5" severity="error" style={{ width: "59.5%" }}>
                    <AlertTitle data-testid="AlertTitle-pwv7">Error</AlertTitle>
                    {errors}
                </Alert>
            )}
            <div data-testid="div-kac5" style={{width: "100%", height: "100%", margin: "auto", padding: "1em", display: "flex", alignItems: "center"}}>
                <Grid container spacing={5} alignItems="top" justifyContent="center" style={{ minWidth: "100%", padding: 5 }}>
                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                                <Card data-testid="Card-mh6j" sx={{ pb: 1, boxShadow: 10, height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Box data-testid="Box-6ihk" sx={{display: 'flex'}}>
                                        <Box data-testid="Box-354f" sx={{height: '100%', background: colorConfigs.tables.headBg, display: 'flex', alignItems: 'center', borderBottomRightRadius: 3}}>
                                        <Typography data-testid="Typography-v6hs" sx={{px: 2, py: 0.5, color: '#fff', fontWeight: 'bold', fontSize: '20px' }}>Organisation Details</Typography>
                                        </Box>
                                    </Box>
                                    <Box data-testid="Box-9fer" sx={{p: 2, pt:3, height: '100%', display: 'flex', flexDirection: 'column', gap: 3}}>
                                        {!create && <Field data-testid="Field-aw6h" name="id" disableds type="text" className="form-control" label="Organisation ID" component={TextEditReadOnlyField}  />}
                                        <Field data-testid="Field-g2sz" name="name" type="text" className="form-control" label="Organisation Name" component={TextEditField} />
                                        <Field data-testid="Field-2uvp" name="email" type="text" className="form-control" label="Organisation Email" component={TextEditField} />
                                        {authData.isAdmin ? 
                                            <Field data-testid="Field-1g2x" name="status" type="text" className="form-control" label="Status" component={OranisationStatusSelectField} />
                                            :
                                            <Field data-testid="Field-qjym" name="status" type="text" className="form-control" label="Status" component={TextEditReadOnlyField} />
                                        }
                                        </Box>
                                </Card>
                            </Grid>
                            {!create && 

                            <Grid item xs={12} md={5} style={{ display: "flex", flexDirection: "column",  }}>
                            <Card data-testid="Card-4l6c" sx={{ pb: 1, boxShadow: 10, height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Box data-testid="Box-c9cj" sx={{display: 'flex'}}>
                                    <Box data-testid="Box-qy2b" sx={{height: '100%', background: colorConfigs.tables.headBg, display: 'flex', alignItems: 'center', borderBottomRightRadius: 3}}>
                                    <Typography data-testid="Typography-b0c2" sx={{px: 2, py: 0.5, color: '#fff', fontWeight: 'bold', fontSize: '20px' }}>Organisation Stats</Typography>
                                    </Box>
                                </Box>
                                <Box data-testid="Box-okr7" sx={{p: 2, pt:3, height: '100%', display: 'flex', flexDirection: 'column', gap: 3}}>
                                {!create && <Field data-testid="Field-sspk" name="date_created" type="text" className="form-control" label="DateCreated" component={TextEditReadOnlyField} />}
                                {!create && <Field data-testid="Field-lwzc" name="tier" type="text" className="form-control" label="Subscription" component={SubscriptionSelectField} /> }
                                <Field data-testid="Field-4g0o"
                                    name="users"
                                    component={OrganisationUsersReadOnlyField}
                                    activeUsersCount={formik.values.active_standard_users_count}
                                    permittedUsersCount={formik.values.permitted_number_of_users}
                                    canCreateNewUsers={formik.values.can_create_new_users}
                                    label="No of Users"
                                />                                
                                </Box>
                                </Card>
                            </Grid>
                                }
                            </Grid>
                    </div>
                    <div data-testid="div-gdne" className="form-group" style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                        <RareButton data-testid="RareButton-ushu"
                            onClick={() => handleBackButton()}
                            variant="outlined"
                            sx={{
                                position: "relative",
                                m: 1,
                            }}
                        >
                            Back
                        </RareButton>
                        <RareButton data-testid="RareButton-ucxy"
                            type="submit"
                            variant="success"
                            disabled={!hasMadeChanges}
                            sx={{
                                position: "relative",
                                m: 1,
                            }}
                        >
                            Save Changes
                        </RareButton>
                    </div>
                </Card>
            </form>
        </FormikProvider>
    )
}

export default OrganisationDetailCard;