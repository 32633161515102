import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  TextField,
  MenuItem,
  Box,
  Card,
} from "@mui/material";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";

import { ControlCorrection } from "../CustomHooks";
import { TextEditField } from "../fields/fields";
import RareButton from "../buttons/RareButton";
import colorConfigs from "../../../configs/colorConfigs";

interface CustomDialogProps {
  disabled?: boolean;
  setData: (value: ControlCorrection | undefined) => void;
  data: ControlCorrection | undefined;
  defaultSearchMin?: number;
  defaultSearchMax?: number;
}

const ControlCorrectionPopup: React.FC<CustomDialogProps> = ({
  disabled = false,
  data,
  setData,
  defaultSearchMin,
  defaultSearchMax,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const initialValues = data as ControlCorrection;

  const validationSchema = () =>
    Yup.object({
      extension: Yup.number().required("Required"),
      trim: Yup.number().required("Required"),
      div_profile: Yup.number().required("Required"),
      step_search: Yup.number().required("Required"),
      search_min: Yup.number().required("Required"),
      search_max: Yup.number().required("Required"),
      min_fit_percentage: Yup.number().required("Required"),
      search_method: Yup.string().required("Required"),
    });

  return (
    <>
      <RareButton data-testid="RareButton-gnn9"
        disabled={disabled}
        variant="contained"
        onClick={openDialog}
        sx={{ margin: "15px" }}
      >
        Control Correction
      </RareButton>

      <Dialog
        data-testid="Dialog-vbrm"
        open={isOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            closeDialog();
          }
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle data-testid="DialogTitle-p7lh">
          Control Correction
        </DialogTitle>
        <Formik
          initialValues={{
            ...initialValues,
            search_min: initialValues?.search_min ?? defaultSearchMin,
            search_max: initialValues?.search_max ?? defaultSearchMax,
          }}
          validationSchema={validationSchema}
          onSubmit={(values: ControlCorrection) => {
            setData(values);
            closeDialog();
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <DialogContent data-testid="DialogContent-s38j">
                {/* Yellow warning box */}
                <Alert
                  data-testid="Alert-7y7d"
                  severity="warning"
                  style={{ marginBottom: "16px" }}
                >
                  This is an advanced feature recommended for use by Novarum DX
                </Alert>

                <div
                  data-testid="div-pznc"
                  style={{ flexGrow: 0, width: "100%" }}
                >
                    <Card data-testid="Card-btvj" sx={{ 
                      py: 3,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: "1rem",
                      width: "100%", 
                      boxShadow: 10, 
                      height: '100%', 
                      }}>

                    <Box data-testid="Box-fskp" sx={{p:3, height: '100%', display: 'flex', flexDirection: 'column', gap: 3}}>
                      {/* Extension field */}
                      <Field
                        data-testid="Field-0xfi"
                        name="extension"
                        type="number"
                        className="form-control"
                        label="Extension"
                        component={TextEditField}
                        required
                      />
                      <Field
                        data-testid="Field-spij"
                        name="trim"
                        type="number"
                        className="form-control"
                        label="Trim"
                        component={TextEditField}
                        required
                      />
                      <Field
                        data-testid="Field-5khc"
                        name="div_profile"
                        type="number"
                        className="form-control"
                        label="Div Profile"
                        component={TextEditField}
                        required
                      />
                      <Field
                        data-testid="Field-zhvd"
                        name="step_search"
                        type="number"
                        className="form-control"
                        label="Step Search"
                        component={TextEditField}
                        required
                      />
                    </Box>
                    <Box data-testid="Box-vlq3" sx={{p: 2, pt:3, height: '100%', display: 'flex', flexDirection: 'column', gap: 3}}>
                  
                      <Field
                        data-testid="Field-u5xp"
                        name="search_min"
                        type="number"
                        className="form-control"
                        label="Search Min"
                        component={TextEditField}
                        required
                      />
                      <Field
                        data-testid="Field-nf7i"
                        name="search_max"
                        type="number"
                        className="form-control"
                        label="Search Max"
                        component={TextEditField}
                        required
                      />
                      <Field
                        data-testid="Field-6dtg"
                        name="min_fit_percentage"
                        type="number"
                        className="form-control"
                        label="Minimum Fit Percentage"
                        component={TextEditField}
                        required
                      />
                      <TextField
                        data-testid="TextField-1yib"
                        fullWidth
                        select
                        label="Search Method"
                        margin="normal"
                        name="search_method"
                        value={data?.search_method || "window"}
                        required
                        sx={{
                          m: 0,
                          fontSize: '16px',
                          '& Mui.select': {
                            padding: 1,
                            paddingX: 2,
                            p: 0,
                        },  
                        }}
                        InputProps={{
                          sx:{
                            fontSize: '16px',
                            '& input': {
                              padding: 1,
                              paddingX: 2,
                            },
                            '& .MuiOutlinedInput-input': {
                              fontSize: '14px',
                              padding: 1,
                              paddingX: 2,
                          },  
                          }
                      }}
                      InputLabelProps={{
                          style: {
                            fontSize: '16px',
                            color: colorConfigs.text,
                          },
                          shrink: true 
                      }}
                      >
                        <MenuItem
                          data-testid="MenuItem-w0ot"
                          key={0}
                          value="window"
                        >
                          Window
                        </MenuItem>
                      </TextField>
                    </Box>
                  </Card>
                </div>
              </DialogContent>
              <DialogActions data-testid="DialogActions-hzpx">
                <RareButton data-testid="RareButton-q6u8"
                  onClick={closeDialog}
                  sx={{ margin: "10px" }}
                  variant="outlined"
                >
                  Cancel
                </RareButton>
                <RareButton data-testid="RareButton-ufda"
                  type="submit"
                  variant="success"
                  sx={{ margin: "10px" }}
                >
                  Confirm
                </RareButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default ControlCorrectionPopup;
