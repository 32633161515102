import { useEffect, useMemo, useState } from "react";
import { MaterialReactTable, MRT_Row, type MRT_ColumnDef, type MRT_SortingState } from 'material-react-table';
import dayjs from 'dayjs';
import { useMediaQuery, Box, Button, Grid, Link, Typography, ListItemIcon, Menu, MenuItem, MenuProps, Paper, alpha, styled, Theme, Switch, Tooltip, IconButton } from '@mui/material';
import assets from "../../../assets";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CssBaseline from '@mui/material/CssBaseline';
import { ResultSessionStorageKey, ResultSessionStorageModel, ResultTableParameterStorageService } from "../../../services/sessionStorage";
import colorConfigs from "../../../configs/colorConfigs";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { tsXLXS } from 'ts-xlsx-export';
import * as XLSX from "xlsx";
import { MRT_FilterFns } from 'material-react-table';
import RareButton from "../buttons/RareButton";
import api from "../../../redux/features/auth/axiosAuthHelper";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const StyledMenu = styled((props: any) => (
    <Menu data-testid="Menu-9w0d"
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


interface ResultsTableProps {
    authData: any;
    rows: any[];
    isLoading: boolean;
    sorting: any;
    columnVisibility: any;
    columnFilters: any;
    pagination: any;
    globalFilter: any;
    showGlobalFilter: any;
    setShowGlobalFilter: (value: any) => void;
    handleRemoveAllFilters: () => void;
    setGlobalFilter: (value: string) => void;
    setSorting: (sorting: any[]) => void;
    setColumnVisibility: (columnVisibility: { [key: string]: boolean }) => void;
    setColumnFilters: (filters: any[]) => void;
    setPagination: (pagination: { pageSize: number; pageIndex: number }) => void;
    openPopup: (row: any) => void;
    handleRefreshButton: () => void;
  }

const ResultsTable: React.FC<ResultsTableProps> = ({
  authData,
  rows,
  isLoading,
  sorting,
  columnVisibility,
  columnFilters,
  pagination,
  globalFilter,
  showGlobalFilter,
  setShowGlobalFilter,
  setGlobalFilter,
  handleRemoveAllFilters,
  setSorting,
  setColumnVisibility,
  setColumnFilters,
  setPagination,
  openPopup,
  handleRefreshButton
}) => {

    console.log("Results", rows)
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:768px)');

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleSaveFilterClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const customResultTypeFilter = (row: any, columnId: any, filterValue: string) => {
      const { datarun_id, test_configuration } = row.original;
      const isDCMAppResult = !datarun_id && test_configuration?.collection_mode;
      const isNormalAppResult = !datarun_id && !test_configuration?.collection_mode;
      const isDatarunResult = !!datarun_id;
    
      const datarunText = `Datarun: ${datarun_id}`;
      const searchValue = filterValue.toLowerCase();
    
      return (
        (isDCMAppResult && "data collection mode result".includes(searchValue)) ||
        (isNormalAppResult && "normal app result".includes(searchValue)) ||
        (isDatarunResult && "reprocessed result".includes(searchValue)) ||
        (isDatarunResult && datarunText.toLowerCase().includes(searchValue))
      );
    };
    
    const filterFns = {
      ...MRT_FilterFns,
      customResultTypeFilter,
    };


const copyToClipboard = (text: any) => {
  navigator.clipboard.writeText(text).catch((err) => {
    console.error('Failed to copy: ', err);
  });
};


    const columns: MRT_ColumnDef<any>[] = [
        {
          accessorKey: 'id',
          header: 'ID',
          Header:() => <div data-testid="div-dzio" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Result<br />ID
          </div>, 
          filterFn: (row, id, filterValue) => {
            const idValue = row.getValue(id);          
            // Ensure both values are numbers before comparison
            return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
            },   
        },  
        {
          accessorKey: 'sample_id',
          header: 'Sample ID',
          Header:() => <div data-testid="div-2f63" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Sample<br />ID
          </div>,   
      },  
        {
            accessorFn: (row) => dayjs(row.timestamp).format('YYYY-MM-DD HH:mm:ss'),
            header: 'Timestamp',
            Header:() => <div data-testid="div-j5l4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
            Timestamp<br />&nbsp;
            </div>, 
            Cell: ({ cell }) => (
                <div data-testid="div-7em7" style={{ whiteSpace: 'pre-wrap' }}>
                  {cell.getValue()?.toString().split(' ').join('\n')}
                </div>
              ),
        },
        {
          accessorKey: 'datarun_id',
          header: 'Source',
          Header: () => (
            <div
              data-testid="div-dfvg"
              style={{ textAlign: 'center', height: '100%', minHeight: '50px' }}
            >
              Source
              <br />
              &nbsp;
            </div>
          ),
          Cell: ({ row }) => {
            const { datarun_id, source_result, test_configuration } =
              row.original;
            const source_result_id = source_result?.id;
            const dataset_id = source_result?.dataset_id;
            const isDCMAppResult =
              !datarun_id && test_configuration?.collection_mode;
            const isNormalAppResult =
              !datarun_id && !test_configuration?.collection_mode;
            const isDatarunResult = !!datarun_id;
        
            return (
              <div data-testid="div-6a98">
                {isDCMAppResult ? (
                  <Typography data-testid="Typography-oy1b">
                    Data Collection Mode Result
                  </Typography>
                ) : isNormalAppResult ? (
                  <Typography data-testid="Typography-kkv6">
                    Normal App Result
                  </Typography>
                ) : isDatarunResult ? (
                  <div data-testid="div-2hzx"
                  >
                    <Typography
                      data-testid="Typography-9npz"
                      variant="subtitle1"
                      gutterBottom
                    >
                      Reprocessed Result
                    </Typography>
                    <Box data-testid="div-x5v0"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pl:2, 
                      }}
                    >
                      {/* Datarun ID Section */}
                      <div data-testid="div-mlp6" onClick={(e) => e.stopPropagation()} style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography data-testid="Typography-ry81" sx={{fontSize: '12px', pr: 0.5,}}>
                          <strong>Data Run ID:</strong> {datarun_id}
                        </Typography>
                        <Tooltip data-testid="Tooltip-3i31" title="Copy Datarun ID">
                          <IconButton data-testid="IconButton-w2nx"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(datarun_id);
                            }}
                          >
                            <ContentCopyIcon  sx={{ p:0, m:0, fontSize: '16px'}}/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip data-testid="Tooltip-dt9t" title="View Datarun">
                          <IconButton data-testid="IconButton-1nto"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(
                                `/data_analysis/data_run_management/${datarun_id}`,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            }}
                          >
                            <OpenInNewIcon sx={{p:0, m:0, fontSize: '16px'}} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      {/* Dataset ID Section */}
                      <div data-testid="div-iw27" onClick={(e) => e.stopPropagation()} style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography data-testid="Typography-ey9z" sx={{fontSize: '12px', pr: 0.5,}}>
                        <strong>Dataset ID:</strong> {dataset_id}
                        </Typography>
                        <Tooltip data-testid="Tooltip-0kl3" title="Copy Dataset ID">
                          <IconButton data-testid="IconButton-kcj7"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(dataset_id);
                            }}
                          >
                            <ContentCopyIcon  sx={{p:0, m:0, fontSize: '16px'}} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip data-testid="Tooltip-362t" title="View Dataset">
                          <IconButton data-testid="IconButton-k5nz"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(
                                `/data_analysis/data_set_management/${dataset_id}`,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            }}
                          >
                            <OpenInNewIcon  sx={{p:0, m:0, fontSize: '16px'}} />
                          </IconButton>
                        </Tooltip>
                      </div>
                      {/* Source Result ID Section */}
                      <div data-testid="div-xrg2" onClick={(e) => e.stopPropagation()} style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography data-testid="Typography-gdjc" sx={{fontSize: '12px', pr: 0.5,}}>
                          <strong>Source Result ID:</strong> {source_result_id}
                        </Typography>
                        <Tooltip data-testid="Tooltip-hdyn" title="Copy Source Result ID">
                          <IconButton data-testid="IconButton-79gl"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              copyToClipboard(source_result_id);
                            }}
                          >
                            <ContentCopyIcon  sx={{p:0, m:0, fontSize: '16px'}} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip data-testid="Tooltip-r2ar" title="View Source Result">
                          <IconButton data-testid="IconButton-3x7h"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(
                                `/results/${source_result_id}`,
                                '_blank',
                                'noopener,noreferrer'
                              );
                            }}
                          >
                            <OpenInNewIcon  sx={{fontSize: '16px'}} />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Box>
                  </div>
                ) : null}
              </div>
            );
          },
          enableColumnFilter: true,
          filterFn: 'customResultTypeFilter',
        },    
        {
          accessorKey: 'method_id',
          header: "Method",
          Header:() => <div data-testid="div-olz0" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                            Method<br />ID
                      </div>,
          filterFn: (row, id, filterValue) => {
            const idValue = row.getValue(id);          
            // Ensure both values are numbers before comparison
            return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
            },  
        },
        {
          accessorKey: 'housing_id',
          header: "Housing",
          Header:() => <div data-testid="div-4vcd" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
                          Housing<br />ID
                      </div>,
          filterFn: (row, id, filterValue) => {
            const idValue = row.getValue(id);          
            // Ensure both values are numbers before comparison
            return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
            },  
        },
        {
          accessorKey: 'organisation_id',
          header: 'Organisation ID',
          Header:() => <div data-testid="div-lcw7" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          Organisation<br />ID
          </div>, 
          filterFn: (row, id, filterValue) => {
              const idValue = row.getValue(id);          
              // Ensure both values are numbers before comparison
              return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
              },    
      },  
        {
          accessorKey: 'user_id',
          header: 'User ID',
          Header:() => <div data-testid="div-psyz" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '50px' }}>
          User<br />ID
          </div>, 
          filterFn: (row, id, filterValue) => {
              const idValue = row.getValue(id);          
              // Ensure both values are numbers before comparison
              return idValue !== null && idValue !== undefined && Number(idValue) === Number(filterValue);
              },    
        },
    ]


    // Define the type for the keys for handling persistance of table settings in session storage
    type ResultSessionStorageKeyType = keyof ResultSessionStorageModel;

    // Generic handler function for persisting table parameters
    const handleParameterChange = <T,>(
        key: ResultSessionStorageKeyType,
        currentValue: T,
        setValueFunction: (value: T) => void,
        newValue: T | ((prevValue: T) => T)
        ) => {
        const updatedValue = typeof newValue === 'function' ? (newValue as (prevValue: T) => T)(currentValue) : newValue;
        ResultTableParameterStorageService.set(key, updatedValue);
        setValueFunction(updatedValue);
        };

    // Handlers for persisting table: sorting, pagination, visibility, filter, search, and search bar visibility
    const setSortingFunction = (newSortingValue: any) =>
        handleParameterChange(ResultSessionStorageKey.sorting, sorting, setSorting, newSortingValue);
    const setPaginationChangeFunction = (newPaginationValue: any) =>
        handleParameterChange(ResultSessionStorageKey.pagination, pagination, setPagination, newPaginationValue);
    const setColumnVisibilityFunction = (newColumnVisibilityValue: any) =>
        handleParameterChange(ResultSessionStorageKey.columnVisibility, columnVisibility, setColumnVisibility, newColumnVisibilityValue);
    const setFilterChangeFunction = (newFilterChangeValue: any) =>
        handleParameterChange(ResultSessionStorageKey.columnFilters, columnFilters, setColumnFilters, newFilterChangeValue);
    const setGlobalFilterChangeFunction = (newGlobalFilterChangeValue: string | ((prevValue: string) => string)) =>
        handleParameterChange(ResultSessionStorageKey.search, globalFilter, setGlobalFilter, newGlobalFilterChangeValue);
    const setShowGlobalFilterChangeFunction = (newShowGlobalFilterChangeValue: any) =>
        handleParameterChange(ResultSessionStorageKey.showSearch, showGlobalFilter, setShowGlobalFilter, newShowGlobalFilterChangeValue);



    return <MaterialReactTable
                columns={columns}
                data={rows}
                filterFns={filterFns}
                state={{
                isLoading,
                sorting,
                columnVisibility,
                columnFilters,
                pagination,
                globalFilter,
                showGlobalFilter
                }}
                onSortingChange={setSortingFunction}
                onColumnVisibilityChange={setColumnVisibilityFunction}
                onColumnFiltersChange={setFilterChangeFunction}
                onPaginationChange={setPaginationChangeFunction}
                onGlobalFilterChange={setGlobalFilterChangeFunction}
                onShowGlobalFilterChange={setShowGlobalFilterChangeFunction}
                defaultColumn={{    
                minSize: 10,
                maxSize: 160,
                size: 10,
                }}
                positionActionsColumn="last"
                enableRowSelection
                selectAllMode="all"
                // enableRowActions
                initialState={{
                sorting,
                columnVisibility,
                columnFilters,
                pagination,
                globalFilter,
                density: 'compact',
                columnPinning: { left: ['mrt-row-select'], right: ['mrt-row-actions']},
                }}

            // TABLE STYLING IS IN THE THEME
            displayColumnDefOptions={{
                'mrt-row-actions': {
                header: "Actions", // Renames Actions Column
                },
            }}

            muiTopToolbarProps={({ table }) => ({
                sx: (theme: any) => ({
                  ...((theme.components?.MuiCustomToolbar?.styleOverrides?.root) || {}),
                }),
              })}

            muiTableHeadProps={{
                sx: (theme: any) => theme.components?.MuiTableHead?.styleOverrides?.root || {},
              }}

            muiTableHeadCellProps={({ column }) => ({
                sx: (theme: any) => ({
                  ...(theme.components?.MuiTableCell?.styleOverrides?.head || {}),
                  ...(column.id === 'mrt-row-select' && {
                    maxWidth: '50px',
                    width: '50px',
                  }),
                }),
              })}

            muiFilterTextFieldProps={{
                sx: (theme: any) => theme.components?.MuiFilterTextField?.styleOverrides?.root || {},
              }}

            muiTableBodyRowProps={({ row }) => ({
                onClick: (event) => {
                    console.log(row.original);
                    openPopup(row)
                },
                sx: (theme: any) => ({
                    ...(theme.components?.MuiTableBodyRow?.styleOverrides?.root || {}),
                    backgroundColor:
                    row.index % 2 === 0
                        ? colorConfigs.tables.firstRow
                        : colorConfigs.tables.secondRow,
                }),
                })}

            muiTableBodyProps={{
                sx: (theme: any) => theme.components?.MuiTableBody?.styleOverrides?.root || {},
                }}
            
                muiPaginationProps={{
                    rowsPerPageOptions: [250, 500, 1000],
                    sx: {
                        padding: 0,  
                        margin: 0,   
                        '& .MuiTablePagination-root': {
                        padding: 0,  
                        margin: 0,   
                        },
                        '& .MuiToolbar-root': {
                        padding: 0,
                        margin: 0,
                        minHeight: '0px',
                        },
                        '& .MuiTablePagination-selectLabel': {
                        margin: 0,
                        },
                        '& .MuiTablePagination-displayedRows': {
                        margin: 0,
                        },
                        '& .MuiTablePagination-actions': {
                        margin: 0,
                        },
                    },
                    }}

            muiTableBodyCellProps={({ cell }) => ({
                // TABLE BODY CELLS
                sx: {
                },
            })}

            muiBottomToolbarProps={{
                // BOTTOM TOOLBAR (Pagination)
                sx: {
                    height: '41px',
                    maxHeight: '41px',
                    minHeight: '41px',
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 1,
                    backgroundColor: '#fff',
                    boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)', 
                    margin: 0,
                    padding: 0,
                  '& .MuiToolbar-root': {
                    padding: 0,
                    margin: 0,
                    paddingBottom: 0,
                    paddingTop: 0,
                  },
                  '& .MuiBox-root': {
                    p: 0,
                  },
                  '& .MuiIconButton-root': {
                    color: colorConfigs.tables.headBg,
                  },
                  '& .MuiTablePagination-root': {
                    margin: 0,
                    paddingTop: 0.5,
                    paddingBottom: 0.5,
                    color: colorConfigs.buttons.bg,
                  },
                  '& .MuiTablePagination-selectLabel': {
                    color: colorConfigs.tables.headBg,
                    padding: 0,
                    margin: 0,
                  },
                  '& .MuiTablePagination-displayedRows': {
                    color: colorConfigs.buttons.bg,
                    padding: 0,
                    margin: 0,
                  },
                  '& .MuiTablePagination-actions': {
                    color: colorConfigs.tables.headBg,
                    padding: 0,
                    margin: 0,
                  },
                },
              }}


            renderTopToolbarCustomActions={({ table }) => {

                const handleArchiveResults = () => {
                    table.getSelectedRowModel().flatRows.map(row => {
                        //Add archive logic
                    });
                };

                const handleExportMultiLineRows = async (rows: MRT_Row<any>[]) => {
                  const resultIds = rows.map((row) => row.original.id);
                  const payload = {
                    result_ids: resultIds,
                  };
                  const data: any[] = [];
                
                  try {
                    const url = `results/batch`;
                    const initialResponse = await api.post(url, payload, {
                      headers: {
                        Authorization: `Bearer ${authData.access}`,
                      },
                    });
                
                    if (initialResponse.data.results.length < 1) {
                      console.log("No results for this query");
                      return;
                    } else {
                      data.push(...initialResponse.data.results);
                    }
                
                    console.log("Downloaded all results for export:", data);
                
                    // Prepare and download Excel
                    const excelRows: any[] = [];
                    const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
                    const filename = `${now}_ResultsList`;
                
                    for (let i = 0; i < data.length; i++) {
                      const result = data[i];
                      const formattedTimestamp = dayjs(result.timestamp).format(
                        "YYYY-MM-DD HH:mm:ss"
                      );
                      const isDCMAppResult =
                        !result.datarun_id && result.test_configuration?.collection_mode;
                      const isNormalAppResult =
                        !result.datarun_id && !result.test_configuration?.collection_mode;
                      const isDatarunResult = !!result.datarun_id;
                
                      // Add the main result row
                      const baseRow = {
                        ID: result.id,
                        "Sample ID": result.sample_id || "",
                        Timestamp: formattedTimestamp,
                        Longitude: result.longitude || "",
                        Latitude: result.latitude || "",
                        Notes: result.notes || "",
                        "Method ID": result.method_id,
                        "Housing ID": result.housing_id,
                        "Organisation ID": result.organisation_id,
                        "User ID": result.user_id,
                        Type: result.type,
                        Source: isDCMAppResult
                          ? "Data Collection Mode Result"
                          : isNormalAppResult
                          ? "Normal App Result"
                          : isDatarunResult
                          ? `Reprocessed Result`
                          : "",
                        // "REPROCESSED RESULT INFO": "",
                        "Datarun ID": result.datarun_id || "",
                        "Dataset ID": result.source_result?.dataset_id || "",
                        "Source Result ID": result.source_result?.id || "",
                        "Analysis Number": result.source_result?.analysis_number ? result.source_result?.analysis_number : "still to be hooked up in the backend", // still to hook up
                        "Images Used": result.source_result?.images.length
                        ? `[${result.source_result?.images.join(", ")}]`
                        : (!result.source_result?.images.length && result.datarun_id) ? "[]" : "",
                        "Images Rejected": result.source_result?.rejected_images.length
                        ? `[${result.source_result?.rejected_images.join(", ")}]`
                        : (!result.source_result?.rejected_images.length && result.datarun_id) ? "[]" : "",
                        // "TEST CONFIGURATION INFO": "",
                        "Flash Required": result.test_configuration?.flash_required ? "TRUE" : "FALSE",
                        "Reader Resolution": result.test_configuration?.reader_resolution
                        ? `${result.test_configuration.reader_resolution[0]}x${result.test_configuration.reader_resolution[1]}`
                        : "",
                        "Ground Truth": 'Not setup yet',
                        // "DEVICE INFO": "",
                        "Device Model": result.device_model,
                        "Device UUID": result.device_uuid,
                        "OS Version": result.os_version,
                        "App Version": result.app_version,
                        "App Build": result.app_build,
                        "TEST STRIPS INFO": result.test_strips?.[0] ? `Test Strip 1` : "",
                        "C Line Score": result.test_strips?.[0]?.c_line?.score || "",
                        "C Line Area": result.test_strips?.[0]?.c_line?.area || "",
                        "C Line Position": result.test_strips?.[0]?.c_line?.peak_position || "",
                        Baseline: result.test_strips?.[0]?.baseline
                        ? `[${result.test_strips[0].baseline.join(", ")}]`
                        : "",
                        "Mean Profile": result.test_strips?.[0]?.mean_profile
                        ? `[${result.test_strips[0].mean_profile.join(", ")}]`
                        : "",
                        "Baseline Fit Score": "still to be hooked up in the backend", //still to hook up
                        "TEST LINES INFO": result.test_strips?.[0]?.t_lines?.[0]
                          ? `T Line 1`
                          : "",
                        Score: result.test_strips?.[0]?.t_lines?.[0]?.score || "",
                        Area: result.test_strips?.[0]?.t_lines?.[0]?.area || "",
                        Position: result.test_strips?.[0]?.t_lines?.[0]?.peak_position || "",
                        "TC Ratio": result.test_strips?.[0]?.t_lines?.[0]?.tc_ratio || "",
                      };
                
                      excelRows.push(baseRow);
                
                      // Add additional test lines for the first strip
                      result.test_strips?.[0]?.t_lines?.slice(1).forEach((tLine: any, tLineIndex: number) => {
                        const tLineRow = {
                          ID: "",
                          "Sample ID": "",
                          Timestamp: "",
                          Longitude: "",
                          Latitude: "",
                          Notes: "",
                          "Method ID": "",
                          "Housing ID": "",
                          "Organisation ID": "",
                          "User ID": "",
                          Type: "",
                          Source: "",
                          // "REPROCESSED RESULT INFO": "",
                          "Datarun ID": "",
                          "Dataset ID": "",
                          "Analysis Number": "",
                          "Images Used": "",
                          "Images Rejected": "",
                          // "TEST CONFIGURATION INFO": "",
                          "Flash Required": "",
                          "Reader Resolution": "",
                          "Ground Truth": "",
                          // "DEVICE INFO": "",
                          "Device Model": "",
                          "Device UUID": "",
                          "OS Version": "",
                          "App Version": "",
                          "App Build": "",
                          "TEST STRIPS INFO": "Test Strip 1",
                          "C Line Score": "",
                          "C Line Area": "",
                          "C Line Position": "",
                          Baseline: "",
                          "Mean Profile": "",
                          "Baseline Fit Score": "",
                          "TEST LINES INFO": `T Line ${tLineIndex + 2}`,
                          Score: tLine.score,
                          Area: tLine.area,
                          Position: tLine.peak_position,
                          "TC Ratio": tLine.tc_ratio,
                        };
                
                        excelRows.push(tLineRow);
                      });
                
                      // Add subsequent test strips
                      result.test_strips?.slice(1).forEach((strip: any, stripIndex: number) => {
                        let isFirstRowForStrip = true;
                        strip.t_lines?.forEach((tLine: any, tLineIndex: number) => {
                          const tLineRow = {
                            ID: "",
                            "Sample ID": "",
                            Timestamp: "",
                            Longitude: "",
                            Latitude: "",
                            Notes: "",
                            "Method ID": "",
                            "Housing ID": "",
                            "Organisation ID": "",
                            "User ID": "",
                            Type: "",
                            Source: "",
                            // "REPROCESSED RESULT INFO": "",
                            "Datarun ID": "",
                            "Dataset ID": "",
                            "Analysis Number": "",
                            "Images Used": "",
                            "Images Rejected": "",
                            // "TEST CONFIGURATION INFO": "",
                            "Flash Required": "",
                            "Reader Resolution": "",
                            "Ground Truth": "",
                            // "DEVICE INFO": "",
                            "Device Model": "",
                            "Device UUID": "",
                            "OS Version": "",
                            "App Version": "",
                            "App Build": "",
                            "TEST STRIPS INFO": isFirstRowForStrip
                              ? `Test Strip ${stripIndex + 2}`
                              : `Test Strip ${stripIndex + 2}`,
                            "C Line Score": isFirstRowForStrip ? strip.c_line.score : "",
                            "C Line Area": isFirstRowForStrip ? strip.c_line.area : "",
                            "C Line Position": isFirstRowForStrip
                              ? strip.c_line.peak_position
                              : "",
                            Baseline: isFirstRowForStrip ? strip.baseline.join(", ") : "",
                            "Mean Profile": isFirstRowForStrip
                              ? strip.mean_profile.join(", ")
                              : "",
                            "Baseline Fit Score": "",
                            "TEST LINES INFO": `T Line ${tLineIndex + 1}`,
                            Score: tLine.score,
                            Area: tLine.area,
                            Position: tLine.peak_position,
                            "TC Ratio": tLine.tc_ratio,
                          };
                
                          excelRows.push(tLineRow);
                
                          // Mark that the first row for this strip has been added
                          isFirstRowForStrip = false;
                        });
                      });
                    }
                
                    tsXLXS().exportAsExcelFile(excelRows).saveAsExcelFile(filename);
                  } catch (error) {
                    console.error("Error exporting rows:", error);
                    alert("Failed to export results. Please try again.");
                  }
                };
                
                const handleExportOneLineRows = async (rows: MRT_Row<any>[]) => {
                  const resultIds = rows.map((row) => row.original.id);
                  const payload = {
                    result_ids: resultIds,
                  };
                
                  try {
                    const url = `results/batch`;
                    const response = await api.post(url, payload, {
                      headers: {
                        Authorization: `Bearer ${authData.access}`,
                      },
                    });
                
                    const data = response.data.results;
                    if (data.length < 1) {
                      console.log("No results for this query");
                      return;
                    }
                
                    console.log("Downloaded all results for one-line export:", data);
                
                    // Prepare Excel rows
                    const excelRows: any[] = [];
                    const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
                    const filename = `${now}_ResultsList_OneLine`;
                
                    data.forEach((result: any) => {
                      const formattedTimestamp = dayjs(result.timestamp).format("YYYY-MM-DD HH:mm:ss");
                      const isDCMAppResult = !result.datarun_id && result.test_configuration?.collection_mode;
                      const isNormalAppResult = !result.datarun_id && !result.test_configuration?.collection_mode;
                      const isDatarunResult = !!result.datarun_id;
                
                      // Create the base row
                      const baseRow: any = {
                        ID: result.id,
                        "Sample ID": result.sample_id || "",
                        Timestamp: formattedTimestamp,
                        Longitude: result.longitude || "",
                        Latitude: result.latitude || "",
                        Notes: result.notes || "",
                        "Method ID": result.method_id,
                        "Housing ID": result.housing_id,
                        "Organisation ID": result.organisation_id,
                        "User ID": result.user_id,
                        Type: result.type,
                        Source: isDCMAppResult
                          ? "Data Collection Mode Result"
                          : isNormalAppResult
                          ? "Normal App Result"
                          : isDatarunResult
                          ? `Reprocessed Result`
                          : "",
                        "Datarun ID": result.datarun_id || "",
                        "Dataset ID": result.source_result?.dataset_id || "",
                        "Source Result ID": result.source_result?.id || "",
                        "Analysis Number": result.source_result?.analysis_number || "N/A",
                        "Images Used": result.source_result?.images.length
                        ? `[${result.source_result?.images.join(", ")}]`
                        : (!result.source_result?.images.length && result.datarun_id) ? "[]" : "",
                        "Images Rejected": result.source_result?.rejected_images.length
                        ? `[${result.source_result?.rejected_images.join(", ")}]`
                        : (!result.source_result?.rejected_images.length && result.datarun_id) ? "[]" : "",
                        "Flash Required": result.test_configuration?.flash_required ? "TRUE" : "FALSE",
                        "Reader Resolution": result.test_configuration?.reader_resolution
                          ? `${result.test_configuration.reader_resolution[0]}x${result.test_configuration.reader_resolution[1]}`
                          : "",
                        "Ground Truth": "Not setup yet",
                        "Device Model": result.device_model,
                        "Device UUID": result.device_uuid,
                        "OS Version": result.os_version,
                        "App Version": result.app_version,
                        "App Build": result.app_build,
                      };
                
                      // Add test strip details to the base row
                      result.test_strips?.forEach((strip: any, stripIndex: number) => {
                        const stripLabel = `Strip ${stripIndex + 1}`;
                        // baseRow[stripLabel] = `Test Strip ${stripIndex + 1}`;
                        baseRow[`${stripLabel} - CLine Score`] = strip.c_line?.score || "";
                        baseRow[`${stripLabel} - CLine Area`] = strip.c_line?.area || "";
                        baseRow[`${stripLabel} - CLine Position`] = strip.c_line?.peak_position || "";
                        baseRow[`${stripLabel} - Baseline`] = strip.baseline
                          ? `[${strip.baseline.join(", ")}]`
                          : "";
                        baseRow[`${stripLabel} - Mean Profile`] = strip.mean_profile
                          ? `[${strip.mean_profile.join(", ")}]`
                          : "";
                        baseRow[`${stripLabel} - Baseline Fit Score`] = "N/A";
                
                        // Add test lines for the strip
                        strip.t_lines?.forEach((tLine: any, tLineIndex: number) => {
                          const tLineLabel = `${stripLabel} - TLine ${tLineIndex + 1}`;
                          // baseRow[`${tLineLabel}`] = `T Line ${tLineIndex + 1}`;
                          baseRow[`${tLineLabel} - Score`] = tLine.score || "";
                          baseRow[`${tLineLabel} - Area`] = tLine.area || "";
                          baseRow[`${tLineLabel} - Position`] = tLine.peak_position || "";
                          baseRow[`${tLineLabel} - TC Ratio`] = tLine.tc_ratio || "";
                        });
                      });
                
                      excelRows.push(baseRow);
                    });
                
                    tsXLXS().exportAsExcelFile(excelRows).saveAsExcelFile(filename);
                  } catch (error) {
                    console.error("Error exporting one-line rows:", error);
                    alert("Failed to export results. Please try again.");
                  }
                };
                

                // This function is currently being handledo n the backend.  I am keeping this code for the future.
                const handleExportAntonioRows = async (rows: MRT_Row<any>[]) => {
                  const resultIds = rows.map((row) => row.original.id);
                  const payload = {
                    result_ids: resultIds,
                  };
                
                  try {
                    const url = `results/batch`;
                    const response = await api.post(url, payload, {
                      headers: {
                        Authorization: `Bearer ${authData.access}`,
                      },
                    });
                
                    const data = response.data.results;
                    if (data.length < 1) {
                      console.log("No results for this query");
                      return;
                    }
                
                    console.log("Downloaded all results for multi-sheet export:", data);
                
                    // Define sheets
                    const workbook: XLSX.WorkBook = { SheetNames: [], Sheets: {} };
                
                    // Prepare a map to hold data for each strip index
                    const stripDataMap: { [key: string]: any[] } = {};
                
                    // Process each result
                    data.forEach((result: any) => {
                      result.test_strips?.forEach((strip: any, stripIndex: number) => {
                        const sheetName = `Data Strip ${stripIndex + 1}`;
                
                        // Initialise the sheet data array if it doesn't exist
                        if (!stripDataMap[sheetName]) {
                          stripDataMap[sheetName] = [];
                        }
                
                        // Add the current strip's data to the corresponding sheet array
                        const stripDataEntry: any = {
                          Dataset: result.source_result?.dataset_id || "N/A", // Hookup
                          "Analysis number": result.source_result?.analysis_number || "N/A", // Hookup
                          Notes: result.notes || "",
                          "Device Manufacturer": result.source_result?.analysis_number || "N/A", // Hookup
                          "Device Model": result.device_model || "",
                          "OS version": result.os_version || "",
                          "LED Flash": result.test_configuration?.flash_required ? "TRUE" : "FALSE",
                          "Camera Resolution": result.test_configuration?.reader_resolution
                            ? `${result.test_configuration.reader_resolution[0]}x${result.test_configuration.reader_resolution[1]}`
                            : "",
                          "Ground Truth": result.ground_truth || "N/A", // Hookup
                          "Images Used": result.source_result?.images.length
                            ? `[${result.source_result.images.join(", ")}]`
                            : "[]",
                          "Images Rejected": result.source_result?.rejected_images.length
                            ? `[${result.source_result.rejected_images.join(", ")}]`
                            : "[]",
                          "C line 1 score": strip.c_line?.score || "",
                          "C line 1 area": strip.c_line?.area || "",
                          "C line 1 position": strip.c_line?.peak_position || "",
                          Profile: strip.mean_profile ? `[${strip.mean_profile.join(", ")}]` : "",
                          Baseline: strip.baseline ? `[${strip.baseline.join(", ")}]` : "",
                          "Baseline fit score": "N/A", // Hookup
                        };
                
                        // Dynamically add T Lines
                        strip.t_lines?.forEach((tLine: any, tLineIndex: number) => {
                          stripDataEntry[`T line ${tLineIndex + 1} score`] = tLine.score || "";
                          stripDataEntry[`T line ${tLineIndex + 1} area`] = tLine.area || "";
                          stripDataEntry[`T line ${tLineIndex + 1} position`] = tLine.peak_position || "";
                          stripDataEntry[`TC ratio ${tLineIndex + 1}`] = tLine.tc_ratio || "";
                          // stripDataEntry[`TC area ratio ${tLineIndex + 1}`] = tLine.tc_area_ratio || "";
                        });
                
                        // Add to the corresponding strip sheet
                        stripDataMap[sheetName].push(stripDataEntry);
                      });
                    });
                
                    // Add each sheet to the workbook
                    Object.entries(stripDataMap).forEach(([sheetName, sheetData]) => {
                      const worksheet = XLSX.utils.json_to_sheet(sheetData);
                      workbook.SheetNames.push(sheetName);
                      workbook.Sheets[sheetName] = worksheet;
                    });
                
                    // Add the Datasets sheet
                    const datasetsSheet = data.map((result: any) => ({
                      Dataset: result.source_result?.dataset_id || "N/A",
                    }));
                    workbook.SheetNames.push("Datasets");
                    workbook.Sheets["Datasets"] = XLSX.utils.json_to_sheet(datasetsSheet);
                

                // Extract test_configuration from the first result
                const firstResult = data[0]?.test_configuration || {};

                // Define configuration key-value pairs
                const configData = [
                  ["Method ID", firstResult.id || "N/A"],
                  ["Housing ID", firstResult.housing || "N/A"],
                  ["Test Name", firstResult.test_name || "N/A"],
                  ["Description", firstResult.description || "N/A"],
                  ["Version", firstResult.version || "N/A"],
                  ["Status", firstResult.status || "N/A"],
                  ["Date Created", firstResult.date_created || "N/A"],
                  ["Date Updated", firstResult.date_updated || "N/A"],
                  ["Flash Required", firstResult.flash_required ? "TRUE" : "FALSE"],
                  ["Collection Mode", firstResult.collection_mode ? "TRUE" : "FALSE"],
                  ["Camera Macro Focus Required", firstResult.camera_macro_focus_required ? "TRUE" : "FALSE"],
                  ["Reader Resolution", firstResult.reader_resolution
                    ? `${firstResult.reader_resolution[0]}x${firstResult.reader_resolution[1]}`
                    : "N/A"],
                  ["Frames to Capture", firstResult.frames_to_capture || "N/A"],
                  ["Min Conformance Probability", firstResult.min_conformance_probability || "N/A"],
                  ["Max Conformance Probability", firstResult.max_conformance_probability || "N/A"],
                  ["Initial H Factor", firstResult.initial_h_factor || "N/A"],
                  ["Control Exposure", firstResult.control_exposure ? "TRUE" : "FALSE"],
                  ["Profile Control Levels", firstResult.profile_control_levels
                    ? `[${firstResult.profile_control_levels.join(", ")}]`
                    : "N/A"],
                  ["Polynomial Baseline Order", firstResult.polynomial_baseline_order || "N/A"],
                  ["Profile Alignment", firstResult.profile_alignment ? "TRUE" : "FALSE"],
                  ["Control Line Correction", firstResult.control_line_correction ? "TRUE" : "FALSE"],
                  ["Control Baseline", firstResult.control_baseline ? "TRUE" : "FALSE"],
                  ["Control Baseline Threshold", firstResult.control_baseline_threshold || "N/A"],
                ];

                // Create the Configuration sheet with headers in column A and values in column B
                const configurationSheet = XLSX.utils.aoa_to_sheet(configData);

                // Add the Configuration sheet to the workbook
                workbook.SheetNames.push("Configuration");
                workbook.Sheets["Configuration"] = configurationSheet;



                
                // Extract strips from the first result's test_configuration
                const firstResultStrips = data[0]?.test_configuration?.strips || [];

                // Prepare Strip Information data
                const stripInfoData = [
                  ["Strip index", "Control Line Regions", "Test Line Regions", "Baseline Regions", "Strip Corners"],
                ];

                // Populate rows for each strip
                firstResultStrips.forEach((strip: any, stripIndex: number) => {
                  const index = `${stripIndex}`;

                  // Extract regions based on category
                  const controlRegions = strip.strip_regions
                    ?.filter((region: any) => region.category === "CONTROL")
                    .map((region: any) => [region.lower_bound, region.upper_bound]) || [];

                  const testRegions = strip.strip_regions
                    ?.filter((region: any) => region.category === "TEST")
                    .map((region: any) => [region.lower_bound, region.upper_bound]) || [];

                  const baselineRegions = strip.strip_regions
                    ?.filter((region: any) => region.category === "BASELINE")
                    .map((region: any) => [region.lower_bound, region.upper_bound]) || [];

                  // Push formatted data into stripInfoData
                  stripInfoData.push([
                    index, // Strip index
                    JSON.stringify(controlRegions), // Control Line Regions
                    JSON.stringify(testRegions), // Test Line Regions
                    JSON.stringify(baselineRegions), // Baseline Regions
                    JSON.stringify(strip.strip_corners || []), // Strip Corners as nested lists
                  ]);
                });

                // Create the Strip Information sheet
                const stripInfoSheet = XLSX.utils.aoa_to_sheet(stripInfoData);

                // Add the Strip Information sheet to the workbook
                workbook.SheetNames.push("Strip Information");
                workbook.Sheets["Strip Information"] = stripInfoSheet;


                    // Export the workbook
                    const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
                    const filename = `${now}_AntonioResultsList.xlsx`;
                    XLSX.writeFile(workbook, filename);
                  } catch (error) {
                    console.error("Error exporting Antonio rows:", error);
                    alert("Failed to export results. Please try again.");
                  }
                };
                
                
                        

                return (
                    <Box data-testid="Box-36bn" sx={{ display: 'flex', gap: '0.3rem', height: "40px", alignItems: 'flex-end'}}>
                      <Tooltip data-testid="Tooltip-1z0f" title="View action on selected data" placement="top" arrow>
                        <Box data-testid="Box-ufit">
                            <RareButton data-testid="RareButton-4f1x"
                            variant="outlined"
                            onClick={handleClick}
                            icon={<KeyboardArrowDownIcon sx={{fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }}  />} 
                            sx={{gap: isMobile ? 0: 1, px: isMobile ? 1 : 2, fontSize: '14px'}}              
                          >
                            {isMobile ? "" : "Bulk Actions"}
                          </RareButton> 
                        </Box>  
                      </Tooltip>
                      <Tooltip data-testid="Tooltip-7bcy" title="Refresh Data" placement="top" arrow>
                        <Box data-testid="Box-doq4">
                          <RareButton data-testid="RareButton-klzb" 
                            variant="outlined"
                            onClick={() => handleRefreshButton()}
                            center={true}
                            icon={<RefreshIcon sx={{fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }}  />} 
                            sx={{gap: 0, px: isMobile ? 1 : 2}}              
                            >
                            
                          </RareButton>
                        </Box>
                      </Tooltip>
                      <Tooltip data-testid="Tooltip-euyd" title="Clear All Table Filters" placement="top" arrow>
                        <Box data-testid="Box-bitm">
                          <RareButton data-testid="RareButton-103s"
                            variant="outlined"
                            onClick={() => handleRemoveAllFilters()}
                            icon={<FilterAltOffOutlinedIcon sx={{fontSize: isMobile ? '16px' : '24.5px', p: 0, m: 0, display: 'flex', alignItems: 'center' }}  />} 
                            sx={{gap: 0, px: isMobile ? 1 : 2}}              
                          >
                          </RareButton>
                        </Box>
                      </Tooltip>
                      <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                          'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                      >
                        <MenuItem data-testid="MenuItem-qc5c" disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        } onClick={() => handleExportMultiLineRows(table.getSelectedRowModel().rows)} disableRipple>
                          <Box data-testid="Box-phjl" sx={{display: 'flex', alignItems: 'center'}}>
                            <FileDownloadOutlinedIcon />
                            <Typography data-testid="Typography-08co">Export to Excel (Mulitline)</Typography>
                          </Box>    
                        </MenuItem>
                        <MenuItem data-testid="MenuItem-qc5d" disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        } onClick={() => handleExportOneLineRows(table.getSelectedRowModel().rows)} disableRipple>
                          <Box data-testid="Box-phjh" sx={{display: 'flex', alignItems: 'center'}}>
                            <FileDownloadOutlinedIcon />
                            <Typography data-testid="Typography-08cr">Export to Excel (One Line)</Typography>
                          </Box>    
                        </MenuItem>
                        {/* <MenuItem data-testid="MenuItem-qc5e" disabled={
                          !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                        } onClick={() => handleExportAntonioRows(table.getSelectedRowModel().rows)} disableRipple>
                          <Box data-testid="Box-phjg" sx={{display: 'flex', alignItems: 'center'}}>
                            <FileDownloadOutlinedIcon />
                            <Typography data-testid="Typography-08cp">Export to Excel (Antonio Layout)</Typography>
                          </Box>    
                        </MenuItem> */}
                      </StyledMenu>
                      </Box>
                );
            }}
        />
}
 
export default ResultsTable;

