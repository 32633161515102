const colorConfigs = {
    sidebar: {
        bg: "#F6F6F8",
        // bi: "linear-gradient(180deg, #4A90E2 0%, #132036 85%, #132036 100%)",
        color: "#403b43",
        hoverBg: "#7a150d",
        activeBg: "#7a150d",
    },
    topbar: {
        bg: "#359BDB",
        color: "#359BDB",
        bg2: "#FBFBFB"
    },
    mainBg: "#fff",
    buttons: {
        bg: "linear-gradient(45deg, #5A77B7, #7B97D4)",
        bgActive: "linear-gradient(45deg, #4A9A63, #639C78)",
        bgActiveHover: "linear-gradient(45deg, #3E8353, #527B64)",
        bgInactive: "linear-gradient(45deg, #B55F5D, #D27876)",
        bgInactiveHover: "linear-gradient(45deg, #9B4F4D, #B96566)",
        bgActiveSolid: "#569b6d",
        bgActiveHoverSolid: "#487f5b",
        bgInactiveSolid: "#c36b69",
        bgInactiveHoverSolid: "#aa5959",
        active: "#88BC72",
        inactive: "#b8b9c7",
        sidebarActive: "#E03A3A",
        sidebar: "#5A77B7",
        in_progress: "#DAA520",
        memorialised: "#DAA520",
        red: '#E03A3A',
        green: "#69C987",
        contained: '#6FA8DC',
        cancel: "#c36b69",
        cancelHovered:  "#aa5959",
        cancelDisabled: "rgba(244,0,3, 0.4)",
        success: "#569b6d",
        successHovered: "#487f5b",
        successDisabled: "rgba(147,196,125, 0.4)",
        warning: "#FF9800",
    },
    tables: {
        // headBg:  "#80332f",
        headBg:  "#7a150d",
        mainBg: "#e7e7e7",
        firstRow: "#fff",
        secondRow: 'rgb(250, 250, 250)',
    },
    sub_banner: {
        sub: '#5B6A9C',
        secondary_sub: "linear-gradient(45deg, #5A77B7, #7B97D4)",
    },
    result: {
        red: "#C96969",
        green: "#69C987",
        meanProfile: "#6987C9",
        meanBaseline: "#E03A3A",
        cColor: "#2C3E50", 
        tColors: [
            "#1B5E20",  // darkest green
            "#2E7D32",
            "#388E3C",
            "#43A047",
            "#4CAF50",  // medium green
            "#66BB6A",
            "#81C784",
            "#A5D6A7",
            "#C8E6C9",  // very light green
            "#E8F5E9"   // lightest green
        ],
        colorPairs: [
            { baseline_color: "#F4F4F4", profile_color: "#D9D9D9" }, // light gray and soft gray
            { baseline_color: "#EAEAEA", profile_color: "#CFCFCF" }, // light gray and medium-light gray
            { baseline_color: "#E0E0E0", profile_color: "#C5C5C5" }, // soft gray
            { baseline_color: "#D6D6D6", profile_color: "#BBBBBB" }, // medium gray
            { baseline_color: "#CCCCCC", profile_color: "#B1B1B1" }, // medium gray
            { baseline_color: "#C2C2C2", profile_color: "#A7A7A7" }, // darker gray
            { baseline_color: "#B8B8B8", profile_color: "#9D9D9D" }, // deeper medium gray
            { baseline_color: "#AEAEAE", profile_color: "#939393" }, // medium-dark gray
            { baseline_color: "#A4A4A4", profile_color: "#898989" }, // medium-dark gray
            { baseline_color: "#9A9A9A", profile_color: "#7F7F7F" }  // darkest gray in set
        ],
    },
    simple_result: {
        red: "#FF4141",
        green: "#A7E531",
        yellow: "#FFE103",
        invalid: "#e7e7e7",
        cpeak: "#FFA53B",
        tpeak: "#86589B",
        profile: "#359BDB",
        baseline: "#FF4141",
    },
    input: {
        color: "#86589B",
    },
    dashboard: {
        slider:  "#359BDB",
        border:  "rgba(134, 88, 155, 0.9)",
        light_grey: "#b8b9c7",
        mid_grey: "#a7a9c4",
        dark_grey: "#403b43",
        birght_red: "#ff0000",
        dark_red: "#7a150d",
    },
    primary: "#7a150d",
    text: "#403b43",
    subscription: {
        mySubscription: '#F7F8FC',
        selected: '#DCE0F0'
    }
};

export default colorConfigs;

// #E5F9FF light blue
// #359BDB darker blue
// #132036 dark blue

// Colour scheme
// RED #FF4141
// GREEN #A7E531
// YELLOW #FFE103
// ORANGE #FFA53B
// PURPLE #86589B
// PINK #FF7F7F
// BLACK #1D1D1D
// GREY #E5E5E5
// BLUE #029BDC



// const colorConfigs = {
//     topbar: {
//         bg: "#fff",
//         color: "#000",
//         bg2: "#fff"
//     },
//     mainBg: "#000",
//     buttons: {
//         bg: "linear-gradient(180deg, #0C94F1 0%, #019CFF 43%, #3376BE 100%)",
//     },
//     tables: {
//         headBg: "#3376BE",
//         mainBg: "#E7E7E7"
//     }
// };

// export default colorConfigs;