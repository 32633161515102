import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { RouteType } from "../../../routes/config";
import { RootState } from "../../../redux/store";
import colorConfigs from "../../../configs/colorConfigs";
import { Link } from "react-router-dom";
import SubSidebarItem from "./SubSidebarItem";


type Props = {
  item: RouteType;
  isHovered: boolean;
};

const SubSidebarItemCollapse = ({ item, isHovered }: Props) => {
  const [open, setOpen] = useState(false);

  const { appState } = useSelector((state: RootState) => state.appState);

  useEffect(() => {
    if (appState.includes(item.state)) {
      setOpen(true);
    }
  }, [appState, item]);

  return (
    item.sidebarProps ? (
      <>
        <ListItemButton data-testid="ListItemButton-j2h9"
          onClick={() => setOpen(!open)}
          // component={Link}
          // to={item.path}
          disableRipple
          selected={appState === item.state}
          sx={{
            "&: hover": {
              color: colorConfigs.sidebar.activeBg,
              backgroundColor: colorConfigs.sidebar.bg,
              // Apply hover effect to the icon inside ListItemButton
               "& .MuiListItemIcon-root": {
                color: colorConfigs.sidebar.activeBg,
            },
            },
            "&.Mui-selected": {
                backgroundColor: colorConfigs.sidebar.bg,
                color: colorConfigs.sidebar.activeBg,
                "&:hover": {
                backgroundColor: colorConfigs.sidebar.bg,
                },
                "&:focus": {
                backgroundColor: colorConfigs.sidebar.bg,
                },
                "&:active": {
                backgroundColor: colorConfigs.sidebar.bg,
                },
              },
              "&.Mui-selected.Mui-focusVisible": {
                  backgroundColor: colorConfigs.sidebar.bg,
              },
              "&.Mui-selected:active": {
                  backgroundColor: colorConfigs.sidebar.bg,
              },
              "&.Mui-selected:focus": {
                  backgroundColor: colorConfigs.sidebar.bg,
              },
              color: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
              paddingY: "20px",
              display: 'flex',
              flexDirection: isHovered ? 'row' : 'column',
            }}
        >
          <ListItemIcon data-testid="ListItemIcon-sfp2" sx={{
            minWidth: "unset",
            marginLeft: "7px",
            p:0,
            marginRight: "20px",
            justifyContent: "center",
            transition: "all 0.3s",
            color: appState === item.state ? colorConfigs.sidebar.activeBg : "unset",
            "&:hover": {
                color: colorConfigs.sidebar.hoverBg,
                backgroundColor: colorConfigs.sidebar.bg,
            },
            "&.Mui-selected": {
                backgroundColor: colorConfigs.sidebar.bg,
                color: colorConfigs.sidebar.activeBg,
                "&:hover": {
                backgroundColor: colorConfigs.sidebar.bg,
                },
                "&:focus": {
                backgroundColor: colorConfigs.sidebar.bg,
                },
                "&:active": {
                backgroundColor: colorConfigs.sidebar.bg,
                },
            },
            "&.Mui-selected.Mui-focusVisible": {
                backgroundColor: colorConfigs.sidebar.bg,
            },
            "&.Mui-selected:active": {
                backgroundColor: colorConfigs.sidebar.bg,
            },
            "&.Mui-selected:focus": {
                backgroundColor: colorConfigs.sidebar.bg,
            },
            }}>
            {item.sidebarProps.icon && item.sidebarProps.icon}
          </ListItemIcon>
          {isHovered && item.sidebarProps.displayText}

          {/* <ListItemText
            disableTypography
            primary={
              <Typography>
                {isHovered && item.sidebarProps.displayText}
              </Typography>
            }
          /> */}
          {!isHovered ? null : open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
          {/* {!isHovered ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />} */}
        </ListItemButton>
        <Collapse in={open} timeout="auto">
          <List>
            {item.child?.map((route, index) => (
              route.sidebarProps ? (
                route.child ? (
                  <SubSidebarItemCollapse item={route} key={index} isHovered={isHovered}/>
                ) : (
                  <SubSidebarItem item={route} key={index} isHovered={isHovered} />
                )
              ) : null
            ))}
          </List>
        </Collapse>
      </>
    ) : null
  );
};

export default SubSidebarItemCollapse;